import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, InputGroup, FormControl, Dropdown, Pagination } from "react-bootstrap";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Main from '../template/Main'
import './Clientes.css'
import ToggleSwitch from '../Uso_Geral/ToggleSwitch';

const headerProps = {
    icon: 'users',
    title: ' Clientes',
    subtitle: 'Incluir, Listar, Alterar e Excluir '
};


function Clientes() {

    const [clientes, setClientes] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [orderBy, setOrderBy] = useState("id");


    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(false)

    const [activePage, setActivePage] = useState(1);

    const itemsPerPage = 10;



    useEffect(() => {
        makeApiCall('clientes')
            .then((response) => {

                if (response && response.data) {
                    const clientes = response.data.map((cliente) => {
                        // Extrai as razões de todas as lojas associadas à maquineta
                        const lojasRazoes = cliente.lojas.map((loja) => loja.razao);

                        // Une as razões usando uma vírgula e um espaço como separador
                        const lojasRazoesString = lojasRazoes.join(' / ');

                        return {
                            id: cliente.id,
                            nomeRazao: cliente.nomeRazao,
                            documento: cliente.documento,
                            apelidoFantasia: cliente.apelidoFantasia,
                            ativo: cliente.ativo,
                            lojas: lojasRazoesString, // Armazena todas as lojas

                        };
                    });
                    setClientes(clientes);
                    setIsLoading(false);
                }

            })
            .catch(async (error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    //navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    try {
                        const refres = await Refresh();
                        if (refres === 'login') {
                            navigate('/login');
                        }
                    } catch (error) {
                        console.log(" ERRO NA CHAMADA DA API")
                    }





                }


            });
    }, [render, navigate]);



   

    const handleStatus = async (id) => {


        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`/clientes/status/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inativar cliente');
        }


    };

  
    const filteredAndSortedClients = (clientes || [])
        .filter(
            (cliente) =>
                (cliente.nomeRazao && cliente.nomeRazao.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (cliente.apelidoFantasia && cliente.apelidoFantasia.toLowerCase().includes(searchTerm.toLowerCase())) || // Adicionado filtro para apelidoFantasia
                String(cliente.documento).includes(searchTerm)
        )
        .sort((a, b) => {
            if (orderBy === 'nome') {
                const nameA = a.nomeRazao.toLowerCase();
                const nameB = b.nomeRazao.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (a[orderBy] < b[orderBy]) {
                    return -1;
                }
                if (a[orderBy] > b[orderBy]) {
                    return 1;
                }
                return 0;
            }
        });


    function formatDocumento(documento) {
        if (!documento) {
            return "";
        } else if (documento.length === 11) {
            return `${documento.substr(0, 3)}.${documento.substr(3, 3)}.${documento.substr(6, 3)}-${documento.substr(9, 2)}`;
        } else if (documento.length === 14) {
            return `${documento.substr(0, 2)}.${documento.substr(2, 3)}.${documento.substr(5, 3)}/${documento.substr(8, 4)}-${documento.substr(12, 2)}`;
        } else {
            return documento;
        }
    }



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };



    const totalPages = Math.ceil(filteredAndSortedClients.length / itemsPerPage);
    const currentPageItems = filteredAndSortedClients.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);





    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (
        <Main {...headerProps}>

            <div className="d-flex justify-content-between">
                <InputGroup className="m-3" style={{ width: "400px" }}>
                    <InputGroup.Text>Pesquisar</InputGroup.Text>
                    <FormControl
                        placeholder="Nome, Apelido ou CPF / CNPJ"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>
                <div className="order-button-container">
                    <Dropdown onSelect={(e) => setOrderBy(e)}>
                        <Dropdown.Toggle variant="outline-dark" className="m-3">
                            Ordenar por
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="nome">Nome</Dropdown.Item>
                            <Dropdown.Item eventKey="documento">CPF / CNPJ</Dropdown.Item>
                            <Dropdown.Item eventKey="lojas">Lojas</Dropdown.Item>
                            <Dropdown.Item eventKey="ativo">Status</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Link to="/app/clientes/cadastro">
                    <Button variant="outline-dark" className="m-3">
                        Cadastrar
                    </Button>
                </Link>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nome / Razao Social</th>
                        <th>CPF / CNPJ</th>
                        <th>Lojas</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageItems.map((cliente) => (
                        <tr key={cliente.id}>
                            <td>
                                <Link to={`/app/clientes/financeiro/${cliente.id}`} className="link">
                                    {cliente.nomeRazao} <br /> {/* Utilize <br /> para quebra de linha, se desejar */}
                                    <span style={{ fontSize: '0.8em', color: '#666' }}>
                                        {cliente.apelidoFantasia}</span>
                                </Link>                          </td>
                            <td style={{ whiteSpace: "nowrap" }}>{formatDocumento(cliente.documento)}</td>
                            <td>{cliente.lojas}</td>
                            <td>
                                <ToggleSwitch
                                    id={`toggle-${cliente.id}`}
                                    isActive={cliente.ativo}
                                    onToggle={(e) => {
                                        e.preventDefault();
                                        handleStatus(cliente.id);
                                    }}
                                />
                            </td>


                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>





        </Main>
    )
}

export default Clientes;
