import './Nav.css'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';



const Nav = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleLogout = (e) => {
        //  localStorage.clear()

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role');
        localStorage.removeItem('inadimplente')


    };

    const userRole = localStorage.getItem('role');


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    return (

        <aside className="menu-area">
            <nav className="menu">

                {userRole === "SYSTEM" ? (
                    <>

                        <Link to="/app/matriz" className='a-principal fa fa-university'> Matrizes</Link>
                        <Link to="/login" onClick={handleLogout}><i className="fa fa-sign-out" aria-hidden="true"></i> Sair</Link>

                    </>
                ) : (
                    <>
                        <Link to="/app/inicio" className='a-principal'>
                            <i className="fa fa-home a-principal"></i> Inicio
                        </Link>
                        <Link to="/app/clientes" className='a-principal' >
                            <i className="fa fa-users" aria-hidden="true"></i> Clientes
                        </Link>
                        <Link to="/app/funcionarios" className='a-principal'>
                            <FontAwesomeIcon icon={faUsers} /> Funcionarios
                        </Link>
                        <Link to="/app/financeiro" className='a-principal'>
                            <i className="fa fa-list-alt" aria-hidden="true"></i> Financeiro
                        </Link>
                        <Link to="/app/fechamento" className='a-principal'>
                            <i className="fa fa-money a-principal"></i> Fechamento
                        </Link>
                        <Link to="/app/relatorios" className='a-principal'>
                            <i className="fa fa-file-text a-principal"></i> Relatórios
                        </Link>
                        <div className="dropdown a-principal" ref={dropdownRef}>
                            <div className="config " onClick={toggleDropdown}>
                                <i className="fa fa-cog "> Configurações</i>

                                <i className={`fa ${showDropdown ? 'fa-angle-left' : 'fa-angle-right'}`}> </i>
                            </div>
                            {showDropdown &&
                                <div className="dropdown-content">
                                    <Link to="/app/minhaconta" onClick={toggleDropdown}>
                                        <i className="fa fa-user-circle"></i> Minha Conta
                                    </Link>
                                    <Link to="/app/lojas" onClick={toggleDropdown}>
                                        <i className="fa fa-university" aria-hidden="true"></i> Lojas
                                    </Link>

                                    <Link to="/app/maquinetas" onClick={toggleDropdown}>
                                        <i className="fa fa-tablet" aria-hidden="true"></i> Maquinetas
                                    </Link>
                                    <Link to="/login" onClick={handleLogout}><i className="fa fa-sign-out" aria-hidden="true"></i> Sair</Link>
                                </div>
                            }
                        </div>
                    </>

                )}
            </nav>
        </aside>
    )
}

export default Nav;
