import React from "react";
import Lojas from '../Lojas/Lojas.jsx'
import Clientes from "../Clientes/Clientes.jsx"
import CadastroCliente from "../Clientes/Cadastro_Cliente.jsx";
import AlterarCliente from "../Clientes/Alterar_Cliente.jsx";
import Inicio from "../Inicio/Inicio.jsx";
import Logo from '../template/Logo.jsx'
import Nav from '../template/Nav.jsx'
import Footer from '../template/Footer.jsx'
import FechamentoDeCaixa from "../FechamentoDeCaixa/FechamentoDeCaixa.jsx";
import { Routes, Route} from 'react-router-dom';
import Maquinetas from "../Maquinetas/Maquinetas.jsx";
import CadastroMaquinetas from "../Maquinetas/Cadastro_Maquinetas.jsx";
import AlterarMaquinetas from "../Maquinetas/Alterar_Maquinetas.jsx";
import CadastroLojas from "../Lojas/Cadastro_Lojas.jsx";
import AlterarLojas from "../Lojas/Alterar_Lojas.jsx";
import AlterarUsuario from "../MinhaConta/Usuarios/Alterar_Usuario.jsx";
import CadastroUsuario from "../MinhaConta/Usuarios/CadastroUsuario.jsx";
import Funcionarios from "../Funcionarios/Funcionarios.jsx";
import AlterarFuncionarios from "../Funcionarios/Alterar_Funcionarios.jsx.jsx";
import CadastroFuncionarios from "../Funcionarios/Cadastro_Funcionarios.jsx";
import FinanceiroCliente from "../Clientes/Financeiro_Cliente.jsx";
import ResumoFinanceiro from "../Financeiro/Resumo_Financeiro.jsx"
import FinanceiroFuncionario from "../Funcionarios/Financeiro_Funcionario.jsx";
import Financeiro from "../Financeiro/Financeiro.jsx";
import Relatorios from "../Relatorios/Relatorios.jsx";
import Matriz from "../Administracao/Matriz/Matriz.jsx";
import CadastroMatriz from "../Administracao/Matriz/Cadastro_Matriz.jsx";
import AlterarMatriz from "../Administracao/Matriz/Alterar_Matriz.jsx";
import UsuariosMatriz from "../Administracao/Matriz/Usuarios/Usuarios_Matriz.jsx";
import AlterarUsuarioMatriz from "../Administracao/Matriz/Usuarios/Alterar_Usuario_Matriz.jsx";
import CadastroUsuarioMatriz from "../Administracao/Matriz/Usuarios/Cadastro_Usuario_Matriz.jsx";
import DadosMatriz from "../Administracao/Matriz/Dados_Matriz.jsx";
import MinhaConta from "../MinhaConta/Minha_Conta.jsx";
import DebtStatus from "../Uso_Geral/DebtStatus.jsx";


const ProtectedRoutes = () => {
    return (
        <div className="app">
        <Logo />
        <Nav />
        <DebtStatus />
        <Routes>
        <Route path="*" element={<Inicio />} />
        <Route path="/inicio" element={<Inicio />} />
        <Route path="/usuarios/cadastro" element={<CadastroUsuario />} />
        <Route path="/usuarios/alterar/:id" element={<AlterarUsuario />} />
        <Route path="/lojas" element={<Lojas/>}/>
        <Route path="/lojas/cadastro" element={<CadastroLojas/>}/>
        <Route path="/lojas/alterar/:id" element={<AlterarLojas/>}/>
        <Route path="/clientes" element={<Clientes />}/>
        <Route path="/clientes/cadastro" element={<CadastroCliente />}/>
        <Route path="/clientes/alterar/:id" element={<AlterarCliente />}/>
        <Route path="/clientes/financeiro/:id" element={<FinanceiroCliente />}/>
        <Route path="/fechamento" element={<FechamentoDeCaixa />}/>
        <Route path="/maquinetas" element={<Maquinetas />}/>
        <Route path="/maquinetas/cadastro" element={<CadastroMaquinetas />}/>
        <Route path="/maquinetas/alterar/:id" element={<AlterarMaquinetas />}/>
        <Route path="/funcionarios" element={<Funcionarios />}/>
        <Route path="/funcionarios/alterar/:id" element={<AlterarFuncionarios />}/>
        <Route path="/funcionarios/cadastro" element={<CadastroFuncionarios />}/>
        <Route path="/funcionarios/financeiro/:id" element={<FinanceiroFuncionario />}/>
        <Route path="/financeiro" element={<Financeiro />}/>
        <Route path="/financeiro/resumo" element={<ResumoFinanceiro />}/>
        <Route path="/minhaconta" element={<MinhaConta />}/>
        <Route path="/relatorios" element={<Relatorios />}/>

        <Route path="/matriz" element={<Matriz />}/>
        <Route path="/matriz/dados/:id" element={<DadosMatriz />}/>
        <Route path="/matriz/cadastro" element={<CadastroMatriz />}/>
        <Route path="/matriz/alterar/:id" element={<AlterarMatriz />}/>
        <Route path="/matriz/usuarios/:id" element={<UsuariosMatriz />}/>
        <Route path="/matriz/usuarios/alterar/:idUsuario/:idMatriz" element={<AlterarUsuarioMatriz />}/>
        <Route path="/matriz/usuarios/cadastro/:idMatriz" element={<CadastroUsuarioMatriz />}/>



        </Routes>
        <Footer />
    </div>
    )
}
export default ProtectedRoutes;
