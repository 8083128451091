import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, InputGroup, FormControl, Dropdown, Pagination } from "react-bootstrap";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';
import Main from '../template/Main'
import { useAlert } from '../Uso_Geral/AlertProvider'
import ToggleSwitch from '../Uso_Geral/ToggleSwitch';


const headerProps = {
    icon: 'tablet',
    title: ' Maquinetas',
    subtitle: 'Incluir, Listar, Alterar e Excluir '
};


function Maquinetas() {

    const showAlert = useAlert();

    const [maquinetas, setMaquinetas] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [orderBy, setOrderBy] = useState("id");


    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(false)

    const [activePage, setActivePage] = useState(1);

    const itemsPerPage = 10;



    useEffect(() => {
        makeApiCall('maquinetas')
            .then((response) => {

                if (response && response.data) {
                    const maquinetas = response.data.map((maquineta) => {
                        // Extrai as razões de todas as lojas associadas à maquineta
                        const lojasRazoes = maquineta.lojas.map((loja) => loja.razao);

                        // Une as razões usando uma vírgula e um espaço como separador
                        const lojasRazoesString = lojasRazoes.join(' / ');

                        return {
                            id: maquineta.id,
                            operadora: maquineta.operadora,
                            identificacao: maquineta.identificacao,
                            numeroDeSerie: maquineta.numeroDeSerie,
                            lojas: lojasRazoesString, // Armazena todas as lojas
                            ativo: maquineta.ativo,
                        };
                    });
                    setMaquinetas(maquinetas);
                    setIsLoading(false);
                }
                console.log(response.data)
            })
            .catch(async (error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {



                    try {
                        const refres = await Refresh();
                        if (refres === 'login') {
                            navigate('/login');
                        }
                    } catch (error) {
                        console.log(" ERRO NA CHAMADA DA API")
                    }





                }


            });
    }, [render, navigate]);

    const handleAlterarClick = (maquinetaId) => {
        navigate(`/app/maquinetas/alterar/${maquinetaId}`);
    };

    const handleExcluir = async (id) => {
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/maquinetas/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Maquineta excluida com sucesso', 'success', 'top-right');
            return response.data;

        } catch (error) {
            if (error.response) {
                // Se o servidor retorna um código de status de erro (ex: 400, 404, 500, etc.)
                showAlert(error.response.data.message || error.response.data, 'error', 'top-right');
            } else {
                // Se um erro ocorre antes de a requisição ser enviada ou depois de a resposta ser recebida
                showAlert(error.message, 'error', 'top-right');
            }

        }
    };

    const handleStatus = async (id) => {


        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`/maquinetas/status/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inativar maquineta');
        }


    };


    const filteredAndSortedClients = (maquinetas || [])
        .filter(
            (maquineta) =>
                (maquineta.identificacao && maquineta.identificacao.toLowerCase().includes(searchTerm.toLowerCase())) ||
                String(maquineta.numeroDeSerie).includes(searchTerm)
        )
        .sort((a, b) => {
            if (orderBy === 'identificacao') {
                const nameA = a.identificacao.toLowerCase();
                const nameB = b.identificacao.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (a[orderBy] < b[orderBy]) {
                    return -1;
                }
                if (a[orderBy] > b[orderBy]) {
                    return 1;
                }
                return 0;
            }
        });



        const handleConfirm = (id) => {
            if(id){handleExcluir(id)}



          };



          const handleCancel = () => {
            console.log('Ação cancelada');
            // Outra lógica de tratamento aqui...
          };



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const totalPages = Math.ceil(filteredAndSortedClients.length / itemsPerPage);
    const currentPageItems = filteredAndSortedClients.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);





    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (
        <Main {...headerProps}>

            <div className="d-flex justify-content-between">
                <InputGroup className="m-3" style={{ width: "300px" }}>
                    <InputGroup.Text>Pesquisar</InputGroup.Text>
                    <FormControl
                        placeholder="Identificação ou Nº de Serie"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>
                <div className="order-button-container">
                <Dropdown onSelect={(e) => setOrderBy(e)}>
                    <Dropdown.Toggle variant="outline-dark" className="m-3">
                        Ordenar por
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="id">ID</Dropdown.Item>
                        <Dropdown.Item eventKey="operadora">Operadora</Dropdown.Item>
                        <Dropdown.Item eventKey="identificacao">Identificação</Dropdown.Item>
                        <Dropdown.Item eventKey="numeroDeSerie">N° de Serie</Dropdown.Item>
                        <Dropdown.Item eventKey="lojas">Lojas</Dropdown.Item>
                        <Dropdown.Item eventKey="ativo">Status</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
                <Link to="/app/maquinetas/cadastro">
                    <Button variant="outline-dark" className="m-3">
                        Cadastrar
                    </Button>
                </Link>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Operadora</th>
                        <th>Identificação</th>
                        <th>Nº de Serie</th>
                        <th>Lojas</th>
                        <th>Status</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageItems.map((maquineta) => (
                        <tr key={maquineta.id}>
                            <td>{maquineta.id}</td>
                            <td>{maquineta.operadora}</td>
                            <td>{maquineta.identificacao}</td>
                            <td>{maquineta.numeroDeSerie}</td>
                            <td>{maquineta.lojas}</td>
                            <td>
                            <ToggleSwitch
                                    id={`toggle-${maquineta.id}`}
                                    isActive={maquineta.ativo}
                                    onToggle={(e) => {
                                        e.preventDefault();
                                        handleStatus(maquineta.id);
                                    }}
                                />
                            </td>
                            <td>
                                <Button className='mt-2' variant="primary" onClick={() => handleAlterarClick(maquineta.id)}>
                                    Alterar
                                </Button>{" "}
                                <ModalConfirmacao
                                    variant="danger"
                                    name="Excluir"
                               question={`Deseja realmente excluir a maquineta ${maquineta.identificacao} ?`}
                                    onConfirm={()=> handleConfirm(maquineta.id)}
                                    onCancel={handleCancel}

                                    />{' '}

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>





        </Main>
    )
}

export default Maquinetas;
