import React, { useState, useEffect } from 'react';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { Modal, Table, Form, FormGroup, FormLabel, FormControl, Card, Button, Pagination, Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import SearchableSelect from '../template/SearchableSelect';
import CommentModal from '../../componentes/Uso_Geral/CommentModal'
import CustomTooltip from '../Uso_Geral/CustomTooltip';
import moment from 'moment';
import axios from 'axios';
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import { useAlert } from '../Uso_Geral/AlertProvider'
import { useNavigate, Link } from 'react-router-dom';
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';


const Adiantamentos = () => {
    const showAlert = useAlert();

    const [isLoading, setIsLoading] = useState(true);
    const [carregando, setCarregando] = useState(true);
    const [render, setRender] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showModalComment, setShowModalComment] = useState(false);


    const [showModalHistorico, setShowModalHistorico] = useState(false);
    const [historico, setHistorico] = useState("");
    const [buscarHistorio, setBuscarHistorio] = useState(false);



    const [lojas, setLojas] = useState([])
    const [lojaId, setLojaId] = useState("")
    const [lojaSelecionada, setLojaSelecionada] = useState("")

    const [funcionarioId, setFuncionarioId] = useState("")
    const [funcionarios, setFuncionarios] = useState([])
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState(null)

    const [situacao, setSituacao] = useState('PENDENTE');
    const [comentario, setComentario] = useState("")

    const [baixaData, setBaixaData] = useState('');
    const [baixaObservacoes, setBaixaObservacoes] = useState('');
    const [totalPages, setTotalPages] = useState('');

    const [adiantamentos, setAdiantamentos] = useState([]);
    const [adiantamentoSelecionado, setAdiantamentoSelecionado] = useState("")



    const [activePage, setActivePage] = useState(1);

    const [itemsPerPage, setItemsPerPage] = useState(10);

    const MAX_ITEMS = 10;
    //const [startItem, setStartItem] = useState(1);
    // Calcula o índice inicial e final com base na página ativa
    const startIndex = Math.max(activePage - Math.floor(MAX_ITEMS / 2), 1);
    const endIndex = Math.min(startIndex + MAX_ITEMS - 1, totalPages);



    useEffect(() => {
        const fetchData = async () => {
            setCarregando(true)
            try {
                const funcionariosResponse = makeApiCall('funcionarios',);
                const lojasResponse = makeApiCall('lojas',);
                const adiantamentosResponse
                    = makeApiCall('adiantamentos/paginada', {
                        statusAdiantamento: situacao,
                        funcionarioId,
                        lojaId,
                        data: '',
                        page: Math.max(activePage - 1, 0),
                        size: itemsPerPage,
                    }


                    );


                const [funcionariosData, lojasData, adiantamentosData,] = await Promise.all([
                    funcionariosResponse,
                    lojasResponse,
                    adiantamentosResponse,


                ]);

                const adiantamentosComFuncionario = adiantamentosData.data.content.map(adiantamento => {
                    const funcionario = funcionariosData.data.find(funcionario => funcionario.id === adiantamento.funcionario_id
                    );
                    return {
                        ...adiantamento,
                        funcionario: funcionario
                    };
                });


                setAdiantamentos(adiantamentosComFuncionario);
                setTotalPages(adiantamentosData.data.totalPages)
                setActivePage(adiantamentosData.data.pageable.pageNumber + 1)
                setLojas(lojasData.data)
                setFuncionarios(funcionariosData.data)

            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
                setCarregando(false)
            }
        };

        fetchData();
    }, [activePage, render, navigate]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'Adiantamentos',
                    entidadeId: adiantamentoSelecionado.id
                });

                setHistorico(historicoResponse.data);

            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [buscarHistorio, navigate]);


    // Função para manipular o envio do formulário de baixa
    const handleBaixaSubmit = async (e) => {
        e.preventDefault();


        if (!baixaData) {
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(
                `adiantamentos/baixar/${adiantamentoSelecionado.id}?dataPagamento=${baixaData}&observacoes=${baixaObservacoes}`,
                {

                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            toggleModal()
            setIsLoading(false);
            setAdiantamentoSelecionado('');
            setBaixaData('');
            setBaixaObservacoes('')
            setRender(!render);

            showAlert('Adiantamento baixado com sucesso ', 'success', 'top-right');
            return response.data;
        } catch (error) {
            console.error(error.response);
            showAlert('Erro ao Baixar adiantamento! ', 'error', 'top-right');
        }
    };




    const handleReverterBaixa = async (id) => {

        setIsLoading(true);

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(
                `adiantamentos/reverter-baixa/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setIsLoading(false);
            setRender(!render);

            showAlert('Baixa de adiantamento revertida com sucesso! ', 'success', 'top-right');
            return response.data;
        } catch (error) {
            console.error(error.response);
            showAlert('Erro ao reverter baixa! ', 'error', 'top-right');

        }
    };









    const toggleModal = () => setShowModal(!showModal);


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    const filtrar = () => {
        setRender(!render)
        setActivePage(0);
    };

    const options = [
        { value: '', label: 'Selecione uma opção', id: '' },
        ...funcionarios.map((funcionario) => ({
            value: funcionario,
            label: funcionario.nomeCompleto,
            id: funcionario.id,
        })),
    ];

    const handleDateChange = (e) => {
        setBaixaData(e.target.value);

    };

    const handleToggleModalComment = (mensagem) => {
        setComentario(mensagem)
        setShowModalComment(true);
    };
    const handleModalClose = () => {
        setComentario('')
        setShowModalComment(false);
    };


    const limparFiltros = () => {
        setSituacao('PENDENTE');
        setLojaSelecionada('');
        setLojaId('');
        setFuncionarioSelecionado(null);
        setFuncionarioId('');
        setBaixaData('');
        setBaixaObservacoes('')
        filtrar();


    };


    const handleOpenModalHistorico = () => {

        setBuscarHistorio(!buscarHistorio)
        setShowModalHistorico(true);
    };

    const handleCloseModalHistorico = () => {
        setShowModalHistorico(false);
    };


    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };



    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }




    return (
        <>

            <h3>Adiantamentos</h3>

            <Form>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><strong>Filtros</strong></Accordion.Header>
                            <Accordion.Body>
                                <Card.Body>
                                    <FormGroup>
                                        <FormLabel htmlFor="filtroCliente"><strong>Funcionário</strong></FormLabel>

                                        <SearchableSelect
                                            options={options}
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setFuncionarioSelecionado(selectedOption);
                                                    setFuncionarioId(selectedOption.id);
                                                } else {
                                                    setFuncionarioSelecionado(null);
                                                    setFuncionarioId('');
                                                }
                                            }}
                                            placeholder="Selecione um funcionário"
                                            value={funcionarioSelecionado}
                                            isDisabled={isLoading}
                                        />


                                    </FormGroup>
                                    <div className="row ">

                                        <div className="col">
                                            <FormGroup>
                                                <FormLabel className='mb-0 mt-2' htmlFor="filtroSituacao"><strong>Situação</strong></FormLabel>
                                                <FormControl
                                                    as="select"
                                                    id="filtroSituacao"
                                                    value={situacao}
                                                    onChange={(e) => setSituacao(e.target.value)}
                                                >
                                                    <option value="">Todas</option>
                                                    <option value="PENDENTE">Pendente</option>
                                                    <option value="PAGO">Pago</option>
                                                </FormControl>
                                            </FormGroup>
                                        </div>
                                        <div className="col">
                                            <FormGroup>
                                                <FormLabel className='mb-0 mt-2' htmlFor="loja"><strong>Loja</strong></FormLabel>
                                                <Form.Control
                                                    as="select"
                                                    value={lojaSelecionada}
                                                    onChange={(event) => {
                                                        const lojaIndex = event.target.selectedIndex - 1;
                                                        setLojaSelecionada(event.target.value);

                                                        if (lojaIndex >= 0) {
                                                            // Quando uma loja específica é selecionada
                                                            setLojaId(lojas[lojaIndex].id);
                                                        } else {
                                                            // Quando "Todas" é selecionado
                                                            // Faça algo aqui quando "Todas" é selecionado
                                                            // Por exemplo, você pode definir lojaId como null ou como uma string vazia
                                                            setLojaId(null);
                                                        }
                                                    }}
                                                >
                                                    <option value="">Todas</option>
                                                    {lojas.map((loja) => (
                                                        <option key={loja.id} value={loja.razao}>{loja.razao}</option>
                                                    ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Card.Body>

                                <Button variant="primary" className='mt-2' onClick={() => filtrar()}>
                                    Filtrar
                                </Button>
                                <Button variant="secondary" onClick={limparFiltros}>
                                    Limpar Filtros
                                </Button>
                            </Accordion.Body>

                        </Accordion.Item>
                    </Card>
                </Accordion>


            </Form>
            <div className="d-flex justify-content-end mt-3 ">
                <FormGroup>
                    <FormLabel htmlFor="itensPorPagina" className="fw-bold">
                        Mostrar
                    </FormLabel>
                    <FormControl
                        as="select"
                        id="itensPorPagina"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                    </FormControl>
                </FormGroup>
            </div>

            <Table className='mt-1 ' striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Funcionario</th>
                        <th>Data de Vencimento</th>
                        <th>Valor</th>
                        <th>Situação</th>
                        <th>Data de Pagamento</th>
                        <th>Loja</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {adiantamentos.map((adiantamento) => (
                        <tr key={adiantamento.id}>
                            <td>{adiantamento.funcionario.nomeCompleto}</td>
                            <td>{moment.utc(adiantamento.data).format('DD/MM/YYYY')}</td>
                            <td >
                                {adiantamento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}{' '}
                                {adiantamento.observacoes && <Link> <CustomTooltip content="Observação" position="top"><i className="fa fa-commenting text-primary custom-hover" aria-hidden="true" onClick={() => handleToggleModalComment(adiantamento.observacoes)}></i></CustomTooltip></Link>}
                            </td>

                            <td >
                                <Badge
                                    bg={
                                        adiantamento.statusAdiantamento === 'PENDENTE'
                                            ? 'warning'
                                            : adiantamento.statusAdiantamento === 'PAGO'
                                                ? 'success'
                                                : 'secondary'
                                    }
                                    text={
                                        adiantamento.statusAdiantamento === 'PENDENTE'
                                            ? 'dark'
                                            : 'light'
                                    }
                                    onClick={() => {
                                        handleOpenModalHistorico();
                                        setAdiantamentoSelecionado(adiantamento);
                                    }}
                                    className="my-button"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {adiantamento.statusAdiantamento}
                                </Badge>
                            </td>


                            <td>
                                {moment.utc(adiantamento.dataPagamento).isValid()
                                    ? moment.utc(adiantamento.dataPagamento).format('DD/MM/YYYY')
                                    : ''}
                            </td>
                            <td>{adiantamento.loja.razao}</td>
                            <td>
                                <Link
                                    onClick={() => {
                                        setAdiantamentoSelecionado(adiantamento)

                                        adiantamento.dataPagamento ? setBaixaData(adiantamento.dataPagamento) : setBaixaData(null);
                                        adiantamento.observacoes ? setBaixaObservacoes(adiantamento.observacoes) : setBaixaObservacoes('');
                                        toggleModal()
                                    }}
                                >

                                    {adiantamento.statusAdiantamento !== 'PAGO' && (
                                        <Button variant="light">
                                            <CustomTooltip content="Baixar Boleto" position="top">
                                                <i className="fa fa-check-circle-o fa-lg text-black fa-hover" aria-hidden="true"></i>
                                            </CustomTooltip>
                                        </Button>

                                    )}
                                </Link>

                                {adiantamento.statusAdiantamento === 'PAGO' && (

                                    <ModalConfirmacao
                                        variant="light"
                                        name={<CustomTooltip content="Reverter Baixa" position="top"><i className="fa fa-undo fa-lg text-black fa-hover" aria-hidden="true"></i></CustomTooltip>}
                                        question={`Deseja realmente reveter a baixa do funcionário: ${adiantamento.funcionario.nomeCompleto} ?`}
                                        onConfirm={() => handleReverterBaixa(adiantamento.id)}
                                        onCancel={handleCancel}

                                    />


                                )}


                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First disabled={carregando} onClick={() => handlePageChange(1)} />
                <Pagination.Prev disabled={carregando} onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />

                {/* Renderiza os itens da páginação dentro do intervalo calculado */}
                {Array.from({ length: endIndex - startIndex + 1 }, (_, index) => {
                    const pageNumber = startIndex + index;
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === activePage}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={carregando}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}

                <Pagination.Next disabled={carregando} onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last disabled={carregando} onClick={() => handlePageChange(totalPages)} />
            </Pagination>

            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Baixa</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleBaixaSubmit}>
                        <FormGroup>
                            <FormLabel>Data da Baixa</FormLabel>
                            <FormControl
                                type="date"
                                value={baixaData}
                                onChange={handleDateChange}
                                required
                            />
                        </FormGroup>



                        <FormGroup>
                            <FormLabel>Observações</FormLabel>
                            <FormControl
                                as="textarea"
                                value={baixaObservacoes}
                                onChange={(e) => setBaixaObservacoes(e.target.value)}
                            />
                        </FormGroup>

                        <Modal.Footer>
                            {adiantamentoSelecionado.statusAdiantamento === 'PAGO' ? (
                                <Button variant="warning" type="submit">
                                    Confirmar Alteração
                                </Button>
                            ) : (
                                <Button variant="primary" type="submit">
                                    Confirmar Baixa
                                </Button>
                            )}
                            <Button variant="secondary" onClick={toggleModal}>
                                Cancelar
                            </Button>
                        </Modal.Footer>

                    </Form>
                </Modal.Body>


            </Modal>


            <CommentModal show={showModalComment} onHide={handleModalClose} comment={comentario} />


            <HistoricoAlteracoesModal
                show={showModalHistorico}
                handleClose={handleCloseModalHistorico}
                historico={historico}
            />


        </>
    );
};

export default Adiantamentos;
