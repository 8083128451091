import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, InputGroup, FormControl, Dropdown, Pagination } from "react-bootstrap";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Main from '../template/Main'
import ToggleSwitch from '../Uso_Geral/ToggleSwitch';


const headerProps = {
    icon: 'users',
    title: ' Funcionarios',
    subtitle: 'Incluir, Listar, Alterar e Excluir '
};


function Funcionarios() {


    const [funcionarios, setFuncionarios] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [orderBy, setOrderBy] = useState("id");


    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(false)

    const [activePage, setActivePage] = useState(1);

    const itemsPerPage = 10;



    useEffect(() => {
        makeApiCall('funcionarios')
            .then((response) => {

                if (response && response.data) {
                    const funcionarios = response.data.map((funcionario) => {
                        // Extrai as razões de todas as lojas associadas à maquineta
                        const lojasRazoes = funcionario.lojas.map((loja) => loja.razao);

                        // Une as razões usando uma vírgula e um espaço como separador
                        const lojasRazoesString = lojasRazoes.join(' / ');

                        return {
                            id: funcionario.id,
                            nomeCompleto: funcionario.nomeCompleto,
                            ativo: funcionario.ativo,
                            apelido: funcionario.apelido,
                            lojas: lojasRazoesString, // Armazena todas as lojas

                        };
                    });
                    setFuncionarios(funcionarios);
                    setIsLoading(false);
                }

            })
            .catch(async (error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {



                    try {
                        const refres = await Refresh();
                        if (refres === 'login') {
                            navigate('/login');
                        }
                    } catch (error) {
                        console.log(" ERRO NA CHAMADA DA API")
                    }





                }


            });
    }, [render, navigate]);



    

    const handleStatus = async (id) => {


        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`/funcionarios/status/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inativar funcionario');
        }


    };

  

    const filteredAndSortedClients = (funcionarios || [])
        .filter(
            (funcionario) =>
                (funcionario.nomeCompleto && funcionario.nomeCompleto.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (funcionario.apelido && funcionario.apelido.toLowerCase().includes(searchTerm.toLowerCase())) ||
                String(funcionario.id).includes(searchTerm)
        )
        .sort((a, b) => {
            if (orderBy === 'nomeCompleto') {
                const nameA = a.nomeCompleto.toLowerCase();
                const nameB = b.nomeCompleto.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (a[orderBy] < b[orderBy]) {
                    return -1;
                }
                if (a[orderBy] > b[orderBy]) {
                    return 1;
                }
                return 0;
            }
        });





    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    

    const totalPages = Math.ceil(filteredAndSortedClients.length / itemsPerPage);
    const currentPageItems = filteredAndSortedClients.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);





    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (
        <Main {...headerProps}>

            <div className="d-flex justify-content-between">
                <InputGroup className="m-3" style={{ width: "300px" }}>
                    <InputGroup.Text>Pesquisar</InputGroup.Text>
                    <FormControl
                        placeholder="Nome ou Apelido"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>
                <div className="order-button-container">
                    <Dropdown className='justify-content' onSelect={(e) => setOrderBy(e)}>
                        <Dropdown.Toggle variant="outline-dark " className="m-3">
                            Ordenar por
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="nomeCompleto">Nome</Dropdown.Item>
                            <Dropdown.Item eventKey="lojas">Lojas</Dropdown.Item>
                            <Dropdown.Item eventKey="ativo">Status</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Link to="/app/funcionarios/cadastro">
                    <Button variant="outline-dark" className="m-3">
                        Cadastrar
                    </Button>
                </Link>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nome Completo</th>
                        <th>Lojas</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageItems.map((funcionario) => (
                        <tr key={funcionario.id}>
                            <td>
                                <Link to={`/app/funcionarios/financeiro/${funcionario.id}`} className="link">
                                    {funcionario.nomeCompleto} <br /> {/* Utilize <br /> para quebra de linha, se desejar */}
                                    <span style={{ fontSize: '0.8em', color: '#666' }}>
                                        {funcionario.apelido}</span>
                                </Link>
                            </td>
                            <td>{funcionario.lojas}</td>


                            <td>
                                <ToggleSwitch
                                    id={`toggle-${funcionario.id}`}
                                    isActive={funcionario.ativo}
                                    onToggle={(e) => {
                                        e.preventDefault();
                                        handleStatus(funcionario.id);
                                    }}
                                />

                            </td>


                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>





        </Main>
    )
}

export default Funcionarios;
