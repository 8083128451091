import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import Login from "./componentes/Views/Login";
import PageRecoveryPassword from "./componentes/Views/PageRecoveryPassword";
import PageSignUp from "./componentes/Views/PageSignup";
import PageAlterarSenha from "./componentes/Views/PageAlterarSenha"
import ProtectedRoutes from "./componentes/Views/ProtectedRoutes";
import Index from "./componentes/Site/index"
import "../node_modules/aos/dist/aos.css"



const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('accessToken'); // Simula uma verificação de autenticação

  // Verifica se o usuário está logado, senão redireciona para o login
  return isLoggedIn ? children : <Navigate to="/login" replace />;
};



export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Index />} />
        <Route path="/signup" element={<PageSignUp />} />
        <Route path="/esqueci-minha-senha" element={<PageRecoveryPassword />} />
        <Route path="/alterar-senha" element={<PageAlterarSenha />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app/*" element={
          <PrivateRoute>
            <ProtectedRoutes />
          </PrivateRoute>
        } />
      </Routes>
    </BrowserRouter>
  );
}