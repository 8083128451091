import React, { createContext, useContext, useState, useCallback } from 'react';

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: '', type: '', position: '', visible: false });

  const showAlert = useCallback((message, type, position, duration = 3000) => {
    setAlert({ message, type, position, visible: true });
    setTimeout(() => {
      setAlert((prev) => ({ ...prev, visible: false }));
    }, duration);
  }, []);

  const closeAlert = () => setAlert((prev) => ({ ...prev, visible: false }));

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert.visible && <Alert {...alert} closeAlert={closeAlert} />}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) throw new Error('useAlert must be used within an AlertProvider');
  return context;
};

const Alert = ({ message, type, position, closeAlert }) => {
  const styles = {
    container: {
      backgroundColor: type === 'error' ? '#ff6961' : (type === 'warning' ? '#ffb347' : '#77dd77'),
      color: '#ffffff',
      padding: '10px 20px',
      borderRadius: '5px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      position: 'fixed',
      zIndex: 1000000000000000,
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'center',
      width: '300px',
      ...getPositionStyle(position),
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      right: '10px',
      top: '10px',
      fontSize: '18px',
      backgroundColor: 'transparent',
      border: 'none',
      color: '#ffffff',
    },
  };

  return (
    <div style={styles.container}>
      <button style={styles.closeButton} onClick={closeAlert}>&times;</button>
      {message}
    </div>
  );
};

const getPositionStyle = (position) => {
  switch (position) {
    case 'top-right':
      return { top: '10px', right: '10px' };
    case 'top-left':
      return { top: '10px', left: '10px' };
    case 'bottom-right':
      return { bottom: '10px', right: '10px' };
    case 'bottom-left':
      return { bottom: '10px', left: '10px' };
    default:
      return { top: '10px', right: '10px' };
  }
};

export default AlertProvider;
