import { Button, Form } from "react-bootstrap";
import Main from "../template/Main";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import SelectLojas from "../Uso_Geral/SelectLojas";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";
import InputMask from 'react-input-mask';
import './Alterar_Funcionarios.css'
import { useAlert } from '../Uso_Geral/AlertProvider'

const headerProps = {
    icon: "users",
    title: " Funcionarios",
    subtitle: "Editar Funcionarios",
};

function AlterarFuncionarios() {
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [apelido, setApelido] = useState("");
    const [cpf, setCpf] = useState("");
    const [cpfFormatado, setCpfFormatado] = useState("");

    const [numeroIdentidade, setNumeroidentidade] = useState("");
    const [emailValido, setEmailValido] = useState(true);
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [celular, setCelular] = useState("");
    const [cargo, setCargo] = useState("");
    const [salario, setSalario] = useState("");
    const [salarioSelecionado, setSalarioSelecionado] = useState("");
    const [dataNascimento, setDataNascimento] = useState("");
    const [dataAdmissao, setDataAdmissao] = useState("");

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [lojasList, setLojasList] = useState([]);
    const [lojasIds, setLojasIds] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const showAlert = useAlert();


    useEffect(() => {
        // Carregar Funcionarios
        makeApiCall(`funcionarios/${id}`)
            .then((response) => {
                setNomeCompleto(response.data.nomeCompleto);
                setApelido(response.data.apelido);
                setCpf(response.data.cpf);
                setCpfFormatado(response.data.cpf)
                setNumeroidentidade(response.data.numeroIdentidade);
                setEmail(response.data.email)
                setCargo(response.data.cargo);
                setTelefone(response.data.telefone);
                setCelular(response.data.celular);
                setSalario(response.data.salario);
                setSalarioSelecionado(response.data.salario);
                setDataNascimento(response.data.dataNascimento);
                setDataAdmissao(response.data.dataAdmissao);
                setLojasIds(response.data.lojas.map(loja => loja.id));

            })
            .catch((error) => {
                console.error(error);
            });



        makeApiCall('lojas')
            .then((response) => {

                if (response && response.data) {
                    const lojas = response.data.map((loja) => ({

                        id: loja.id,
                        razao: loja.razao


                    }));
                    setLojasList(lojas);
                    setIsLoading(false);

                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {

                    Refresh()

                }


            });

    }, [id, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('')


        if (nomeCompleto === "" || lojasIds.length < 1) {
            setError("Por favor, preencha todos os campos e selecione pelo menos uma loja.");
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem("accessToken");

        try {
            const response = await api.put(
                `/funcionarios/${id}`,
                {
                    nomeCompleto,
                    apelido,
                    cpf,
                    numeroIdentidade,
                    cargo,
                    salario,
                    email,
                    telefone,
                    celular,
                    dataNascimento,
                    dataAdmissao,
                    lojasIds,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            showAlert('Funcionário atualizado com sucesso', 'success', 'top-right');
            navigate("/app/funcionarios");
            return response.data;
        } catch (error) {
            setError("Erro ao atualizar funcionario");
            if (error.response) {
                // O servidor respondeu com um status fora do intervalo 2xx
                showAlert(error.response.data.message, 'error', 'top-right',5000);
            } else if (error.request) {
                // A requisição foi feita mas não houve resposta
                console.log("O servidor não respondeu à solicitação.");
            } else {
                // Algo aconteceu na configuração da solicitação que causou um erro
                console.log("Erro ao fazer a solicitação: " + error.message);
            }
        }
    };

    const formatarCpf = (doc) => {
    

        return doc
            .replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (match, g1, g2, g3, g4) => {
                return `${g1}${g2 ? '.' + g2 : ''}${g3 ? '.' + g3 : ''}${g4 ? '-' + g4 : ''}`;
            });
    };

    const handleChangeCpf = (e) => {
        const apenasNumeros = e.target.value.replace(/\D/g, '').slice(0, 11);

        const valorFormatado = formatarCpf(apenasNumeros);
        console.log(cpf)
        setCpf(apenasNumeros)
        setCpfFormatado(valorFormatado);
    };


    const handleChangeNumeroIdentidade = (e) => {
        const valorApenasNumerosHifensEPontos = e.target.value.replace(/[^0-9-.]/g, '');
        setNumeroidentidade(valorApenasNumerosHifensEPontos);
    };

    const handleEmailChange = (e) => {
        const novoEmail = e.target.value;
        setEmail(novoEmail);
        setEmailValido(validarEmail(novoEmail));
    }

    function validarEmail(email) {
        if (email === '') return true; // Considera string vazia como válida
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }


    const handleChangeSalario = (event) => {

        const novoValor = event.target.value.replaceAll(',', '.');
        const salario = event.target.value

        setSalarioSelecionado(salario);
        setSalario(novoValor);
    };


    const handleDateNascimento = (e) => {

        setDataNascimento(e.target.value);

    };

    const handleDateAdmissao = (e) => {

        setDataAdmissao(e.target.value);

    };

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>
                <div className="dados-principais">

                    <Form.Group className="mb-3">

                        <Form.Label>Nome Completo<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome completo"
                            value={nomeCompleto}
                            onChange={(e) => setNomeCompleto(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Apelido</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o apelido ou Fantasia"
                            value={apelido}
                            onChange={(e) => setApelido(e.target.value)}

                        />
                    </Form.Group>
                </div>

                <div className="documentos">

                    <Form.Group className="mb-2">
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o cpf"
                            value={cpfFormatado}
                            onChange={handleChangeCpf}

                        />
                    </Form.Group>


                    <Form.Group className="mb-2">
                        <Form.Label>N° de Identidade </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o número de identidade"
                            value={numeroIdentidade}
                            onChange={handleChangeNumeroIdentidade}

                        />
                    </Form.Group>

                </div>

                <div className="email">

                    <Form.Group className={`mb-2 ${!emailValido ? 'email-invalido' : ''}`}>
                        <Form.Label>E-mail </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Digite o e-mail"
                            value={email}
                            onChange={handleEmailChange}
                            isInvalid={!emailValido}

                        />
                        {!emailValido && <Form.Control.Feedback type="invalid">
                            Por favor, insira um e-mail válido.
                        </Form.Control.Feedback>}
                    </Form.Group>
                </div>


                <div className="contato">


                    <Form.Group className="mb-2">
                        <Form.Label>Celular </Form.Label>
                        <InputMask
                            mask="(99) 99999-9999"
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                            className="form-control"
                            placeholder="Digite o celular"

                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Telefone </Form.Label>
                        <InputMask
                            mask="(99) 9999-9999"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                            className="form-control"
                            placeholder="Digite o telefone"

                        />
                    </Form.Group>
                </div>

                <div className="informacoes">


                    <Form.Group className="mb-2">
                        <Form.Label>Cargo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o cargo"
                            value={cargo}
                            onChange={(e) => setCargo(e.target.value)}

                        />
                    </Form.Group>



                    <Form.Group controlId="valor">
                        <Form.Label>Salário</Form.Label>
                        <Form.Control type="text" placeholder="Digite o valor do Salário (ex: 100,00)"
                            value={salarioSelecionado}
                            isInvalid={salarioSelecionado <= 0}
                            onChange={handleChangeSalario} />
                    </Form.Group>
                </div>


                <div className="informacoes">


                    <Form.Group controlId="dataNascimento">
                        <Form.Label>Data de Nascimento</Form.Label>
                        <Form.Control type="date"
                            value={dataNascimento}
                            onChange={handleDateNascimento}
                        />
                    </Form.Group>

                    <Form.Group controlId="dataNascimento">
                        <Form.Label>Data de Admissão</Form.Label>
                        <Form.Control type="date"
                            value={dataAdmissao}
                            onChange={handleDateAdmissao}
                        />
                    </Form.Group>

                </div>

                <Form.Group className="mb-3">
                    <Form.Label>Lojas</Form.Label>
                    <SelectLojas
                        selectedLojas={lojasIds}
                        onChange={(lojasIds) => setLojasIds(lojasIds)}
                        lojasList={lojasList}
                    />
                </Form.Group>
                <div>
                    {error && <Form.Text className="text-danger">{error}</Form.Text>}
                </div>
                <Button className='mt-2' variant="primary" type="submit">
                    Atualizar
                </Button>
                <Link to="/app/funcionarios">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>
            </Form>
        </Main >
    );
}

export default AlterarFuncionarios;
