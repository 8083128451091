import './App.css';
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertProvider from '../componentes/Uso_Geral/AlertProvider'; // Ajuste o caminho conforme necessário


import Routes from '../routes';


const App = () => {

  return (
    <>

    <AlertProvider>

      <Routes />

      
    </AlertProvider>
    </>
  );
};

export default App;
