import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../../componentes/Shared/SectionTitle";
import Pricing from "../../componentes/Shared/PricingBox";

class Price extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings: [
        {
          id: 1,
          title: "Starter",
          price: 29.90,
          duration: "mês",
          buttonText: "Comece grátis",
          btnLink: "",
          features: [{ title: "3 Usuários" }, { title: "1 Loja" },{ title: "Total Acesso" },{ title: "Relatórios" },{ title: "Dashborad" }],
        },
        {
          id: 2,
          title: "PROFESSIONAL",
          price: 49.90,
          duration: "mês",
          buttonText: "Comece grátis",
          btnLink: "",
          isActive: true,
          features: [
            { title: "5 Usuários" },
            { title: "3 Lojas" },
            { title: "Total Acesso" },{ title: "Relatórios" },{ title: "Dashborad" }
          ],
        },
        {
          id: 3,
          title: "ULTIMATE",
          price: 79.90,
          duration: "mês",
          buttonText: "Comece grátis",
          btnLink: "",
          features: [
            { title: "10 Usuários" },
            { title: "5 Lojas" },
            { title: "Total Acesso" },{ title: "Relatórios" },{ title: "Dashborad" },
          ],
        },
        {
          id: 4,
          title: "PERSONALIZADO",
          price: null,
          duration: "",
          buttonText: "Contate-nos",
          btnLink: "",
          features: [
            { title: "(?) Usuários" },
            { title: "(?) Lojas" },
            { title: "Total Acesso" },{ title: "Relatórios" },{ title: "Dashborad" }
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="pricing">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Conheça nossos planos"
              desc=", gerencie seu fluxo de caixa como nunca e veja como é fácil fazer o fechamento de caixa e controlar suas vendas diárias."
            />

            <Row id="pricing">
              <Pricing pricings={this.state.pricings} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Price;
