import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../../componentes/Shared/SectionTitle";
import TeamBox from "../../componentes/Shared/TeamBox";

//Import Images
import image1 from "../../assets/imgs/client/01.jpg";


class Team extends Component {
  state = {
    candidates: [
      {
        id: 1,
        image: image1,
        name: "Geovanni Correia",
        designation: "Fundador e CTO",
        salary: "2300",
        link: "",
        socialIds: [
          { icon: "instagram", link: "https://www.instagram.com/geovanni.correia/" },
          { icon: "linkedin", link: "https://www.linkedin.com/in/geovanni-dos-santos-correia-420218162/" },
        ],
      }
      

    ],
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Fundador e CTO"
              desc=", gerencie seu fluxo de caixa como nunca e veja como é fácil fazer o fechamento de caixa e controlar suas vendas diárias."
            />

            <Row className="d-flex justify-content-center">
              {/* teambox */}
              <TeamBox candidates={this.state.candidates} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
