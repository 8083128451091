import './Logo.css';
import logo from '../../assets/imgs/LogoClosureSemFundoTracado.png';
import React from 'react';

const Logo = () => {
  return (
    <aside className="logo">
      <a href="/app/inicio" className="logo">
        <img src={logo} alt="logo" />
      </a>
    </aside>
  );
};

export default Logo;
