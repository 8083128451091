import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Main from "../../../template/Main";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../../../Utils/Autenticacao_API";



const headerProps = {
    icon: 'users',
    title: 'Usuários',
    subtitle: 'Cadastro de Usuarios '
};

function CadastroUsuarioMatriz() {
    const { idMatriz } = useParams();
    const [nomeCompleto, setnomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [senhaConfirmada, setSenhaConfirmada] = useState("");
    const [permissaoId, setPermissaoId] = useState("");
    const [permissaoSelecionada, setPermissaoSelecionada] = useState("");
    const [permissoes, setPermissoes] = useState([]);
    const [validated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const regex = /\S+@\S+\.\S+/;
    const [show, setShow] = useState(false);


    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        // verificar se as senhas são iguais
        if (password !== senhaConfirmada) {
            setIsLoading(false)
            return alert("As senhas não coincidem!");
        }

        if (!nomeCompleto || !regex.test(email)  || !permissoes) {
            setIsLoading(false)
            return
        }



        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post(`/admin/usuarios/${idMatriz}`, {

                nomeCompleto,
                email,
                password,
                permissoes: [
                    {
                        id: permissaoId
                    }
                ]

            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });



            setIsLoading(false)
            navigate(-1)

            return response.data;
        } catch (error) {
            console.error(error);
            setIsLoading(false)
            setShow(true)
            //navigate('/app/usuarios')
            throw new Error('Erro ao cadastrar usuário');

        }

    };

    useEffect(() => {
        makeApiCall('admin/permissao/usuario')
            .then((response) => {

                if (response && response.data) {
                    const permissoes = response.data.map((permissao) => ({

                        id: permissao.id,
                        descricao: permissao.descricao


                    }));
                    setPermissoes(permissoes);
                    setIsLoading(false);

                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {

                    Refresh()


                }


            });
    }, [navigate]);




    if (isLoading) {
        return <div class="position-fixed top-50 start-50 translate-middle">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    const handleVoltar = () => {
        navigate(-1);
    };

    return (
        <Main  {...headerProps}>

            {show && (
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>Não foi possivel cadastrar o usuario!</Alert.Heading>
                    <p>
                        Verifique se o usuario não está cadastrado, pois o sistema não aceita cadastro em duplicidade.
                    </p>
                    <Alert.Link href="/matriz/usuario">Verificar usuarios Cadastrados</Alert.Link>
                </Alert>
            )}
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="mx-auto col-md-5 border p-2"
            >
                <Form.Group controlId="nomeCompleto">
                    <Form.Label>Nome Completo</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Digite seu nome completo"
                        value={nomeCompleto}
                        onChange={(event) => setnomeCompleto(event.target.value)}
                        className={!nomeCompleto || nomeCompleto.length < 10 ? 'is-invalid' : 'is-valid'}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, digite seu nome completo.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        placeholder="exemplo@gmail.com"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        isValid={email.length >= 12}
                        isInvalid={!regex.test(email) || email.length < 12}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, digite um email válido.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="senha">
                    <Form.Label>

                        Senha</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Digite uma senha segura"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        isInvalid={password.length > 0 && password.length < 6}
                    />
                    <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                        A senha deve conter pelo menos 6 caracteres
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="senhaConfirmada">
                    <Form.Label>Confirmação de senha</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Digite novamente a senha"
                        value={senhaConfirmada}
                        onChange={(event) => setSenhaConfirmada(event.target.value)}
                        isInvalid={senhaConfirmada !== password}
                    />
                    <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                        As senhas não coincidem
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="permissao">
                    <Form.Label>Permissão</Form.Label>
                    <Form.Control
                        as="select"
                        value={permissaoSelecionada}
                        onChange={(event) => {
                            const permissaoIndex = event.target.selectedIndex - 1; // índice da permissão selecionada no array de permissões
                            if (permissaoIndex >= 0) {
                                setPermissaoSelecionada(event.target.value);
                                setPermissaoId(permissoes[permissaoIndex].id);
                            }
                        }}
                        isValid={permissaoSelecionada !== ""}
                        isInvalid={permissaoSelecionada === ""}
                    >
                        <option disabled selected defaultValue value=''>Selecione uma opção</option>
                        {permissoes.map((permissao) => (
                            <option key={permissao.id} value={permissao.descricao}>
                                {permissao.descricao}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                        Selecione uma permissão
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="success" type="submit" >
                    Cadastrar
                </Button>

                <Button onClick={handleVoltar} variant="outline-dark">Voltar</Button>

            </Form>
        </Main>
    );
}

export default CadastroUsuarioMatriz;
