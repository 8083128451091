import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { makeApiCall, TokenExpirado, Refresh, UrlApi } from "../../Utils/Autenticacao_API";
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../Uso_Geral/AlertProvider'

const Perfil = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const showAlert = useAlert();
    const [usuario, setUsuario] = useState('');
    const [senhaAtual, setSenhaAtual] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [editando, setEditando] = useState(false);
    const [erro, setErro] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [render, setRender] = useState(false)
    const regex = /\S+@\S+\.\S+/;


    useEffect(() => {
        makeApiCall(`usuarios/buscar-usuario-logado`)
            .then((response) => {
                setUsuario(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh()
                }
            });
    }, [render]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            [name]: value,
        }));
    };



    const salvarAlteracoes = async (e) => {
        e.preventDefault();

        setIsLoading(true)
        // Verifica se as senhas coincidem
        if (!usuario.nomeCompleto || usuario.nomeCompleto.length < 10 || !regex.test(usuario.email) || usuario.email.length < 12) {
            setIsLoading(false)
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });


        const accessToken = localStorage.getItem('accessToken');

        try {
            await api.put(`usuarios/atualizar-dados-usuario-logado`, {
                nomeCompleto: usuario.nomeCompleto,
                email: usuario.email
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            showAlert('Dados alterados com sucesso! ', 'success', 'top-right');
            navigate('/app/inicio')
        } catch (error) {
            showAlert('Erro ao alterar Dados! ', 'error', 'top-right');

        } finally {
            setIsLoading(false)
        }
    }


    const handleAlterarSenha = async (e) => {
        e.preventDefault();

        setIsLoading(true)
        // Verifica se as senhas coincidem
        if (novaSenha !== confirmaSenha) {

            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });


        const accessToken = localStorage.getItem('accessToken');

        try {
            await api.put(`usuarios/alterar-senha`, {
                senhaAtual,
                novaSenha
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setNovaSenha('');
            setConfirmaSenha('');
            showAlert('Senha alterada com sucesso! ', 'success', 'top-right');
            navigate('/app/inicio')
        } catch (error) {
            showAlert('Erro ao alterar senha! ', 'error', 'top-right');

        } finally {
            setIsLoading(false)
        }


    };


    const abrirModalAlterarSenha = () => {
        setShowModal(true);
    };


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (
        <Container>
            <Row className="justify-content-center mt-5">
                <Col lg={6}>
                    <Card>
                        <Card.Header as="h5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                Perfil do Usuário
                                <Button variant="info" onClick={abrirModalAlterarSenha} className="ms-md-2">
                                    <i className="fa fa-lock" aria-hidden="true"></i> Alterar Senha
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {erro && <Alert variant="danger">{erro}</Alert>}
                            {sucesso && <Alert variant="success">{sucesso}</Alert>}
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomeCompleto"
                                        value={usuario.nomeCompleto || ''}
                                        onChange={handleChange}
                                        readOnly={!editando}
                                        className={!usuario.nomeCompleto || usuario.nomeCompleto.length < 10 ? 'is-invalid' : 'is-valid'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Por favor, digite seu nome completo.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={usuario.email || ''}
                                        onChange={handleChange}
                                        readOnly={!editando}
                                        className={!regex.test(usuario.email) || usuario.email.length < 12 ? 'is-invalid' : 'is-valid'}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Por favor, digite um email válido.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Botões de ação */}
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    {editando ? (
                                        <>
                                            <Button variant="primary" onClick={salvarAlteracoes} className="me-2">
                                                Salvar Alterações
                                            </Button>
                                            <Button variant="outline-secondary" onClick={() => { setEditando(false); setRender(!render) }}>
                                                Cancelar
                                            </Button>

                                        </>
                                    ) : (
                                        <Button variant="secondary" onClick={() => setEditando(true)}>
                                            Editar Perfil
                                        </Button>
                                    )}

                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal para Alteração de Senha */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alterar Senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAlterarSenha}>

                        <Form.Group className='mt-2' controlId="formCurrentPassword">
                            <Form.Label>Senha Atual:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Digite sua senha atual"
                                value={senhaAtual}
                                onChange={(e) => setSenhaAtual(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className='mt-2' controlId="formNewPassword">
                            <Form.Label>Nova Senha:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Digite sua nova senha"
                                value={novaSenha}
                                onChange={(e) => setNovaSenha(e.target.value)}
                                isInvalid={novaSenha.length > 0 && novaSenha.length < 6}
                                required
                            />
                        </Form.Group>
                        <Form.Group className='mt-2' controlId="formConfirmPassword">
                            <Form.Label>Confirmar Nova Senha:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirme sua nova senha"
                                value={confirmaSenha}
                                onChange={(e) => setConfirmaSenha(e.target.value)}
                                isInvalid={confirmaSenha !== novaSenha}
                                required
                            />
                        </Form.Group>
                        <Button className='mt-3' variant="primary" type="submit">
                            Alterar Senha
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};

export default Perfil;
