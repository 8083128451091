import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class GoogleMap extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60" fluid>
          <Row>
            <Col xs="12" className="p-0">
              <div className="map">
                <iframe
                  title="uniqueTitle"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.3331500114323!2d-35.74410068972068!3d-9.566523000652072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70148754c006509%3A0x119c143a1abc42a1!2sResidencial%20Jardim%20Primavera!5e0!3m2!1spt-BR!2sbr!4v1715478376840!5m2!1spt-BR!2sbr"
                  style={{ border: "0" }}
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default GoogleMap;
