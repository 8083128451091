import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Main from '../template/Main';
import ResumoFinanceiro from './Resumo_Financeiro';
import Boletos from './Boletos';
import './Financeiro.css';
import Recebimentos from './Recebimentos';
import Adiantamentos from './Adiantamentos';

const headerProps = {
    icon: "list-alt",
    title: " Financeiro",
    subtitle: "Valores a Receber",
};

function Financeiro() {
    const [activeTab, setActiveTab] = useState('resumo');

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    return (
        <Main {...headerProps}>
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="fill-tab-example"
                className="mb-3"
                fill
                transition={false} // Desabilita as transições entre os tabs
            >
                <Tab eventKey="resumo" title="Resumo" mountOnEnter unmountOnExit>
                    <ResumoFinanceiro />
                </Tab>
                <Tab eventKey="boletos" title="Boletos" mountOnEnter >
                    <Boletos />
                </Tab>
                <Tab eventKey="recebimentos" title="Recebimentos" mountOnEnter >
                    <Recebimentos />
                </Tab>
                <Tab eventKey="adiantamentos" title="Adiantamentos" mountOnEnter >
                    <Adiantamentos />
                </Tab>
              
            </Tabs>
        </Main>
    );
}

export default Financeiro;
