import React from 'react';
import { useState } from 'react';

import './Relatorios.css';
import Main from '../template/Main';
import VendasMensalRelatorio from './Vendas_Mensal_Relatorio';
import AdiantamentosRelatorio from './Adiantamentos_Relatorio';

const headerProps = {
    icon: 'file-text',
    title: ' Relatórios',
    subtitle: 'Listagem de Dados',
};

const Relatorios = () => {
    const [showVendasModal, setShowVendasModal] = useState(false);
    const handleCloseVendasModal = () => setShowVendasModal(false);

    const [showAdiantamentosModal, setShowAdiantamentosModal] = useState(false);
    const handleCloseAdiantamentosModal = () => setShowAdiantamentosModal(false);


    const onSelecionarRelatorio = (relatorio) => {
        console.log('Relatório selecionado:', relatorio);
    };



    

    return (
        <Main {...headerProps}>
            <div className="d-grid gap-2">
                <h4 className="text-black">Vendas</h4>
                <button className="text-button" onClick={() => setShowVendasModal(true)}>
                    Faturamento mensal
                </button>
                <button className="text-button disabled-btn" onClick={() => onSelecionarRelatorio({ id: 2, nome: 'Listagem de clientes' })}>
                    Faturamento anual (Em breve)
                </button>

                <h4 className="text-black">Clientes</h4>
                <button className="text-button disabled-btn" onClick={() => onSelecionarRelatorio({ id: 2, nome: 'Listagem de clientes' })}>
                    Listagem de clientes (Em breve)
                </button>

                <h4 className="text-black">Funcionários</h4>
                <button className="text-button disabled-btn" onClick={() => onSelecionarRelatorio({ id: 3, nome: 'Listagem de funcionários' })}>
                    Listagem de funcionários (Em breve)
                </button>

                <button className="text-button " onClick={() => setShowAdiantamentosModal(true)}>
                    Adiantamentos
                </button>

                <h4 className="text-black">Financeiro</h4>

                <button className="text-button disabled-btn" onClick={() => onSelecionarRelatorio({ id: 3, nome: 'Listagem de funcionários' })}>
                    Retiradas e Suprimentos de Caixa (Em breve)
                </button>
                <button className="text-button disabled-btn" onClick={() => onSelecionarRelatorio({ id: 3, nome: 'Listagem de funcionários' })}>
                    Pagamentos e Despesas (Em breve)
                </button>

            </div>

            <VendasMensalRelatorio showModalProp={showVendasModal} onCloseModal={handleCloseVendasModal} />
            <AdiantamentosRelatorio showModalProp={showAdiantamentosModal} onCloseModal={handleCloseAdiantamentosModal}/>

        </Main>
    );
};

export default Relatorios;
