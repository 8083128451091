import Select from 'react-select';

const SearchableSelect = ({ options, onChange, placeholder, value }) => {
  const handleOnChange = (selectedOption) => {
    if (selectedOption) {
      onChange(selectedOption);
    } else {
      onChange(null);
    }
  };

  return (
    <Select
      options={options}
      onChange={handleOnChange}
      placeholder={placeholder}
      value={value}
      isSearchable
      isClearable
    />
  );
};

export default SearchableSelect;
