import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import Main from '../template/Main';
import NotFoundPage from '../Error/NotFoundPage';
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import './Financeiro_Cliente.css';
import axios from 'axios';
import { useAlert } from '../Uso_Geral/AlertProvider'
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';

const headerProps = {
    icon: "users",
    title: " Clientes",
    subtitle: "Resumo Financeiro",
};

const FinanceiroCliente = () => {
    const { id } = useParams();
    const [cliente, setCliente] = useState("");
    const [historico, setHistorico] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [buscarHistorio, setBuscarHistorio] = useState(false);

    const [render, setRender] = useState(false)
    const showAlert = useAlert();

    useEffect(() => {
        makeApiCall(`clientes/${id}`)
            .then((response) => {
                setCliente(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh()
                }
            });
    }, [id, navigate]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'Clientes',
                    entidadeId: id
                });

                setHistorico(historicoResponse.data);
                console.log(historicoResponse)
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [id, buscarHistorio, navigate]);



    const handleExcluir = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/clientes/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Cliente excluido com sucesso ', 'success', 'top-right');
            navigate(-1)
            return response.data;

        } catch (error) {
            if (error.response) {
                // Se o servidor retorna um código de status de erro (ex: 400, 404, 500, etc.)
                showAlert(error.response.data.message || error.response.data, 'error', 'top-right');
            } else {
                // Se um erro ocorre antes de a requisição ser enviada ou depois de a resposta ser recebida
                showAlert(error.message, 'error', 'top-right');
            }

        }
    };


    function formatDocumento(documento) {
        if (!documento) {
            return "";
        } else if (documento.length === 11) {
            return `${documento.substr(0, 3)}.${documento.substr(3, 3)}.${documento.substr(6, 3)}-${documento.substr(9, 2)}`;
        } else if (documento.length === 14) {
            return `${documento.substr(0, 2)}.${documento.substr(2, 3)}.${documento.substr(5, 3)}/${documento.substr(8, 4)}-${documento.substr(12, 2)}`;
        } else {
            return documento;
        }
    }

    function formatarTelefone(numero) {
        if (!numero) return '';

        if (numero.length === 11) { // Celular com 9 dígitos
            return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 10) { // Telefone fixo
            return numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
            return numero; // Retorna o número como está caso não corresponda aos formatos esperados
        }
    }


    const handleConfirm = (id) => {
        if (id) { handleExcluir(id) }

    };


    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };

    const handleAlterarClick = (maquinetaId) => {
        navigate(`/app/clientes/alterar/${maquinetaId}`);
    };

    const handleVoltar = () => {
        navigate(-1); // Retorna para a página anterior
    };


    const handleOpenModal = () => {
        setIsLoading(true)
        setBuscarHistorio(!buscarHistorio)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    function formatarCep(cep) {
        // Verifica se o CEP tem 8 dígitos e formata como #####-###
        return cep ? cep.replace(/^(\d{5})(\d{3})$/, "$1-$2") : '';
    }

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {cliente && cliente.nomeRazao ? (
                <Container className="mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Resumo do Cliente</h4>
                        <Button variant="outline-dark" onClick={handleVoltar}>
                            Voltar
                        </Button>
                    </div>

                    <Row className="mb-3">
                        <Col>
                            <Card className="mb-3 mt-3 card-moderno">

                                <Card.Header>

                                    <div className="d-flex justify-content-between align-items-center">


                                        <div className='dados'> <strong>DADOS DO CLIENTE</strong> </div>
                                        <div>
                                            <Button variant="outline-primary me-1" onClick={() => handleAlterarClick(cliente.id)}>
                                                <i className="fa fa-edit">Alterar</i>
                                            </Button>
                                            <ModalConfirmacao
                                                variant="outline-danger"
                                                name="Excluir"
                                                question={`Deseja realmente excluir o cliente ${cliente.nomeRazao}?`}
                                                onConfirm={() => handleConfirm(cliente.id)}
                                                onCancel={handleCancel}
                                            />
                                        </div>

                                    </div>
                                </Card.Header>


                                <Card.Body>

                                    <p className="info-item"><strong>Nome / Razão Social:</strong> {cliente.nomeRazao || ''}</p>
                                    <p className="info-item"><strong>Apelido / Fantasia:</strong> {cliente.apelidoFantasia || ''}</p>

                                    <div className="documentos">
                                        <p className="info-item"><strong>CPF/CNPJ:</strong> {cliente.documento ? formatDocumento(cliente.documento) : ''}</p>
                                        <p className="info-item"><strong>Inscrição Estadual:</strong> {cliente.inscricaoEstadual || ''}</p>
                                    </div>
                                    <p className="info-item"><strong>E-mail:</strong> {cliente.email || ''}</p>

                                    <div className="contato">
                                        <p ><strong>Celular:</strong> {cliente.celular ? formatarTelefone(cliente.celular) : ''}</p>
                                        <p><strong>Telefone:</strong> {cliente.telefone ? formatarTelefone(cliente.telefone) : ''}</p>
                                    </div>

                                    <div className='endereco-principal'>
                                        <div className="endereco-dados">

                                            <p className="endereco-info"><strong>Endereço:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].logradouro : ''}</p>
                                            <p className="endereco-info"><strong>Número:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].numero : ''}</p>
                                        </div>


                                        <div className="endereco-dados">

                                            <p className="endereco-info"><strong>Bairro:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].bairro : ''}</p>
                                            <p className="endereco-info"><strong>Complemento:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].complemento : ''}</p>

                                        </div>

                                        <div className="endereco-dados">
                                            <p className="endereco-info"><strong>Estado:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].estado : ''}</p>
                                            <p className="endereco-info"><strong>Cidade:</strong> {cliente.enderecos?.length > 0 ? cliente.enderecos[0].cidade : ''}</p>
                                            <p className="endereco-info"><strong>Cep:</strong> {cliente.enderecos?.length > 0 ? formatarCep(cliente.enderecos[0].cep) : ''}</p>

                                        </div>
                                    </div>

                                </Card.Body>

                            </Card>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Card className="border border-2 border-warning mb-3">
                                <Card.Header><strong>BOLETOS EM ABERTO</strong></Card.Header>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Card.Body>
                                        <ListGroup>
                                            {cliente.boletos && cliente.boletos.filter((boleto) => boleto.status === 'PENDENTE').length > 0 ? (
                                                cliente.boletos
                                                    .filter((boleto) => boleto.status === 'PENDENTE')
                                                    .map((boleto) => (
                                                        <ListGroup.Item key={boleto.id}>
                                                            <div><strong>Valor:</strong> {boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div><strong>Emissão:</strong> {moment.utc(boleto.data).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Vencimento:</strong> {moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Loja:</strong> {boleto.loja.razao}</div>
                                                        </ListGroup.Item>
                                                    ))
                                            ) : (
                                                <ListGroup.Item>Nenhum boleto em aberto.</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Card className="border border-2 border-danger mb-3">
                                <Card.Header><strong>BOLETOS VENCIDOS</strong></Card.Header>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Card.Body>
                                        <ListGroup>
                                            {cliente.boletos && cliente.boletos.filter((boleto) => boleto.status === 'VENCIDO').length > 0 ? (
                                                cliente.boletos
                                                    .filter((boleto) => boleto.status === 'VENCIDO')
                                                    .map((boleto) => (
                                                        <ListGroup.Item key={boleto.id}>
                                                            <div><strong>Valor:</strong> {boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div><strong>Emissão:</strong> {moment.utc(boleto.data).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Vencimento:</strong> {moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Loja:</strong> {boleto.loja.razao}</div>
                                                        </ListGroup.Item>
                                                    ))
                                            ) : (
                                                <ListGroup.Item>Nenhum boleto vencido.</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card className="border border-2 border-success mb-3">
                                <Card.Header><strong>BOLETOS PAGOS</strong></Card.Header>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Card.Body>
                                        <ListGroup>
                                            {cliente.boletos && cliente.boletos.filter((boleto) => boleto.status === 'PAGO').length > 0 ? (
                                                cliente.boletos
                                                    .filter((boleto) => boleto.status === 'PAGO')
                                                    .map((boleto) => (
                                                        <ListGroup.Item key={boleto.id}>
                                                            <div><strong>Valor:</strong> {boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div><strong>Emissão:</strong> {moment.utc(boleto.data).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Vencimento:</strong> {moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Data do Pagamento:</strong> {moment.utc(boleto.dataPagamento).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Loja:</strong> {boleto.loja.razao}</div>
                                                            {boleto.observacoes && <div><strong>Observação:</strong> {boleto.observacoes}</div>}
                                                        </ListGroup.Item>
                                                    ))
                                            ) : (
                                                <ListGroup.Item>Nenhum boleto pago.</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <NotFoundPage />
            )}


            <div className="d-flex justify-content-between align-items-center">
                <Button variant="link" onClick={() => handleOpenModal()}>Histórico de Alterações</Button>
            </div>

            <HistoricoAlteracoesModal
                show={showModal}
                handleClose={handleCloseModal}
                historico={historico}
            />
        </Main>


    );
};
export default FinanceiroCliente;


