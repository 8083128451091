import { Button, Form } from "react-bootstrap";
import { makeApiCall, UrlApi } from '../../Utils/Autenticacao_API';
import { useState, useEffect } from "react";
import Main from "../template/Main";
import { Link,useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const headerProps = {
    icon: 'university',
    title: 'Lojas',
    subtitle: 'Alterar Lojas: Atualizar informações da loja',
};

function AlterarLojas() {
    const [razao, setRazao] = useState('');
    const [endereco, setEndereco] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        makeApiCall(`lojas/${id}`)
            .then((response) => {
                // Set initial form values
                setRazao(response.data.razao);
                setEndereco(response.data.endereco);


                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const api = axios.create({
            baseURL: UrlApi,
        });

        //const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(
                `lojas/${id}`,
                { razao, endereco },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            );

            console.log(response.data);
            setIsLoading(false);
            navigate('/app/lojas');
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            // navigate('/app/lojas');
            throw new Error('Erro ao alterar loja');
        }
    };

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Razão Social</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a razão social"
                        value={razao}
                        onChange={(e) => setRazao(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o endereço"
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button className='mt-2' variant="primary" type="submit">
                    Atualizar
                </Button>
                <Link to="/app/lojas">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>
            </Form>
        </Main>
    );
}

export default AlterarLojas;
