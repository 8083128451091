//import Images
import logistics01 from "../../../../assets/imgs/logistics/01.jpg";
import logistics02 from "../../../../assets/imgs/logistics/02.jpg";
import logistics03 from "../../../../assets/imgs/logistics/03.jpg";

import amazon from "../../../../assets/imgs/client/amazon.svg";
import google from "../../../../assets/imgs/client/google.svg";
import lenovo from "../../../../assets/imgs/client/lenovo.svg";
import paypal from "../../../../assets/imgs/client/paypal.svg";
import shopify from "../../../../assets/imgs/client/shopify.svg";
import spotify from "../../../../assets/imgs/client/spotify.svg";

import avatarMasculino from "../../../../assets/imgs/client/avatar_masculino.png"
import avatarFeminino from "../../../../assets/imgs/client/avatar_feminico.png"

// Blog Images
import blog1 from '../../../../assets/imgs/logistics/blog01.jpg';
import blog2 from '../../../../assets/imgs/logistics/blog02.jpg';
import blog3 from '../../../../assets/imgs/logistics/blog03.jpg';
import blog01 from "../../../../assets/imgs/blog/01.jpg";
import blog02 from "../../../../assets/imgs/blog/02.jpg";
import blog03 from "../../../../assets/imgs/blog/03.jpg";

import user from "../../../../assets/imgs/icon/user.svg";
import calendar from "../../../../assets/imgs/icon/calendar.svg";
import sandClock from "../../../../assets/imgs/icon/sand-clock.svg";
import pen from "../../../../assets/imgs/icon/pen.svg";
import video from "../../../../assets/imgs/icon/video.svg";
import intellectual from "../../../../assets/imgs/icon/intellectual.svg";
import client1 from "../../../../assets/imgs/client/01.jpg";
import client2 from "../../../../assets/imgs/client/02.jpg";
import client3 from "../../../../assets/imgs/client/03.jpg";
import client4 from "../../../../assets/imgs/client/04.jpg";
import client5 from "../../../../assets/imgs/client/05.jpg";
import client6 from "../../../../assets/imgs/client/06.jpg";

import artDesign from "../../../../assets/imgs/icon/art-and-design.svg";
import smartphone from "../../../../assets/imgs/icon/smartphone.svg";
import clock from "../../../../assets/imgs/icon/clock.svg";
import stairs from "../../../../assets/imgs/icon/stairs.svg";
import code from "../../../../assets/imgs/icon/code.svg";
import device from "../../../../assets/imgs/icon/device.svg";
import Asset190 from "../../../../assets/imgs/illustrator/Asset190.svg";
import Asset189 from "../../../../assets/imgs/illustrator/Asset189.svg";
import Asset192 from "../../../../assets/imgs/illustrator/Asset192.svg";
import Asset187 from "../../../../assets/imgs/illustrator/Asset187.svg";

//import images
import hotel01 from "../../../../assets/imgs/hotel/01.jpg";
import hotel02 from "../../../../assets/imgs/hotel/02.jpg";
import hotel03 from "../../../../assets/imgs/hotel/03.jpg";
import hotel04 from "../../../../assets/imgs/hotel/04.jpg";
import hotel05 from "../../../../assets/imgs/hotel/05.jpg";
import hotel06 from "../../../../assets/imgs/hotel/06.jpg";
import hotel07 from "../../../../assets/imgs/hotel/07.jpg";
import hotel08 from "../../../../assets/imgs/hotel/08.jpg";
import hotel09 from "../../../../assets/imgs/hotel/09.jpg";
import hotelbg01 from "../../../../assets/imgs/hotel/bg01.jpg";
import hotelbg02 from "../../../../assets/imgs/hotel/bg02.jpg";
import hotelbg03 from "../../../../assets/imgs/hotel/bg03.jpg";
import team1 from "../../../../assets/imgs/construction/t1.jpg";
import team3 from "../../../../assets/imgs/construction/t3.jpg";
import team2 from "../../../../assets/imgs/construction/t2.jpg";
import team4 from "../../../../assets/imgs/construction/t4.jpg";
import news1 from "../../../../assets/imgs/construction/o1.jpg";
import new2 from "../../../../assets/imgs/construction/b1.jpg";
import news3 from "../../../../assets/imgs/construction/r1.jpg";
import course1 from "../../../../assets/imgs/course/1.jpg";
import course2 from "../../../../assets/imgs/course/2.jpg";
import course3 from "../../../../assets/imgs/course/3.jpg";
import course4 from "../../../../assets/imgs/course/4.jpg";
import course5 from "../../../../assets/imgs/course/5.jpg";
import course6 from "../../../../assets/imgs/course/6.jpg";
import course7 from "../../../../assets/imgs/course/7.jpg";
import course8 from "../../../../assets/imgs/course/8.jpg";
import course9 from "../../../../assets/imgs/course/9.jpg";

import team01 from "../../../../assets/imgs/client/01.jpg";
import team02 from "../../../../assets/imgs/client/02.jpg";
import team03 from "../../../../assets/imgs/client/03.jpg";
import team04 from "../../../../assets/imgs/client/04.jpg";

import personal01 from "../../../../assets/imgs/personal/1.jpg";
import personal02 from "../../../../assets/imgs/personal/2.jpg";
import personal03 from "../../../../assets/imgs/personal/3.jpg";
import ticket1 from "../../../../assets/imgs/icon/ticket1.svg";
import ticket2 from "../../../../assets/imgs/icon/ticket2.svg";
import ticket3 from "../../../../assets/imgs/icon/ticket3.svg";
const logisticsHomeData = [
  {
    id: 1,
    img: logistics01,
    titleLine1: "Logistic Solutions ",
    titleLine2: "Around the World",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
  {
    id: 2,
    img: logistics02,
    titleLine1: "We Make Your ",
    titleLine2: "Logistic Simple",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
  {
    id: 3,
    img: logistics03,
    titleLine1: "Flexible Logistics ",
    titleLine2: "& Cargo for Business",
    desc: "Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.",
  },
];

const partnersData = [
  {
    id: 1,
    img: amazon,
  },
  {
    id: 2,
    img: google,
  },
  {
    id: 3,
    img: lenovo,
  },
  {
    id: 4,
    img: paypal,
  },
  {
    id: 5,
    img: shopify,
  },
  {
    id: 6,
    img: spotify,
  },
];

const servicesData = [
  {
    id: 1,
    icon: "uil-truck",
    title: "Land Transport",
  },
  {
    id: 2,
    icon: "uil-plane-departure",
    title: "Air Freight",
  },
  {
    id: 3,
    icon: "uil-ship",
    title: "Ocean Freight",
  },
  {
    id: 4,
    icon: "uil-money-stack",
    title: "Cost Saving",
  },
  {
    id: 5,
    icon: "uil-package",
    title: "Safe Packing",
  },
  {
    id: 6,
    icon: "uil-fast-mail-alt",
    title: "Fast Shipping",
  },
];

const reviews = [
  {
    id: 1,
    img: avatarMasculino,
    name: "Adriano Pereira",
    post: "Empresário",
    desc: "Adoramos o sistema! É super completo e nos ajuda a manter tudo organizado. A parte de financeira é fantástica e nos dá uma visão clara de todos os aspectos do nosso negócio. Um investimento que vale cada centavo.",
    rating: 5,
  },
  {
    id: 2,
    img: avatarFeminino,
    name: "Luana Costa",
    post: "Empresária",
    desc: "Este sistema mudou a forma como gerencio e confiro os fechamentos da minhas lojas. Antes usavamos planilhas, era muito dificil controlar valores a receber",
    rating: 4.5,
  },
  {
    id: 3,
    img: avatarMasculino,
    name: "Abílio Rodrigues",
    post: "Gerente",
    desc: "Muito Bom!, faço todo o fechamento dos caixas muito mais rápido. E consigo ter controle melhor dos vales dos funcionários.",
    rating: 5,
  },
  {
    id: 4,
    img: avatarMasculino,
    name: "Felipe Santos",
    post: "Empresário",
    desc: "Simples e objetivo, consigo acessar de qualquer lugar.",
    rating: 5,
  },
  {
    id: 5,
    img: avatarFeminino,
    name: "Eliana Lima",
    post: "Gerente",
    desc: "Excelente, nunca tive problema. Falta alguns relatórios mais falei com o suporte e ficaram de fazer. Atendimento ótimo",
    rating: 4,
  },
  {
    id: 6,
    img: avatarMasculino,
    name: "Lucas Pedro",
    post: "Empresário",
    desc: "Sistema ótimo, nunca pensei que precisava até ter. Facilitou muito.",
    rating: 5,
  },
];

const logisticsBlogs = [
  {
    id: 1,
    image: blog1,
    title: "Helping Companies in  Their Green Transition",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021"
  },
  {
    id: 2,
    image: blog2,
    title: "Key Logistics Trends in Life  Sciences 2021",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021"
  },
  {
    id: 3,
    image: blog3,
    title: "5 Benefits of Working with  a Freight Broker",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "25th June 2021"
  },
];

const featureArray = [
  {
    id: 1,
    icon: "uil uil-airplay h2 text-primary",
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper. ",
    imgUrl: user,
  },
  {
    id: 2,
    icon: "uil uil-calendar-alt h2 text-primary",
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
    imgUrl: calendar,
  },
  {
    id: 3,
    icon: "uil uil-clock h2 text-primary",
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
    imgUrl: sandClock,
  },
];

const testimonials = [
  {
    id: 1,
    name: "Thomas Israel",
    image: client1,
    desc:
      "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
  },
  {
    id: 2,
    name: "Carl Oliver",
    image: client2,
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 3,
    name: "Barbara McIntosh",
    image: client3,
    desc:
      "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
  },
  {
    id: 4,
    name: "Jill Webb",
    image: client4,
    desc:
      "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
  },
  {
    id: 5,
    name: "Dean Tolle",
    image: client5,
    desc:
      "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
  },
  {
    id: 6,
    name: "Christa Smith",
    image: client6,
    desc:
      "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
  },
];

const pricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [{ title: "Full Access" }, { title: "Source Files" }],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 4,
    title: "ULTIMATE",
    price: 79,
    duration: "mo",
    buttonText: "Started Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Free Installment" },
    ],
  },
];

const facilities = [
  {
    icon: "shield",
    title: "Fully Secured",
    desc:
      "Moreover, in Latin only words at the beginning of sentences are capitalized.",
  },
  {
    icon: "cpu",
    title: "Best Performance",
    desc:
      "If the fill text is intended to illustrate the characteristics of sometimes.",
  },
];

const agencyBlogsData = [
  {
    id: 1,
    image: blog01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: blog02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: blog03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    author: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const agencyFeatureArray = [
  {
    id: 1,
    icon: 'uil uil-edit-alt h2 text-primary',
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a eleifend Aliquam aenean elementum semper.",
    imgUrl: pen,
  },
  {
    id: 2,
    icon: 'uil uil-vector-square h2 text-primary',
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text established by compiling unusual word.",
    imgUrl: video,
  },
  {
    id: 3,
    icon: 'uil uil-file-search-alt h2 text-primary',
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
    imgUrl: intellectual,
  },
];

const appsFeatures = [
  {
    icon: "monitor",
    title: "Use On Any Device",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "feather",
    title: "Feather Icons",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "eye",
    title: "Retina Ready",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "user-check",
    title: "W3c Valid Code",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "smartphone",
    title: "Fully Responsive",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
  {
    icon: "heart",
    title: "Browser Compatibility",
    desc:
      "Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.",
  },
];

const appsPricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
    ],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
    ],
  },
];

const classicFeatures = [
  {
    id: 1,
    img: artDesign,
    title: "Design & Branding",
    desc:
      "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
  },
  {
    id: 2,
    img: smartphone,
    title: "Fully Secured",
    desc:
      "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
    isActive: true,
    isColorText: true,
    isDescColor: true,
  },
  {
    id: 3,
    img: clock,
    title: "High Performance",
    desc:
      "If the distribution of letters and distribution 'words' is random, the reader will not be distracted.",
  },
];

const studioProcessesData = [
  {
    id: 1,
    title: "Discuss The Project",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
  {
    id: 2,
    title: "Develop & Elaborate",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
  {
    id: 3,
    title: "Final Approvement",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
    link: "#",
  },
];

const marketingCounters = [
  {
    title: "Happy Client",
    image: Asset190,
    start: 0,
    value: 97,
    postfix: "%",
  },
  { title: "Awards", image: Asset189, start: 0, value: 15, postfix: "+" },
  {
    title: "Job Placement",
    image: Asset192,
    start: 0,
    value: 2,
    postfix: "K",
  },
  {
    title: "Project Complete",
    image: Asset187,
    start: 0,
    value: 98,
    postfix: "%",
  },
];

const enterpriceFeatures = [
  {
    id: 1,
    icon: 'uil uil-edit-alt',
    imgUrl: pen,
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: 'uil uil-vector-square',
    imgUrl: video,
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: 'uil uil-file-search-alt',
    imgUrl: intellectual,
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
  },
  {
    id: 4,
    icon: 'uil uil-airplay',
    imgUrl: user,
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper",
  },
  {
    id: 5,
    icon: 'uil uil-calendar-alt',
    imgUrl: calendar,
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 6,
    icon: 'uil uil-clock h2',
    imgUrl: sandClock,
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const enterpricePricings = [
  {
    id: 1,
    title: "Free",
    price: 0,
    duration: "mo",
    buttonText: "Buy Now",
    btnLink: "",
    features: [{ title: "Full Access" }, { title: "Source Files" }],
  },
  {
    id: 2,
    title: "Starter",
    price: 39,
    duration: "mo",
    buttonText: "Get Started",
    btnLink: "",
    isActive: true,
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "Free Appointments" },
    ],
  },
  {
    id: 3,
    title: "PROFESSIONAL",
    price: 59,
    duration: "mo",
    buttonText: "Try It Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Enhanced Security" },
    ],
  },
  {
    id: 4,
    title: "ULTIMATE",
    price: 79,
    duration: "mo",
    buttonText: "Started Now",
    btnLink: "",
    features: [
      { title: "Full Access" },
      { title: "Enhanced Security" },
      { title: "Source Files" },
      { title: "1 Domain Free" },
      { title: "Free Installment" },
    ],
  },
];

const enterpriceBlogs = [
  {
    id: 1,
    image: blog01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: blog02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: blog03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const serviceFeatures = [
  {
    id: 1,
    icon: 'uil uil-flip-h h2 text-primary',
    imgUrl: stairs,
    title: "Built for Everyone",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.",
  },
  {
    id: 2,
    icon: 'uil uil-minus-path h2 text-primary',
    imgUrl: device,
    title: "Responsive Design",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 3,
    icon: 'uil uil-layers-alt h2 text-primary',
    imgUrl: code,
    title: "Build Everything",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const serviceCounters = [
  {
    title: "Happy Client",
    end: 97,
    postFix: "%",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    title: "Awards",
    end: 15,
    postFix: "+",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    title: "Project Complete",
    end: 98,
    postFix: "%",
    desc:
      "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
];

const paymentsProcess = [
  {
    id: 1,
    icon: 'uil uil-rupee-sign h2 text-primary',
    title: "Low Fees",
    desc:
      "If the distribution of letters and 'words' is random, the reader will not be distracted.",
  },
  {
    id: 2,
    icon: 'uil uil-shield h2 text-primary',
    title: "Safe & Secure",
    desc:
      "For this reason, dummy text usually consists of a more or less random series of words.",
  },
  {
    id: 3,
    icon: 'uil uil-headphones h2 text-primary',
    title: "Amazing Support",
    desc:
      "This prevents repetitive patterns from impairing the overall visual impression.",
  },
  {
    id: 4,
    icon: 'uil uil-exchange h2 text-primary',
    title: "Convenient & Easy",
    desc:
      "It is advantageous when the dummy text is relatively realistic so that the layout.",
  },
];

const paymentsClientsData = [
  {
    id: 1,
    image: client1,
    name: "Thomas Israel",
    post: "C.E.O",
    rating: 5,
    desc:
      "It seems that only fragments of the oem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 2,
    image: client2,
    name: "Carl Oliver",
    post: "P.A",
    rating: 4,
    desc:
      "The advantage of its Latin origin andor distract the viewer's attention from the layout.",
  },
  {
    id: 3,
    image: client3,
    name: "Barbara McIntosh",
    post: "M.D",
    rating: 3.5,
    desc:
      "There is now an abundance of readableives to the classic and tell short, funny or nonsensical stories.",
  },
  {
    id: 4,
    image: client4,
    name: "Jill Webb",
    post: "Manager",
    rating: 4.5,
    desc:
      "According to most sources, Lorum Ipsuthe origin of the text by of the unusual word  he could find",
  },
  {
    id: 5,
    image: client5,
    name: " Dean Tolle",
    post: "Developer",
    rating: 5,
    desc:
      "It seems that only fragments of the oem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 6,
    image: client6,
    name: "Christa Smith",
    post: "Designer",
    rating: 5,
    desc:
      "It seems that only fragments of the otime certain letters at various positions within the text.",
  },
];

const itSolutionProgress = [
  {
    id: 1,
    img: Asset190,
    count: "97",
    percentage: "%",
    heading: "Happy Client",
  },
  {
    id: 2,
    img: Asset189,
    count: "15",
    percentage: "+",
    heading: "Awards",
  },
  {
    id: 3,
    img: Asset192,
    count: "2",
    percentage: "K",
    heading: "Job Placement",
  },
  {
    id: 4,
    img: Asset187,
    count: "98",
    percentage: "%",
    heading: "Project Complete",
  },
];

const itSolutionWhatwedo = [
  {
    id: 1,
    icon: "uil uil-chart-line",
    heading: "Hign Performance",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 2,
    icon: "uil uil-crosshairs",
    heading: "Best Securities",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 3,
    icon: "uil uil-airplay",
    heading: "Trusted Service",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 4,
    icon: "uil uil-rocket",
    heading: "Info Technology",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 5,
    icon: "uil uil-clock",
    heading: "24/7 Support",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 6,
    icon: "uil uil-users-alt",
    heading: "IT Management",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 7,
    icon: "uil uil-file-alt",
    heading: "Certified Company",
    desc: "It is a long established fact that a reader.",
  },
  {
    id: 8,
    icon: "uil uil-search",
    heading: "Data Analytics",
    desc: "It is a long established fact that a reader.",
  },
];

const developerKeyfeatures = [
  {
    id: 1,
    icon: "uil uil-layer-group d-block rounded h3 mb-0",
    hoverIcon: "uil uil-layer-group text-primary full-img",
    title: "Modular",
  },
  {
    id: 2,
    icon: "uil uil-airplay d-block rounded h3 mb-0",
    hoverIcon: "uil uil-airplay text-primary full-img",
    title: "Responsive",
  },
  {
    id: 3,
    icon: "uil uil-focus-target d-block rounded h3 mb-0",
    hoverIcon: "uil uil-focus-target text-primary full-img",
    title: "Customizable",
  },
  {
    id: 4,
    icon: "uil uil-expand-arrows d-block rounded h3 mb-0",
    hoverIcon: "uil uil-expand-arrows text-primary full-img",
    title: "Scalable",
  },
];

const businessFeatures = [
  {
    id: 1,
    icon: 'uil uil-edit-alt h2 text-primary',
    title: "Design & Development",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: 'uil uil-vector-square h2 text-primary',
    title: "Management & Marketing",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: 'uil uil-file-search-alt h2 text-primary',
    title: "Stratagy & Research",
    description:
      "It seems that only fragments of the original text remain in the Lorem the original Ipsum texts used today.",
  },
  {
    id: 4,
    icon: 'uil uil-airplay h2 text-primary',
    title: "Easy To Use",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.",
  },
  {
    id: 5,
    icon: 'uil uil-calendar-alt h2 text-primary',
    title: "Daily Reports",
    description:
      "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
  },
  {
    id: 6,
    icon: 'uil uil-clock h2 text-primary',
    title: "Real Time Zone",
    description:
      "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
  },
];

const hotelClientsData = [
  {
    id: 1,
    image: client1,
    name: "Thomas Israel",
    rating: 5,
    desc:
      "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 2,
    image: client2,
    name: "Carl Oliver",
    rating: 4,
    desc:
      "There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories..",
  },
  {
    id: 3,
    image: client3,
    name: "Barbara McIntosh",
    rating: 3.5,
    desc:
      "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
  },
  {
    id: 4,
    image: client4,
    name: "Jill Webb",
    rating: 4.5,
    desc:
      "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word 'consectetur' he could find",
  },
  {
    id: 5,
    image: client5,
    name: " Dean Tolle",
    rating: 5,
    desc:
      "There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.",
  },
  {
    id: 6,
    image: client6,
    name: "Christa Smith",
    rating: 5,
    desc:
      "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.",
  },
];

const hotelRoomsData = [
  {
    imageUrl: hotel01,
    title: "Regular Room",
    bed: "1",
    bathrooms: "1",
    rent: "350",
    type: "Night",
  },
  {
    imageUrl: hotel02,
    title: "Regular Big Room",
    bed: "1",
    bathrooms: "1",
    rent: "800",
    type: "Night",
  },
  {
    imageUrl: hotel03,
    title: "Deluxe Room",
    bed: "1",
    bathrooms: "2",
    rent: "1100",
    type: "Night",
  },
  {
    imageUrl: hotel04,
    title: "Super Deluxe Room",
    bed: "2",
    bathrooms: "2",
    rent: "1400",
    type: "Night",
  },
  {
    imageUrl: hotel05,
    title: "Deluxe Big Room",
    bed: "3",
    bathrooms: "3",
    rent: "1600",
    type: "Night",
  },
  {
    imageUrl: hotel06,
    title: "Family Room",
    bed: "3",
    bathrooms: "2",
    rent: "1750",
    type: "Night",
  },
  {
    imageUrl: hotel07,
    title: "Premium Room",
    bed: "1",
    bathrooms: "1",
    rent: "1890",
    type: "Night",
  },
  {
    imageUrl: hotel08,
    title: "Super Premium Room",
    bed: "1",
    bathrooms: "1",
    rent: "2050",
    type: "Night",
  },
  {
    imageUrl: hotel09,
    title: "Premium Big Room",
    bed: "1",
    bathrooms: "1",
    rent: "2500",
    type: "Night",
  },
];

const hotelBlogsData = [
  {
    id: 1,
    image: hotelbg01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: hotelbg02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: hotelbg03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const hotelFeaturesData = [
  {
    id: 1,
    icon: 'uil uil-wifi h2 text-primary',
    title: "Free WIFI",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 2,
    icon: 'uil uil-process h2 text-primary',
    title: "Relaxation",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
  {
    id: 3,
    icon: 'uil uil-dumbbell h2 text-primary',
    title: "Spa & Fitness",
    description:
      "It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.",
  },
  {
    id: 4,
    icon: 'uil uil-restaurant h2 text-primary',
    title: "Restaurant",
    description:
      "It seems that only fragments of the original text remain in the Lorem Ipsum fragments texts used today.",
  },
  {
    id: 5,
    icon: 'uil uil-band-aid h2 text-primary',
    title: "Smooth Parallax",
    description:
      "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean elementum semper.",
  },
  {
    id: 6,
    icon: 'uil uil-bed-double h2 text-primary',
    title: "Bedrooms",
    description:
      "Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.",
  },
];

const constructionMembers = [
  {
    id: 1,
    img: team1,
    name: "Ronny Jofra",
    position: "Architecture",
  },
  {
    id: 2,
    img: team3,
    name: "Micheal Carlo",
    position: "Project Manager",
  },
  {
    id: 3,
    img: team2,
    name: "Aliana Rosy",
    position: "Builder Advisor",
  },
  {
    id: 4,
    img: team4,
    name: "Sofia Razaq",
    position: "HR Manager",
  },
];

const constructionNewsData =  [
  {
    id: 1,
    img: news1,
    title: "High quality work for demand our customer.",
  },
  {
    id: 2,
    img: new2,
    title: "Building public support for a severige work bond",
  },
  {
    id: 3,
    img: news3,
    title: "Satisfection for the customer our first parity.",
  },
];

const emailInboxFeatures =  [
  {
    id: 1,
    icon: "uil uil-comment-verify d-block rounded h3 mb-0",
    title: "Instant Chat",
  },
  {
    id: 2,
    icon: "uil uil-image d-block rounded h3 mb-0",
    title: "Media Messages",
  },
  {
    id: 3,
    icon: "uil uil-video d-block rounded h3 mb-0",
    title: "Video Messages",
  },
  {
    id: 4,
    icon: "uil uil-database-alt d-block rounded h3 mb-0",
    title: "Dedicated Server",
  },
  {
    id: 5,
    icon: "uil uil-users-alt d-block rounded h3 mb-0",
    title: "Team Collaboration",
  },
  {
    id: 6,
    icon: "uil uil-apps d-block rounded h3 mb-0",
    title: "Responsive App",
  },
];

const travelBenefitData = [
  {
    id: 1,
    icon: "uil uil-shield-check",
    title: "Secure Payment",
  },
  {
    id: 1,
    icon: "uil uil-thumbs-up",
    title: "Easy Book",
  },
  {
    id: 1,
    icon: "uil uil-keyboard-show",
    title: "Free Amenities",
  },
  {
    id: 1,
    icon: "uil uil-award",
    title: "Best Offers",
  },
  {
    id: 1,
    icon: "uil uil-bookmark",
    title: "Cheap than Other",
  },
  {
    id: 1,
    icon: "uil uil-favorite",
    title: "Top Rated",
  },
  {
    id: 1,
    icon: "uil uil-clock",
    title: "24/7 Support",
  },
  {
    id: 1,
    icon: "uil uil-process",
    title: "Fast Refund",
  },
];

const coursesData = [
  {
    id: 1,
    image: course1,
    title: "Program for Missionaries",
    price: 75,
    rating: 5,
    person: 5,
    students: 30,
    lession: 5,
  },
  {
    id: 2,
    image: course2,
    title: "Access to Higher Education",
    price: 150,
    rating: 3.99,
    person: 5,
    students: 30,
    lession: 5,
    isStarClass:"star-outline",
  },
  {
    id: 3,
    image: course3,
    title: "Course in TEFL Teacher Training",
    price: 175,
    rating: 4.7,
    person: 5,
    students: 30,
    lession: 5,
    isStarClass: "star-half",
  },
  {
    id: 4,
    image: course4,
    title: "Educational Communication",
    price: 22,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 5,
    image: course5,
    title: "Introduction to Epidemiology",
    price: 100,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 6,
    image: course6,
    title: "Good Clinical Practice",
    price: 110,
    rating: 4,
    person: 2,
    students: 30,
    lession: 5,
    isStarClass:"star-outline",
  },
  {
    id: 7,
    image: course7,
    title: "Advanced Diagnostic Research",
    price: 149,
    rating: 4.5,
    person: 8,
    students: 30,
    lession: 5,
    isStarClass: "star-half",
  },
  {
    id: 8,
    image: course8,
    title: "Skills Development Facilitation",
    price: 135,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
  {
    id: 9,
    image: course9,
    title: "PGCE Primary",
    price: 55,
    rating: 5,
    person: 3,
    students: 30,
    lession: 5,
  },
];

const candidatesData = [
  {
    id: 1,
    image: team01,
    name: "Ronny Jofra",
    designation: "Organizer",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 2,
    image: team04,
    name: "Micheal Carlo",
    designation: "Event Manager",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 3,
    image: team03,
    name: "Aliana Rosy",
    designation: "Motivator",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
  {
    id: 4,
    image: team02,
    name: "Sofia Razaq",
    designation: "Speaker",
    link: "",
    socialIds: [
      { icon: "facebook", link: "#" },
      { icon: "instagram", link: "#" },
      { icon: "twitter", link: "#" },
      { icon: "linkedin", link: "#" },
    ],
  },
];

const insuranceFeaturesData = [
  {
    icon: "uil uil-briefcase",
    title: "Our Vision",
    desc:
      "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
  {
    icon: "uil uil-rocket",
    title: "Our Mission",
    desc:
      "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
  {
    icon: "uil uil-crosshairs",
    title: "Our Goal",
    desc:
      "It is a long established fact that a reader will be of a page reader will be of at its layout.",
  },
];

const personalBlogsData = [
  {
    id: 1,
    image: personal01,
    title: "Design your apps in your own way",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 2,
    image: personal02,
    title: "How apps is changing the IT world",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
  {
    id: 3,
    image: personal03,
    title: "Smartest Applications for Business",
    like: "33",
    comment: "08",
    autor: "Calvin Carlo",
    date: "13th August, 2019",
  },
];

const eventPricingsData = [
  {
    id: 1,
    title: "1 DAY TICKETS",
    image: ticket1,
    price: "09",
    duration: "Day",
    desc:
      "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  },
  {
    id: 2,
    title: "FULL TICKETS",
    image: ticket2,
    price: "24",
    duration: "Full",
    desc:
      "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
    isActive: true,
  },
  {
    id: 3,
    title: "COUPLE TICKETS",
    image: ticket3,
    price: "16",
    duration: "Day",
    desc:
      "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  },
];

const onepageFeaturesData = [
  {
    id: 1,
    icon: 'uil uil-edit-alt h2 text-primary',
    title: "Gráficos de Vendas e Outras Informações",
    description:
      "Visualize de forma clara e intuitiva as vendas e outras métricas financeiras importantes. Os gráficos interativos permitem acompanhar o desempenho das vendas ao longo do tempo, comparar diferentes períodos e identificar tendências, facilitando a tomada de decisões estratégicas para o crescimento do seu negócio.",
  },
  {
    id: 2,
    icon: 'uil uil-vector-square h2 text-primary',
    title: "Fechamento de Fluxo de Caixas",
    description:
      "Faça o fechamento de fluxo de caixas diário, colocando todas as informações referente as vendas, despesas, maquinetas e outras informações pertinente as movimentações financeiras. ",
  },
  {
    id: 3,
    icon: 'uil uil-file-search-alt h2 text-primary',
    title: "Conferência de Pix",
    description:
      "Gerencie e confira todos os pagamentos recebidos via Pix de forma simples e eficaz. Caso a sua loja receba pix através de chaves, esses pix podem ser informado no sistema e ser feito a conferência posteriormente no painel de PIX. Tudo isso para evitar erros. ",
  },
  {
    id: 4,
    icon: 'uil uil-airplay h2 text-primary',
    title: "Controle de Vendas por Boleto",
    description:
      "Monitore e controle todas as vendas realizadas por boleto bancário. Com essa funcionalidade, você pode acompanhar o status dos boletos emitidos, identificar pagamentos pendentes e gerenciar o fluxo de caixa com maior precisão, garantindo que nenhuma venda seja perdida ou esquecida.",
  },
  {
    id: 5,
    icon: 'uil uil-calendar-alt h2 text-primary',
    title: "Controle de Adiantamentos/Vales de Funcionários",
    description:
      "Gerencie os adiantamentos e vales concedidos aos funcionários de maneira organizada. Registre todas as transações, acompanhe os saldos de adiantamentos e garanta que todos os valores sejam corretamente deduzidos dos pagamentos futuros, proporcionando transparência e controle nas finanças da empresa.",
  },
  {
    id: 6,
    icon: 'uil uil-clock h2 text-primary',
    title: "Relatórios Detalhados",
    description:
      "Gere relatórios detalhados e personalizados que atendem às suas necessidades específicas. Filtre e organize os dados para obter insights precisos sobre vendas, fluxo de caixa, adiantamentos e outras métricas financeiras importantes. Os relatórios ajudam a identificar áreas de melhoria e oportunidades de crescimento, facilitando a tomada de decisões informadas.",
  },
];

export {
  logisticsHomeData,
  partnersData,
  servicesData,
  reviews,
  logisticsBlogs,
  featureArray,
  testimonials,
  pricings,
  facilities,
  agencyBlogsData,
  agencyFeatureArray,
  appsFeatures,
  appsPricings,
  classicFeatures,
  studioProcessesData,
  marketingCounters,
  enterpriceFeatures,
  enterpricePricings,
  enterpriceBlogs,
  serviceFeatures,
  serviceCounters,
  paymentsProcess,
  paymentsClientsData,
  itSolutionProgress,
  itSolutionWhatwedo,
  developerKeyfeatures,
  businessFeatures,
  hotelClientsData,
  hotelRoomsData,
  hotelBlogsData,
  hotelFeaturesData,
  constructionMembers,
  constructionNewsData,
  emailInboxFeatures,
  travelBenefitData,
  coursesData,
  candidatesData,
  insuranceFeaturesData,
  personalBlogsData,
  eventPricingsData,
  onepageFeaturesData
};