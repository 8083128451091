import React, { useState } from "react";
import { Button, Modal, Form, FormGroup, FormLabel, } from "react-bootstrap";

import {  UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ExportPDF.css'

const ExportPDF = ({ data,lojaId }) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modelo, setModelo] = useState('1');
    const [show, setShow] = useState(false);

    const handleGenerateReport = async () => {



        setIsLoading(true)


        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${UrlApi}/relatorios/fechamentoDeCaixa/visualizarRelatorio?data=${data}&lojaId=${lojaId}&mod=${modelo}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob',
            });

            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        } catch (error) {
            console.error(error);
            const tokenExpirado = TokenExpirado();
            if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                navigate('/login');
            } else if (tokenExpirado === 'acessToken') {
                try {
                    const refres = await Refresh();
                    if (refres === 'login') {
                        navigate('/login');
                    }
                } catch (error) {
                    console.log("ERRO NA CHAMADA DA API");
                }
            }
        } finally {
            setIsLoading(false)
            setShow(false)
        }
    };

    const handleCloseModal = () => {

        setModelo('1')

        setShow(false)
   };


    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle" style={{zIndex: 999999}}>
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    return (
        <>
        <span className='ml-3' onClick={() => setShow(true)}>
            <Button className="border-secondary " variant="light">
            <i className="fa fa-print icon-large" aria-hidden="true"></i>
            </Button>
            
       
      </span>

      <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Gerar Relatório</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <FormGroup>
                        <FormLabel className='mb-0 mt-2' htmlFor="loja"><strong>Modelo de Relatório</strong></FormLabel>
                        <Form.Control
                            as="select"
                            value={modelo}
                            onChange={(event) => {
                                setModelo(event.target.value);
                            }}
                        >
                            <option value='1'>Sem Listagem de Recebimento</option>
                            <option value='2'>Com Listagem de Recebimento</option>
                        </Form.Control>
                    </FormGroup>


                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancelar
                </Button>
                <Button className="mt-2" variant="primary" onClick={handleGenerateReport}>
                    Gerar Relatório
                </Button>
            </Modal.Footer>
        </Modal>

      </>




    );
  };


  export default ExportPDF;

