import React from 'react';
import { Container, Button } from 'react-bootstrap';

const NotFoundPage = () => {
  return (
    <Container className="text-center mt-5">
      <h1 className="display-1">404</h1>
      <h2 className="mb-4">Página não encontrada</h2>
      <p>A página que você está procurando não existe.</p>
      <Button variant="primary" href="/">Voltar para a página inicial</Button>
    </Container>
  );
};

export default NotFoundPage;
