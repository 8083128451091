import React, { useState, useEffect } from 'react';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { Modal, Form, Table, FormGroup, FormLabel, FormControl, Card, Button, Pagination, Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import SearchableSelect from '../template/SearchableSelect';
import CustomTooltip from '../Uso_Geral/CustomTooltip';
import CommentModal from '../../componentes/Uso_Geral/CommentModal'
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import moment from 'moment';
import axios from 'axios';
import { useAlert } from '../Uso_Geral/AlertProvider'
import { useNavigate, Link } from 'react-router-dom';
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';
import './Recebimentos.css'

const Boletos = () => {
    const showAlert = useAlert();

    const [isLoading, setIsLoading] = useState(true);
    const [carregando, setCarregando] = useState(true);
    const [render, setRender] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [showModalHistorico, setShowModalHistorico] = useState(false);
    const [historico, setHistorico] = useState("");
    const [buscarHistorio, setBuscarHistorio] = useState(false);

    const [lojas, setLojas] = useState([])
    const [lojaId, setLojaId] = useState("")
    const [lojaSelecionada, setLojaSelecionada] = useState("")

    const [clienteId, setClienteId] = useState("")
    const [clientes, setClientes] = useState([])
    const [clienteSelecionado, setClienteSelecionado] = useState("")

    const [situacao, setSituacao] = useState('PENDENTE');
    const [showModalComment, setShowModalComment] = useState(false);
    const [comentario, setComentario] = useState("")


    const [baixaData, setBaixaData] = useState('');
    const [baixaJuros, setBaixaJuros] = useState('');
    const [baixaDesconto, setBaixaDesconto] = useState('');
    const [baixaObservacoes, setBaixaObservacoes] = useState('');
    const [totalPages, setTotalPages] = useState('');

    const [valor, setValor] = useState('');

    const [baixaValorTotal, setBaixaValorTotal] = useState('');


    const [boletos, setBoletos] = useState([]);
    const [boletoSelecionado, setBoletoSelecionado] = useState("")

    const [activePage, setActivePage] = useState(1);

    const [itemsPerPage, setItemsPerPage] = useState(10);

    const MAX_ITEMS = 10;
    //const [startItem, setStartItem] = useState(1);
    // Calcula o índice inicial e final com base na página ativa
    const startIndex = Math.max(activePage - Math.floor(MAX_ITEMS / 2), 1);
    const endIndex = Math.min(startIndex + MAX_ITEMS - 1, totalPages);


    useEffect(() => {
        const fetchData = async () => {
            setCarregando(true)
            try {
                const clientesResponse = makeApiCall('clientes',);
                const lojasResponse = makeApiCall('lojas',);
                const boletosResponse = makeApiCall('boletos/por-status', {
                    status: situacao,
                    lojaId,
                    clienteId,
                    page: activePage - 1,
                    size: itemsPerPage,
                }


                );


                const [lojasData, boletosData, clientesData] = await Promise.all([
                    lojasResponse,
                    boletosResponse,
                    clientesResponse

                ]);

                setBoletos(boletosData.data.content);
                setTotalPages(boletosData.data.totalPages)
                setActivePage(boletosData.data.pageable.pageNumber + 1)
                setLojas(lojasData.data)
                setClientes(clientesData.data)

            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
                setCarregando(false)
            }
        };

        fetchData();
    }, [activePage, render, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'Boletos',
                    entidadeId: boletoSelecionado.id
                });

                setHistorico(historicoResponse.data);
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [buscarHistorio, navigate]);




    // Função para manipular o envio do formulário de baixa
    const handleBaixaSubmit = async (e) => {
        e.preventDefault();


        if (!baixaData) {
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(
                `boletos/baixar/${boletoSelecionado.id}`,
                {
                    desconto: baixaDesconto,
                    juros: baixaJuros,
                    valorTotal: baixaValorTotal,
                    dataPagamento: baixaData
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            toggleModal()
            setIsLoading(false);
            setBoletoSelecionado('');
            setRender(!render);

            showAlert('Boleto baixado com sucesso! ', 'success', 'top-right');
            return response.data;
        } catch (error) {
            console.error(error.response);
            showAlert('Erro ao Baixar boleto! ', 'error', 'top-right');

        }
    };




    const handleReverterBaixa = async (id) => {

        setIsLoading(true);

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(
                `boletos/reverter-baixa/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setIsLoading(false);
            setBoletoSelecionado('');
            setRender(!render);

            showAlert('Baixa de boleto revertido com sucesso! ', 'success', 'top-right');
            return response.data;
        } catch (error) {
            console.error(error.response);
            showAlert('Erro ao Baixar boleto! ', 'error', 'top-right');

        }
    };








    const toggleModal = () => {
        setShowModal(!showModal);

    }


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleChangeJuros = (event) => {

        const rawValue = event.target.value;
        setBaixaJuros(rawValue);
    };


    const handleChangeDesconto = (event) => {

        const rawValue = event.target.value;
        setBaixaDesconto(rawValue);
    };

    const filtrar = () => {
        setRender(!render)
        setActivePage(0);
    };

    const options = [
        { value: '', label: 'Selecione uma opção', id: '' },
        ...clientes.map((cliente) => ({
            value: cliente,
            label: cliente.apelidoFantasia ? `${cliente.nomeRazao} - (${cliente.apelidoFantasia})` : cliente.nomeRazao,
            id: cliente.id,
        })),
    ];


    const handleDateChange = (e) => {
        setBaixaData(e.target.value);

    };


    const limparFiltros = () => {
        setSituacao('PENDENTE');
        setLojaSelecionada('');
        setLojaId('');
        setClienteSelecionado(null);
        setClienteId('');
        setBaixaData('');
        setBaixaJuros('');
        setBaixaDesconto('');
        setBaixaObservacoes('');
        filtrar();
    };

    const handleToggleModalComment = (mensagem) => {
        setComentario(mensagem)
        setShowModalComment(true);
    };
    const handleModalClose = () => {
        setComentario('')
        setShowModalComment(false);
    };


    const handleOpenModalHistorico = () => {

        setBuscarHistorio(!buscarHistorio)
        setShowModalHistorico(true);
    };

    const handleCloseModalHistorico = () => {
        setShowModalHistorico(false);

    };



    const calcularTotalDaBaixa = () => {

        const valorCalculado = baixaJuros - baixaDesconto;
        setBaixaValorTotal(valor + valorCalculado)

    };

    const handleBlur = (event) => {
        calcularTotalDaBaixa()
    };


    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };


    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }



    return (
        <>

            <h3>Boletos</h3>

            <Form>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><strong>Filtros</strong></Accordion.Header>
                            <Accordion.Body>
                                <Card.Body>
                                    <FormGroup>
                                        <FormLabel htmlFor="filtroCliente"><strong>Cliente</strong></FormLabel>

                                        <SearchableSelect
                                            options={options}
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setClienteSelecionado(selectedOption);
                                                    setClienteId(selectedOption.id);
                                                } else {
                                                    setClienteSelecionado(null);
                                                    setClienteId('');
                                                }
                                            }}
                                            placeholder="Selecione um cliente"
                                            value={clienteSelecionado}
                                            isDisabled={isLoading} // Adicione esta linha para desabilitar o campo enquanto os dados estão sendo carregados
                                        />
                                    </FormGroup>
                                    <div className="row">

                                        <div className="col">
                                            <FormGroup>
                                                <FormLabel className='mb-0 mt-2' htmlFor="filtroSituacao"><strong>Situação</strong></FormLabel>
                                                <FormControl
                                                    as="select"
                                                    id="filtroSituacao"
                                                    value={situacao}
                                                    onChange={(e) => setSituacao(e.target.value)}
                                                >
                                                    <option value="">Todas</option>
                                                    <option value="PENDENTE">Pendente</option>
                                                    <option value="VENCIDO">Vencido</option>
                                                    <option value="PAGO">Pago</option>
                                                </FormControl>
                                            </FormGroup>
                                        </div>
                                        <div className="col ">
                                            <FormGroup>
                                                <FormLabel className='mb-0 mt-2' htmlFor="loja"><strong>Loja</strong></FormLabel>
                                                <Form.Control
                                                    as="select"
                                                    value={lojaSelecionada}
                                                    onChange={(event) => {
                                                        const lojaIndex = event.target.selectedIndex - 1;
                                                        setLojaSelecionada(event.target.value);

                                                        if (lojaIndex >= 0) {
                                                            // Quando uma loja específica é selecionada
                                                            setLojaId(lojas[lojaIndex].id);
                                                        } else {
                                                            // Quando "Todas" é selecionado
                                                            // Faça algo aqui quando "Todas" é selecionado
                                                            // Por exemplo, você pode definir lojaId como null ou como uma string vazia
                                                            setLojaId(null);
                                                        }
                                                    }}
                                                >
                                                    <option value="">Todas</option>
                                                    {lojas.map((loja) => (
                                                        <option key={loja.id} value={loja.razao}>{loja.razao}</option>
                                                    ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Card.Body>

                                <Button variant="primary" className='mt-2' onClick={() => filtrar()}>
                                    Filtrar
                                </Button>
                                <Button variant="secondary" onClick={limparFiltros}>
                                    Limpar Filtros
                                </Button>

                            </Accordion.Body>

                        </Accordion.Item>
                    </Card>
                </Accordion>


            </Form>
            <div className="d-flex justify-content-end mt-3 ">
                <FormGroup>
                    <FormLabel htmlFor="itensPorPagina" className="fw-bold">
                        Mostrar
                    </FormLabel>
                    <FormControl
                        as="select"
                        id="itensPorPagina"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                    </FormControl>
                </FormGroup>
            </div>

            <Table className='mt-1' striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Data de Vencimento</th>
                        <th>Valor</th>
                        <th>Situação</th>
                        <th>Data de Pagamento</th>
                        <th style={{ whiteSpace: "nowrap" }}>Nº Boleto</th>
                        <th>Loja</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {boletos.map((boleto) => (
                        <tr key={boleto.id}>
                            <td>{boleto.cliente.nomeRazao}<br /> <span style={{ fontSize: '0.8em', color: '#666' }}>
                                {boleto.cliente.apelidoFantasia}</span></td>
                            <td>{moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                {boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}{' '}
                                {boleto.observacoes && <Link> <CustomTooltip content="Observação" position="top"><i className="fa fa-commenting text-primary custom-hover" aria-hidden="true" onClick={() => handleToggleModalComment(boleto.observacoes)}></i></CustomTooltip></Link>}
                            </td>
                            <td>
                                <Badge
                                    bg={
                                        boleto.status === 'PENDENTE'
                                            ? 'warning'
                                            : boleto.status === 'VENCIDO'
                                                ? 'danger'
                                                : boleto.status === 'PAGO'
                                                    ? 'success'
                                                    : 'secondary'
                                    }
                                    text={
                                        boleto.status === 'PENDENTE'
                                            ? 'dark'
                                            : 'light'
                                    }
                                    onClick={() => {
                                        handleOpenModalHistorico();
                                        setBoletoSelecionado(boleto);
                                    }}
                                    className="my-button"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {boleto.status}
                                </Badge>
                            </td>


                            <td>
                                {moment.utc(boleto.dataPagamento).isValid()
                                    ? moment.utc(boleto.dataPagamento).format('DD/MM/YYYY')
                                    : ''}
                            </td>
                            <td >{boleto.identificacao}</td>
                            <td>{boleto.loja.razao}</td>
                            <td>
                                <Link
                                    onClick={() => {
                                        setBoletoSelecionado(boleto);
                                        setValor(boleto.valor);
                                        boleto.dataPagamento ? setBaixaData(boleto.dataPagamento) : setBaixaData(null);
                                        boleto.observacoes ? setBaixaObservacoes(boleto.observacoes) : setBaixaObservacoes('');
                                        boleto.valorTotal ? setBaixaValorTotal(boleto.valorTotal) : setBaixaValorTotal(boleto.valor);
                                        boleto.juros ? setBaixaJuros(boleto.juros) : setBaixaJuros('')
                                        boleto.desconto ? setBaixaDesconto(boleto.desconto) : setBaixaDesconto('')
                                        toggleModal()
                                    }}
                                >
                                    {boleto.status !== 'PAGO' && (
                                        <Button variant="light">
                                            <CustomTooltip content="Baixar Boleto" position="top">
                                                <i className="fa fa-check-circle-o fa-lg text-black fa-hover" aria-hidden="true"></i>
                                            </CustomTooltip>
                                        </Button>


                                    )}
                                </Link>
                                {boleto.status === 'PAGO' && (


                                    <ModalConfirmacao
                                        variant="light"
                                        name={<CustomTooltip content="Reverter Baixa" position="top"><i className="fa fa-undo fa-lg text-black fa-hover" aria-hidden="true"></i></CustomTooltip>}
                                        question={`Deseja realmente reveter a baixa do cliente: ${boleto.cliente.nomeRazao} ?`}
                                        onConfirm={() => handleReverterBaixa(boleto.id)}
                                        onCancel={handleCancel}

                                    />



                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First disabled={carregando} onClick={() => handlePageChange(1)} />
                <Pagination.Prev disabled={carregando} onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />

                {/* Renderiza os itens da páginação dentro do intervalo calculado */}
                {Array.from({ length: endIndex - startIndex + 1 }, (_, index) => {
                    const pageNumber = startIndex + index;
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === activePage}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={carregando}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}

                <Pagination.Next disabled={carregando} onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last disabled={carregando} onClick={() => handlePageChange(totalPages)} />
            </Pagination>

            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Baixa</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleBaixaSubmit}>
                        <FormGroup>
                            <FormLabel>Data de Baixa</FormLabel>
                            <FormControl
                                type="date"
                                value={baixaData}
                                onChange={handleDateChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Juros</FormLabel>
                            <FormControl
                                type="number"
                                value={baixaJuros}
                                onChange={(e) => handleChangeJuros(e)}
                                onBlur={handleBlur}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Desconto</FormLabel>
                            <FormControl
                                type="number"
                                value={baixaDesconto}
                                onChange={(e) => handleChangeDesconto(e)}
                                onBlur={handleBlur}
                            />
                        </FormGroup>

                        <FormGroup className='mt-2'>
                            <FormLabel><strong>Valor Total</strong></FormLabel>
                            <FormControl
                                type="text"
                                value={baixaValorTotal.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                                onChange={(e) => calcularTotalDaBaixa(e.target.value)}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Observações</FormLabel>
                            <FormControl
                                as="textarea"
                                value={baixaObservacoes}
                                onChange={(e) => setBaixaObservacoes(e.target.value)}
                            />
                        </FormGroup>

                        <Modal.Footer>
                            {boletoSelecionado.status === 'PAGO' ? (
                                <Button disabled={!baixaData} variant="warning" type="submit">
                                    Confirmar Alteração
                                </Button>
                            ) : (
                                <Button disabled={!baixaData} variant="success" type="submit">
                                    Confirmar Baixa
                                </Button>
                            )}
                            <Button variant="secondary" onClick={toggleModal}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>


            </Modal>


            <CommentModal show={showModalComment} onHide={handleModalClose} comment={comentario} />


            <HistoricoAlteracoesModal
                show={showModalHistorico}
                handleClose={handleCloseModalHistorico}
                historico={historico}
            />



        </>
    );
};

export default Boletos;
