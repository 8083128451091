import { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../../Utils/Autenticacao_API';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalConfirmacao from '../../Uso_Geral/Modal_Confirmacao';
import { useAlert } from '../../Uso_Geral/AlertProvider'




function Usuarios() {
    const showAlert = useAlert();


    const [usuarios, setUsuarios] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(false)



    useEffect(() => {
        makeApiCall('usuarios')
            .then((response) => {

                if (response && response.data) {
                    const usuarios = response.data.map((usuario) => ({

                        id: usuario.id,
                        nomeCompleto: usuario.nomeCompleto,
                        email: usuario.email,
                        permissao: usuario.permissoes[0].descricao,
                        ativo: usuario.enabled

                    }));
                    setUsuarios(usuarios);
                    setIsLoading(false);

                }
            })
            .catch(async (error) => {
                setIsLoading(false);
                console.log(error.response.data.message)

                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    //navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    try {
                        const refres = await Refresh();
                        if (refres === 'login') {
                            navigate('/login');
                        }
                    } catch (error) {
                        console.log(" ERRO NA CHAMADA DA API")
                    }

                }


            });
    }, [render, navigate]);

    const handleAlterarClick = (usuarioId) => {
        navigate(`/app/usuarios/alterar/${usuarioId}`);
    };



    const handleExcluir = async (id) => {
        // Implementa a lógica para excluir o usuário com o ID fornecido
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/usuarios/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Usuário excluido com sucesso ', 'success', 'top-right');
            return response.data;

        } catch (error) {
            showAlert('Error ao excluir usuário! ', 'error', 'top-right');

        }



    };

    const handleStatus = async (id) => {
        // Implementa a lógica para inativar o usuário com o ID fornecido
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`/usuarios/status/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inativar usuário');
        }
    };



    const handleConfirm = (id) => {
        if (id) { handleExcluir(id) }



    };




    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };



    const getStatusIcon = (ativo) => {
        return ativo ? (
            <FaCheckCircle style={{ color: 'green' }} />
        ) : (
            <FaTimesCircle style={{ color: 'red' }} />
        );
    };


    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    return (
        <>
            <div className="d-flex justify-content-end" >
                <Link to="/app/usuarios/cadastro">
                    <Button variant="outline-dark" className="m-3">Cadastrar</Button>
                </Link>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nome Completo</th>
                        <th>Email</th>
                        <th>Permissão</th>
                        <th>Status</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody >
                    {usuarios.map((usuario) => (
                        <tr key={usuario.id}>
                            <td>{usuario.nomeCompleto}</td>
                            <td>{usuario.email}</td>
                            <td>{usuario.permissao}</td>
                            <td>{getStatusIcon(usuario.ativo)}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                <Button className='mt-2' variant="primary" onClick={() => handleAlterarClick(usuario.id)}>
                                    Alterar
                                </Button>{' '}
                                <ModalConfirmacao
                                    variant="danger"
                                    name="Excluir"
                                    question={`Deseja realmente excluir o usuario ${usuario.nomeCompleto} ?`}
                                    onConfirm={() => handleConfirm(usuario.id)}
                                    onCancel={handleCancel}

                                />{' '}
                                {usuario.ativo ? (
                                    <Button variant="warning" onClick={() => handleStatus(usuario.id)}>
                                        Inativar
                                    </Button>
                                ) : (
                                    <Button variant="success" onClick={() => handleStatus(usuario.id)}>
                                        Ativar
                                    </Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default Usuarios;
