import React, { useState } from "react";
import { Button, Card, Form, Container, Alert, Spinner } from "react-bootstrap";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { isExpired } from 'react-jwt';
import { UrlApi } from "../../Utils/Autenticacao_API";
import logo from '../../assets/imgs/LogoClosureSemFundo.png';
import './Login.css';

export default function Login() {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
    const [submitted, setSubmitted] = useState(false);

    const [email, setEmail] = useState(rememberMe ? localStorage.getItem('email') || '' : '');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);


        try {
            const response = await axios.post(`${UrlApi}/auth/login`, {
                email,
                password,
            });

            if (rememberMe) {
                localStorage.setItem('rememberMe', 'true');
            } else {
                localStorage.removeItem('rememberMe');
            }

            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('inadimplente', response.data.inadimplente);
            console.log(response)
            const token = localStorage.getItem('accessToken');

            if (token) {
                const isTokenExpired = isExpired(token);

                if (isTokenExpired) {
                    console.log('Token expirado');
                    // Faça algo aqui para tratar o token expirado, como redirecionar o usuário para a tela de login
                } else {
                    navigate('/app/');
                }
            } else {
                console.log('Token não encontrado');
            }
        } catch (error) {
            console.error(error);
            setShowAlert(true);
            setIsLoading(false);
        } finally {
            setSubmitted(true);

        }
    };



    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const isEmailValid = (email) => {
        const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailFormat.test(email);
    };

    return (
        <Container fluid className="container-login">
            <Card className="shadow card-login">
                <Card.Body>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            E-mail ou Senha inválidos
                        </Alert>
                    )}
                    <div className="mb-3 mt-md-4 text-center">

                        <img src={logo} alt="Descrição da Imagem" style={{ maxWidth: '150px', height: 'auto' }} />


                        <p className="mb-5">Por favor, entre com seu E-mail e Senha!</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className={submitted && !isEmailValid(email) ? 'is-invalid' : ''}
                                />
                                {submitted && !isEmailValid(email) && (
                                    <Form.Control.Feedback type="invalid">
                                        E-mail inválido.
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="password" placeholder="Digite sua senha" value={password} onChange={(event) => setPassword(event.target.value)}

                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            handleSubmit(event);
                                        }
                                    }

                                    } />
                            </Form.Group>

                            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Lembrar e-mail" checked={rememberMe} onChange={(event) => setRememberMe(event.target.checked)} />
                            </Form.Group>

                            <div className="mx-auto">
                                <p className="mb-0 mt-2">
                                    <Link to="/esqueci-minha-senha" className="text-primary fw-bold">Esqueci minha senha </Link>
                                </p>
                            </div>

                            <div className="d-grid gap-2">
                                <Button className="btn-login" variant="primary" disabled={isLoading} onClick={handleSubmit}>
                                    {isLoading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Entrando...
                                        </>
                                    ) : (
                                        <>Entrar</>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card >
        </Container >
    );
}
