import axios from "axios";
import { isExpired } from 'react-jwt';


export const UrlApi = process.env.REACT_APP_BASE_URL



export async function makeApiCall(endpoint, params = {}) {
    const apiUrl = UrlApi;
    const accessToken = localStorage.getItem('accessToken');

        return await axios.get(`${apiUrl}/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params,
        });

    }



    // Método para Refresh de Token
    export async function Refresh() {
         try {
        const username = localStorage.getItem('username');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!username || !refreshToken) {
            console.log('Informações de usuário ou token de atualização não encontradas');
            return false;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const response = await api.put(`/auth/refresh/${username}`, {}, {
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        });
        console.log(response.data);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
            const isTokenExpired = isExpired(accessToken);

            if (isTokenExpired) {
                console.log('Token expirado');
            } else {
                console.log('Token válido');
            }
        } else {
            console.log('Token não encontrado');
        }
         } catch (error) {
          console.error(error);
        console.log('Não conseguiu refresh');
         return 'login';
        }
    }

    //Verificar se o Refresh token expirou
    export function TokenExpirado() {

        const accessToken = localStorage.getItem('accessToken')
        const refreshToken = localStorage.getItem('refreshToken')

        const AccessTokenExpired = isExpired(accessToken);
        const RefreshTokenExpired = isExpired(refreshToken);

        if (RefreshTokenExpired) {
            console.log('RefreshToken expirado');
            return 'refreshToken'
        } else if (AccessTokenExpired) {
            console.log('AcessToken expirado');
            return 'acessToken'

        } else {
            return false
        }


    }
