import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from '../../Utils/Autenticacao_API';
import axios from 'axios';
import { useAlert } from '../Uso_Geral/AlertProvider';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Spinner
} from "reactstrap";

//Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import recoveryimg from "../../assets/imgs/user/recovery.svg";

const PageChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const showAlert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setToken(token);
  }, [location]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "A nova senha deve ter pelo menos 8 caracteres")
        .required("Nova senha é obrigatória"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "As senhas não coincidem")
        .required("Confirmação de senha é obrigatória"),
    }),
    onSubmit: (values) => {
      handleChangePassword(values);
    }
  });

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    console.log("Teste"+token)

    const api = axios.create({
      baseURL: UrlApi,
    });

    try {
      const response = await api.post(`/auth/redefinir-senha`, {
        token: token,
        novaSenha: values.newPassword,
      });

      setIsLoading(false);
      showAlert('Senha alterada com sucesso!', 'success', 'top-right',10000);
      navigate('/login')
      return response.data;

    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        showAlert(error.response.data.message || 'Erro ao alterar a senha', 'error', 'top-right', 10000);
      } else if (error.request) {
        console.log("O servidor não respondeu à solicitação.");
      } else {
        console.log("Erro ao fazer a solicitação: " + error.message);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Alterar Senha</h4>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">Por favor, digite sua nova senha e confirme para prosseguir com a alteração</p>
                        <div className="mb-3">
                          <Label className="form-label" for="newPassword">Nova Senha <span className="text-danger">*</span></Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon icon="lock" className="fea icon-sm icons" />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="newPassword"
                            id="newPassword"
                            placeholder="Digite sua nova senha"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.newPassword || ""}
                            invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
                          />
                          {validation.touched.newPassword && validation.errors.newPassword ? (
                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label" for="confirmPassword">Confirmar Nova Senha <span className="text-danger">*</span></Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon icon="lock" className="fea icon-sm icons" />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirme sua nova senha"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                          />
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button color="primary">
                            {isLoading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Alterando...
                              </>
                            ) : (
                              <>Alterar Senha</>
                            )}
                          </Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">Lembra da sua senha?</small>
                          <Link to="/login" className="text-dark fw-bold">Fazer Login</Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default PageChangePassword;
