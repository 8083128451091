import React, { useEffect, useState } from 'react';
import BarGraph from './BarGraph';
import { makeApiCall } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import './Dashboard.css';
registerLocale('ptBR', ptBR);



const Dashboard = () => {
    const [storeIds, setStoreIds] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1)); // Início do mês atual
    const [endDate, setEndDate] = useState(getMonthEnd()); // Fim do mês atual
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStoreIds = async () => {
            try {
                const lojasResponse = await makeApiCall('lojas');
                const ids = lojasResponse.data.map(item => item.id);
                setStoreIds(ids);
                setLojas(lojasResponse);
            } catch (error) {
                console.error(error);
                navigate('/login');
            }
        };

        fetchStoreIds();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allStoreData = [];

                for (let i = 0; i < storeIds.length; i++) {
                    const storeDataResponse = await makeApiCall('vendas/buscarPorPeriodoELojas', {
                        inicio: formatDate(startDate),
                        fim: formatDate(endDate),
                        lojasIds: storeIds[i]
                    });

                    const storeData = storeDataResponse.data;
                    const storeName = storeDataResponse.data[0]?.loja?.razao; // Get the store name from the response

                    allStoreData.push({
                        storeId: storeIds[i],
                        storeName: storeName ? storeName : lojas.data[i]?.razao,
                        data: storeData
                    });
                }

                setSalesData(allStoreData);
            } catch (error) {
                console.error(error);
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, storeIds]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function getMonthEnd() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const lastDay = new Date(year, month, 0).getDate();
        return new Date(year, month - 1, lastDay);
    }

    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    return (
        <div className="container">
            
            <div className="date-container">
            <h3>Dashboard de Vendas</h3>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    locale="ptBR"
                    className="date-picker"
                />
                <span>até</span>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    locale="ptBR"
                    className="date-picker"
                />
            </div>

            {salesData && salesData.length > 0 ? (
                <div style={{ width: '100%', height: '350px' }}>
                    <BarGraph data={salesData} />
                </div>
            ) : (
                <div>Nenhum dado disponível.</div>
            )}
        </div>
    );
};

export default Dashboard;
