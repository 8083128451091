import React, { useState } from 'react';
import { Modal, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import pix from '../../../assets/imgs/pix.png'
import boleto from '../../../assets/imgs/boleto.png'


const FaturaPagarModal = ({ show, onHide, fatura }) => {


  const [paymentMethod, setPaymentMethod] = useState('pix');





  const handlePayment = () => {
    console.log(paymentMethod)
    if (paymentMethod === 'pix') {
      window.open(fatura.linkPix, '_blank');
    } else {
      window.open(fatura.linkBoleto, '_blank');

    }

    onHide(); // Fechar o modal após a escolha

  };




  return (
    <Modal show={show} onHide={onHide} centered>

      <Modal.Header closeButton>
        <Modal.Title>Pagar Fatura</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Como você deseja pagar sua fatura?</p>
        <ToggleButtonGroup type="radio" name="paymentMethods" defaultValue={paymentMethod}>
          <ToggleButton
            id="tbg-radio-1"
            variant="outline-primary"
            value="pix"
            checked={paymentMethod === 'pix'}
            onChange={(e) => setPaymentMethod(e.currentTarget.value)}
          >
            <img src={pix} alt="Pix" style={{ width: 90, marginRight: 35 }} />Pix
          </ToggleButton>
          <ToggleButton
            id="tbg-radio-2"
            variant="outline-secondary"
            value="boleto"
            checked={paymentMethod === 'boleto'}
            onChange={(e) => setPaymentMethod(e.currentTarget.value)}
          >
            <img src={boleto} alt="Pix" style={{ width: 90, marginRight: 35 }} />Boleto
          </ToggleButton>
        </ToggleButtonGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
        <Button variant="success" onClick={handlePayment}>
          Pagar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FaturaPagarModal;
