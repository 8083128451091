import { useState, useEffect } from 'react';
import Main from '../template/Main';
import { makeApiCall, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';




const headerProps = {
    icon: 'users',
    title: ' Bem-Vindo(a)',
    subtitle: 'Que bom que retornou à nossa plataforma',
};

function Inicio() {

    const [frase, setFrase] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await makeApiCall('frases');
                if (response && response.data) {
                    const frases = {
                        autor: response.data.autor,
                        texto: response.data.texto
                    };
                    setFrase(frases);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    try {
                        await Refresh();
                      ///  fetchData(); // Chama a função fetchData novamente após o refresh do token
                    } catch (error) {
                        console.log("ERRO NA CHAMADA DE REFRESH");
                        navigate('/login');
                    }
                }
            }
        };

        fetchData();
    }, [navigate]);

    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }


    return <Main {...headerProps}>
        <div className="row">
            <div className="col">
                <h2 className="text-primary">{frase.autor}</h2>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <blockquote className="blockquote">
                    <p className="mb-0">{`"${frase.texto}"`}</p>
                </blockquote>
            </div>

        </div>


        <Dashboard />






    </Main>;
}

export default Inicio;
