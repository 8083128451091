import React from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import moment from 'moment';

const HistoricoAlteracoesModal = ({ show, handleClose, historico }) => {

  if (!Array.isArray(historico)) {
    return null; // ou exiba uma mensagem de erro adequada
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Histórico de Alterações</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {historico.length > 0 ? (
            <ListGroup>
              {historico.map(item => (
                <ListGroup.Item key={item.id}>
                  <strong>{item.acao}</strong><br />
                  <strong>{'Data e Hora: '}</strong>{moment(item.dataHora).format('DD/MM/YYYY HH:mm:ss')}<br />
                  <strong>Por:</strong> {item.usuario}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>Nenhum dado disponível.</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HistoricoAlteracoesModal;
