import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Main from "../../../template/Main";
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../../../Utils/Autenticacao_API";

const headerProps = {
    icon: 'users',
    title: 'Usuários',
    subtitle: 'Alteração de Usuarios '
};

function AlterarUsuarioMatriz() {
    const { idUsuario, idMatriz } = useParams();
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [permissaoId, setPermissaoId] = useState("");
    const [permissaoSelecionada, setPermissaoSelecionada] = useState("");
    const [permissoes, setPermissoes] = useState([]);
    const [validated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const regex = /\S+@\S+\.\S+/;
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);


        const api = axios.create({
            baseURL: UrlApi
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`usuarios/${idUsuario}`, {
                nomeCompleto,
                email,
                permissoes: [
                    {
                        id: permissaoId
                    }
                ]
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            console.log(response.data);
            setIsLoading(false);
            navigate('/app/usuarios');

            return response.data;
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setShowAlert(true);
            throw new Error('Erro ao alterar usuário');
        }
    };

    useEffect(() => {

        makeApiCall('admin/permissao/usuario')
            .then((response) => {
                if (response && response.data) {
                    const permissoes = response.data.map((permissao) => ({
                        id: permissao.id,
                        descricao: permissao.descricao
                    }));
                    setPermissoes(permissoes);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            });



            makeApiCall(`admin/usuarios/por-id`,{idUsuario,idMatriz})
            .then((response) => {
                if (response && response.data) {
                    setNomeCompleto(response.data.nomeCompleto);
                    setEmail(response.data.email);
                    setPermissaoId(response.data.permissoes[0].id);
                    setPermissaoSelecionada(response.data.permissoes[0].descricao)

                }
            })
            .catch((error) => {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    console.log('teste 1');
                    Refresh();
                }
            });

    }, [idUsuario,navigate]);

    const handleVoltar = () => {
        navigate(-1); // Retorna para a página anterior
    };



    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main  {...headerProps}>
            {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>Não foi possível alterar o usuário!</Alert.Heading>
                    <p>Verifique se o usuário já foi cadastrado.</p>
                    <Alert.Link href="/usuarios">Verificar usuários cadastrados</Alert.Link>
                </Alert>
            )}

            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="mx-auto col-md-5 border p-2"
            >
                <Form.Group controlId="nomeCompleto">
                    <Form.Label>Nome Completo</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Digite seu nome completo"
                        value={nomeCompleto}
                        onChange={(event) => setNomeCompleto(event.target.value)}
                        className={!nomeCompleto || nomeCompleto.length < 10 ? 'is-invalid' : 'is-valid'}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, digite seu nome completo.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        placeholder="exemplo@gmail.com"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        isValid={email.length >= 12}
                        isInvalid={!regex.test(email) || email.length < 12}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, digite um email válido.
                    </Form.Control.Feedback>
                </Form.Group>



                <Form.Group controlId="permissao">
                    <Form.Label>Permissão</Form.Label>
                    <Form.Control
                        as="select"
                        value={permissaoSelecionada}
                        onChange={(event) => {
                            const permissaoIndex = event.target.selectedIndex - 1;
                            if (permissaoIndex >= 0) {
                                setPermissaoSelecionada(event.target.value);
                                setPermissaoId(permissoes[permissaoIndex].id);
                                console.log(permissaoSelecionada)
                            }
                        }}
                        isValid={permissaoSelecionada !== ""}
                        isInvalid={permissaoSelecionada === ""}
                    >
                        <option disabled value={permissaoSelecionada.id}>Selecione uma opção</option>
                        {permissoes.map((permissao) => (
                            <option key={permissao.id} value={permissao.descricao}>
                                {permissao.descricao}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                        Selecione uma permissão
                    </Form.Control.Feedback>
                </Form.Group>

                <Button className='mt-2' variant="primary" type="submit" >
                    Atualizar
                </Button>
                    <Button onClick={handleVoltar} variant="outline-dark">Voltar</Button>
            </Form>
        </Main>
    );
}

export default AlterarUsuarioMatriz;


