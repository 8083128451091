import React, { useState, useEffect } from 'react';
import {  Spinner } from 'react-bootstrap';
import { makeApiCall, TokenExpirado, Refresh } from "../../../Utils/Autenticacao_API";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './Faturas.css'
import FaturaPagarModal from './Fatura_Pagar_Modal';

const Fatura = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [faturas, setFaturas] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [fatura, setFatura] = useState('');

    useEffect(() => {
        makeApiCall(`usuarios/buscar-faturas`)
            .then((response) => {
                setFaturas(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh()
                }
            });
    }, []);



    const handlePagar = (fatura) => {
        setFatura(fatura);
        setModalShow(true)

    };


    // Função de comparação para ordenar do maior para o menor ID
    const compararPorIdDesc = (a, b) => b.id - a.id;

    // Ordenar as faturas do maior para o menor ID
    const faturasOrdenadas = [...faturas].sort(compararPorIdDesc);


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (

        <div className="faturas-container">

            {faturas && faturas.length > 0 ? (
                <table className="faturas-table">
                    <thead>
                        <tr>
                            <th>Valor</th>
                            <th>Data de Vencimento</th>
                            <th>PERÍODO INICIAL</th>
                            <th>PERÍODO FINAL</th>
                            <th>Forma de Pagamento</th>
                            <th>Status da Fatura</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {faturasOrdenadas.map((fatura) => (
                            <tr key={fatura.id}>
                                <td>{fatura.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td>{fatura.dataVencimento ? moment.utc(fatura.dataVencimento).format('DD/MM/YYYY') : ''}</td>
                                <td>{fatura.dataPeridoInicial ? moment.utc(fatura.dataPeridoInicial).format('DD/MM/YYYY') : ''}</td>
                                <td>{fatura.dataPeridoFinal ? moment.utc(fatura.dataPeridoFinal).format('DD/MM/YYYY') : ''}</td>
                                <td>{fatura.meioPagamento}</td>
                                <td>
                                    <span className={`badge ${fatura.statusFatura === 'ABERTO' ? 'bg-warning'
                                        : fatura.statusFatura === 'PAGO' ? 'bg-success'
                                            : 'bg-danger'}`}>
                                        {fatura.statusFatura}
                                    </span>
                                </td>

                                <td>
                                    {fatura.statusFatura !== 'PAGO' && (
                                        <button onClick={() => handlePagar(fatura)} className="btn btn-primary">
                                            Pagar
                                        </button>
                                    )}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Nenhuma fatura encontrada.</p>
            )}



            <FaturaPagarModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                fatura={fatura}
            />

        </div>



    );
};

export default Fatura;
