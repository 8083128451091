import React, { useState } from 'react';
import Main from '../template/Main';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Perfil from './Perfil';
import Usuarios from './Usuarios/Usuarios';
import Faturas from './Faturas/Faturas';

const headerProps = {
    icon: "user",
    title: " Minha Conta",
    subtitle: "Painel Administrativo",
};


const MinhaConta = () => {

    const [activeTab, setActiveTab] = useState('perfil');



    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };


    return (

        <Main {...headerProps}>


            <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="fill-tab-example"
                className="mb-3"
                fill
                transition={false} // Desabilita as transições entre os tabs
            >


                <Tab eventKey="perfil" title="Perfil" mountOnEnter >
                    <Perfil/>
                </Tab>
                <Tab eventKey="usuarios" title="Usuários" mountOnEnter >
                    <Usuarios/>
                </Tab>
                <Tab eventKey="dadosdefaturamento" title="Dados de Faturamento" mountOnEnter >
                    
                    <Faturas/>
                </Tab>




            </Tabs>





        </Main>
    );
};

export default MinhaConta;
