import React, { useState, useEffect } from 'react';
import { makeApiCall, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { Form, Table, Container } from 'react-bootstrap';
import './Resumo.css'
import '../Clientes/Clientes.css'



const ResumoFinanceiro = () => {
    const [boletosVencidos, setBoletosVencidos] = useState([]);
    const [adiantamentosEmAberto, setAdiantamentosEmAberto] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [lojas, setLojas] = useState([])
    const [lojaId, setLojaId] = useState("")
    const [lojaSelecionada, setLojaSelecionada] = useState("")



    useEffect(() => {
        const fetchData = async () => {
            try {
                const lojasResponse = makeApiCall('lojas');
                const boletosResponse = makeApiCall('boletos');
                const adiantamentosResponse = makeApiCall('adiantamentos');

                const [lojasData, boletosData, adiantamentosData] = await Promise.all([
                    lojasResponse,
                    boletosResponse,
                    adiantamentosResponse,
                ]);

                setLojas(lojasData.data);

                if (lojaId) {
                    const boletosPorLoja = boletosData.data
                        .filter((boleto) => boleto.loja.id === lojaId)
                        .filter((boleto) => boleto.status === 'VENCIDO');
                    setBoletosVencidos(boletosPorLoja);

                    const adiantamentosPorLoja = adiantamentosData.data
                        .filter((adiantamento) => adiantamento.loja.id === lojaId)
                        .filter((adiantamento) => adiantamento.statusAdiantamento === 'PENDENTE');
                    setAdiantamentosEmAberto(adiantamentosPorLoja);
                } else {
                    const boletos = boletosData.data.filter((boleto) => boleto.status === 'VENCIDO');
                    setBoletosVencidos(boletos);

                    const adiantamentos = adiantamentosData.data.filter(
                        (adiantamento) => adiantamento.statusAdiantamento === 'PENDENTE'
                    );
                    setAdiantamentosEmAberto(adiantamentos);
                }
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [lojaId, navigate]);


    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (


        <Container>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Resumo Financeiro</h2>

                <Form.Group className="ml-auto">
                    <Form.Label className="mr-2"><strong>Filtrar por Loja</strong></Form.Label>
                    <Form.Control
                        as="select"
                        value={lojaSelecionada}
                        onChange={(event) => {
                            const lojaIndex = event.target.selectedIndex - 1;
                            if (lojaIndex >= 0) {
                                setLojaSelecionada(event.target.value);
                                setLojaId(lojas[lojaIndex].id);

                            } else {
                                setLojaSelecionada(event.target.value);
                                setLojaId('')
                            }
                        }}

                    >
                        <option defaultValue value="">
                            TODAS
                        </option>
                        {lojas.map((loja) => (
                            <option key={loja.id} value={loja.razao}>
                                {loja.razao}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>


            <h3 className="custom-Boletos">Boletos Vencidos</h3>
            {boletosVencidos.length === 0 ? (
                <p>Não há boletos vencidos.</p>
            ) : (
                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>

                    <Table striped bordered>


                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Vencimento</th>
                                <th>Valor</th>
                                <th>Loja</th>
                            </tr>
                        </thead>

                        <tbody>

                            {boletosVencidos.map((boleto) => (

                                <tr key={boleto.id}>
                                    <td>
                                        <Link to={`/app/clientes/financeiro/${boleto.cliente.id}`} className="link">
                                            {boleto.cliente.nomeRazao} <br />
                                            <span style={{ fontSize: '0.8em', color: '#666' }}>
                                                {boleto.cliente.apelidoFantasia}</span>
                                        </Link>
                                    </td>
                                    <td>{moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</td>
                                    <td>{boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{boleto.loja.razao}</td>
                                </tr>

                            ))}

                        </tbody>

                    </Table>
                </div>

            )}

            <h3 className="custom-Adiantamentos">Adiantamentos</h3>                {adiantamentosEmAberto.length === 0 ? (
                <p>Não há adiantamentos em aberto.</p>
            ) : (

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>

                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Funcionario</th>
                                <th>Data</th>
                                <th>Valor</th>
                                <th>Loja</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adiantamentosEmAberto.map((adiantamento) => (
                                <tr key={adiantamento.id}>

                                    <td>
                                        <Link to={`/app/funcionarios/financeiro/${adiantamento.funcionario.id}`} className="link">
                                            {adiantamento.funcionario.nomeCompleto}
                                        </Link>

                                    </td>
                                    <td>{moment.utc(adiantamento.data).format('DD/MM/YYYY')}</td>
                                    <td>{adiantamento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{adiantamento.loja.razao}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

        </Container>

    );
};

export default ResumoFinanceiro;
