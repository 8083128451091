import React, { useState, useEffect } from 'react';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../../Utils/Autenticacao_API";
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Main from '../../template/Main';
import NotFoundPage from '../../Error/NotFoundPage';
import axios from 'axios';
import { useAlert } from '../../Uso_Geral/AlertProvider'
import ModalConfirmacao from '../../Uso_Geral/Modal_Confirmacao';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UsuariosMatriz from './Usuarios/Usuarios_Matriz'
import './Dados_Matriz.css'
import FinanceiroMatriz from './Financeiro_Matriz';

const headerProps = {
    icon: "users",
    title: " Matriz",
    subtitle: "Resumo Financeiro",
};

const DadosMatriz = () => {
    const { id } = useParams();
    const [matriz, setMatriz] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [render, setRender] = useState(false)
    const showAlert = useAlert();
    const [activeTab, setActiveTab] = useState('financeiro');




    useEffect(() => {
        makeApiCall(`matriz/${id}`)
            .then((response) => {
                setMatriz(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh()
                }
            });
    }, [id, navigate]);






    const handleExcluir = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/matriz/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Matriz excluida com sucesso ', 'success', 'top-right');
            navigate(-1)
            return response.data;

        } catch (error) {
            if (error.response) {
                // Se o servidor retorna um código de status de erro (ex: 400, 404, 500, etc.)
                showAlert(error.response.data.message || error.response.data, 'error', 'top-right');
            } else {
                // Se um erro ocorre antes de a requisição ser enviada ou depois de a resposta ser recebida
                showAlert(error.message, 'error', 'top-right');
            }

        }
    };


    function formatDocumento(documento) {
        if (!documento) {
            return "";
        } else if (documento.length === 11) {
            return `${documento.substr(0, 3)}.${documento.substr(3, 3)}.${documento.substr(6, 3)}-${documento.substr(9, 2)}`;
        } else if (documento.length === 14) {
            return `${documento.substr(0, 2)}.${documento.substr(2, 3)}.${documento.substr(5, 3)}/${documento.substr(8, 4)}-${documento.substr(12, 2)}`;
        } else {
            return documento;
        }
    }

    function formatarTelefone(numero) {
        if (!numero) return '';

        if (numero.length === 11) { // Celular com 9 dígitos
            return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 10) { // Telefone fixo
            return numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
            return numero; // Retorna o número como está caso não corresponda aos formatos esperados
        }
    }


    const handleConfirm = (id) => {
        if (id) { handleExcluir(id) }

    };


    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };

    const handleAlterarClick = (id) => {
        navigate(`/app/matriz/alterar/${id}`);
    };

    const handleVoltar = () => {
        navigate(-1); // Retorna para a página anterior
    };



    function formatarCep(cep) {
        // Verifica se o CEP tem 8 dígitos e formata como #####-###
        return cep ? cep.replace(/^(\d{5})(\d{3})$/, "$1-$2") : '';
    }

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {matriz && matriz.razao ? (
                <Container className="mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Resumo da Matriz</h4>
                        <Button variant="outline-dark" onClick={handleVoltar}>
                            Voltar
                        </Button>
                    </div>

                    <Row className="mb-3">
                        <Col>
                            <Card className="mb-3 mt-3 card-moderno">

                                <Card.Header>

                                    <div className="d-flex justify-content-between align-items-center">


                                        <div className='dados'> <strong>DADOS DA MATRIZ</strong> </div>
                                        <div>
                                            <Button variant="outline-primary me-1" onClick={() => handleAlterarClick(matriz.id)}>
                                                <i className="fa fa-edit">Alterar</i>
                                            </Button>
                                            <ModalConfirmacao
                                                variant="outline-danger"
                                                name="Excluir"
                                                question={`Deseja realmente excluir a matriz ${matriz.razao}?`}
                                                onConfirm={() => handleConfirm(matriz.id)}
                                                onCancel={handleCancel}
                                            />
                                        </div>


                                    </div>
                                </Card.Header>


                                <Card.Body>

                                    <p className="info-item"><strong>Nome / Razão Social:</strong> {matriz.razao || ''}</p>
                                    <p className="info-item"><strong>Apelido / Fantasia:</strong> {matriz.apelidoFantasia || ''}</p>

                                    <div className="documentos">
                                        <p className="info-item"><strong>CPF/CNPJ:</strong> {matriz.documento ? formatDocumento(matriz.documento) : ''}</p>
                                        <p className="info-item"><strong>Inscrição Estadual:</strong> {matriz.inscricaoEstadual || ''}</p>
                                    </div>
                                    <p className="info-item"><strong>E-mail:</strong> {matriz.email || ''}</p>

                                    <div className="contato">
                                        <p ><strong>Celular:</strong> {matriz.celular ? formatarTelefone(matriz.celular) : ''}</p>
                                        <p><strong>Telefone:</strong> {matriz.telefone ? formatarTelefone(matriz.telefone) : ''}</p>
                                    </div>

                                    <div className='endereco-principal'>

                                        <div className="endereco-dados">

                                            <p className="endereco-info"><strong>Endereço:</strong> {matriz.enderecos?.logradouro || ''}</p>
                                            <p className="endereco-info"><strong>Número:</strong> {matriz.enderecos?.numero || ''}</p>
                                        </div>

                                        <div className="endereco-dados">

                                            <p className="endereco-info"><strong>Bairro:</strong> {matriz.enderecos?.bairro || ''}</p>
                                            <p className="endereco-info"><strong>Complemento:</strong> {matriz.enderecos?.complemento || ''}</p>

                                        </div>

                                        <div className="endereco-dados">
                                            <p className="endereco-info"><strong>Estado:</strong> {matriz.enderecos?.estado || ''}</p>
                                            <p className="endereco-info"><strong>Cidade:</strong> {matriz.enderecos?.cidade || ''}</p>
                                            <p className="endereco-info"><strong>Cep:</strong> {matriz.enderecos?.cep ? formatarCep(matriz.enderecos.cep) : ''}</p>

                                        </div>
                                    </div>

                                </Card.Body>

                            </Card>
                        </Col>

                    </Row>

                    <Tabs
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        transition={false} // Desabilita as transições entre os tabs
                    >


                        <Tab eventKey="financeiro" title="Financeiro" mountOnEnter >
                        <FinanceiroMatriz matriz={matriz} />
                        </Tab>
                        <Tab eventKey="usuarios" title="Usuários" mountOnEnter >
                            <UsuariosMatriz />
                        </Tab>




                    </Tabs>




                </Container>
            ) : (
                <NotFoundPage />
            )}


        </Main>


    );
};
export default DadosMatriz;


