import './Footer.css';
import React from 'react';

const Footer = (props) => (
  <footer className="footer personalizado">
    Desenvolvido com&nbsp; <i className="fa fa-heart text-danger"></i> &nbsp;por
    <strong>
    &nbsp;<span className="text-danger">Clo</span>sure
    </strong>
  </footer>
);

export default Footer;
