import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import './Financeiro_Matriz.css'
import { useNavigate, useParams } from 'react-router-dom';
import { TokenExpirado, Refresh, makeApiCall, UrlApi } from '../../../Utils/Autenticacao_API';
import CriarFaturaModal from './CriarFaturaModal';
import { Button } from 'react-bootstrap';


const FinanceiroMatriz = ({ matriz }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(false)

  const [modalShow, setModalShow] = useState(false);





  const [statusContaSelecionada, setStatusContaSelecionada] = useState(matriz.statusConta);
  const [statusCobrancaSelecionada, setStatusCobrancaSelecionada] = useState(matriz.statusCobranca);
  const [dataExpiraDemoSeleciodada, setDataExpiraDemoSelecionada] = useState(matriz.dataExpiraDemo);
  const [tipoStatusConta, setTipoStatusConta] = useState('');
  const [tipoStatusCobranca, setTipoStatusCobranca] = useState('');



  const fetchData = useCallback(async (endpoint, data) => {
    try {
      const response = await makeApiCall(endpoint, data);
      if (response && response.data) {
        return response.data;
      } else if (response === "login") {
        navigate('/login');

      }
    } catch (error) {
      console.error(error);
      const tokenExpirado = TokenExpirado();

      if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
        navigate('/login');

      } else if (tokenExpirado === 'acessToken') {
        try {
          const refres = await Refresh();
          if (refres === 'login') {
            navigate('/login');

          }
        } catch (error) {
          console.log(' ERRO NA CHAMADA DA API');

        }


      }
    }
    return null;
  }, [navigate]);



  useEffect(() => {



    const tipoStatusConta = fetchData('statusConta', {}).then((StatusData) => {
      if (StatusData) {
        const statusConta = StatusData

        setTipoStatusConta(statusConta);
      }

    });


    const tipoStatusCobranca = fetchData('statusCobranca', {}).then((StatusData) => {
      if (StatusData) {
        const statusCobranca = StatusData

        setTipoStatusCobranca(statusCobranca);
      }

    });


    Promise.all([tipoStatusConta, tipoStatusCobranca]).then(() => {
      setIsLoading(false);
    });

  }, [navigate, render, fetchData]);




  const handleConfirmar = async (event) => {
    event.preventDefault();
    setRender(!render)

    const api = axios.create({
      baseURL: UrlApi,
    });


    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await api.put(
        `/matriz/atualizarFinanceiro/${id}`,
        {
          statusConta: statusContaSelecionada,
          statusCobranca: statusCobrancaSelecionada,
          dataExpiraDemo: dataExpiraDemoSeleciodada
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );


      return response.data;
    } catch (error) {
      console.error("Erro ao excluir fatura:", error.message);
    }

  };




  const handleExcluir = async (id) => {

    const api = axios.create({
      baseURL: UrlApi, // coloque aqui a URL base da sua API
    });

    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await api.delete(`/matriz/excluirfatura/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

      window.location.reload();
      return response.data;


    } catch (error) {
      console.error(error);
      throw new Error('Erro ao excluir o recebimento');
    }


  }


  const abrirLinkPix = (fatura) => {
    window.open(fatura.linkPix, '_blank');
  };

  const abrirLinkBoleto = (fatura) => {
    window.open(fatura.linkBoleto, '_blank');
  };



  if (isLoading) {
    return <div className="position-fixed top-50 start-50 translate-middle">
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Carregando...</span>
      </div>
    </div>;
  }

  return (
    <div className="financeiroMatriz">


      <h4>Dados Financeiros do Cliente</h4>


      <div>
        <strong>Data de Expiração do Demo:</strong>
        <input
          type="date"
          value={dataExpiraDemoSeleciodada ? moment.utc(dataExpiraDemoSeleciodada).format('YYYY-MM-DD') : ''}
          onChange={(e) => setDataExpiraDemoSelecionada(e.target.value)}
        />
      </div>

      <div>
        <strong>Status da Conta:</strong>
        {tipoStatusConta && tipoStatusConta.length > 0 ? (
          <select value={statusContaSelecionada || ""} onChange={(e) => setStatusContaSelecionada(e.target.value)}>
            {tipoStatusConta.map((opcao) => (
              <option key={opcao} value={opcao}>{opcao}</option>
            ))}
          </select>
        ) : (
          <p>Não há dados disponíveis.</p>
        )}
      </div>





      <div>
        <strong>Status de Cobrança:</strong>
        {tipoStatusCobranca && tipoStatusCobranca.length > 0 ? (
          <select value={statusCobrancaSelecionada || ""} onChange={(e) => setStatusCobrancaSelecionada(e.target.value)}>
            {tipoStatusCobranca.map((opcao) => (
              <option key={opcao} value={opcao}>{opcao}</option>
            ))}
          </select>
        ) : (
          <p>Não há dados disponíveis.</p>
        )}
      </div>


      <Button variant="primary" onClick={handleConfirmar}>
        Confirmar Alterações
      </Button>


      <hr />

      <div>
        <h4>Faturas  </h4>

        <Button variant="warning" onClick={() => setModalShow(true)}>
          Criar Nova Fatura
        </Button>

        <CriarFaturaModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

      </div>

      {matriz.faturas && matriz.faturas.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Valor</th>
              <th>Data de Vencimento</th>
              <th>PERÍODO INICIAL</th>
              <th>PERÍODO FINAL</th>
              <th>Forma de Pagamento</th>
              <th>Status da Fatura</th>
              <th>Links</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {matriz.faturas.map((fatura) => (
              <tr key={fatura.id}>
                <td>{fatura.id}</td>
                <td>{fatura.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td>{fatura.dataVencimento ? moment.utc(fatura.dataVencimento).format('DD/MM/YYYY') : ''}</td>
                <td>{fatura.dataPeridoInicial ? moment.utc(fatura.dataPeridoInicial).format('DD/MM/YYYY') : ''}</td>
                <td>{fatura.dataPeridoFinal ? moment.utc(fatura.dataPeridoFinal).format('DD/MM/YYYY') : ''}</td>
                <td>{fatura.meioPagamento}</td>
                <td>
                  <span className={`badge ${fatura.statusFatura === 'ABERTO' ? 'bg-warning'
                    : fatura.statusFatura === 'PAGO' ? 'bg-success'
                      : 'bg-danger'}`}>
                    {fatura.statusFatura}
                  </span>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {fatura.statusFatura !== 'PAGO' && (
                    <div>
                      <Button variant="outline-secondary" onClick={() => abrirLinkPix(fatura)}>
                        Pix
                      </Button>

                      <Button variant="outline-secondary" onClick={() => abrirLinkBoleto(fatura)}>
                        Boleto
                      </Button>
                    </div>
                  )}
                </td>
                <td> <Button variant="outline-danger" onClick={() => handleExcluir(fatura.id)}>
                  <i className="fa fa-remove"></i>
                </Button> </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhuma fatura encontrada.</p>
      )}

    </div>
  );
};

export default FinanceiroMatriz;


