import React from 'react';
import Select from 'react-select';

const SelectLojas = ({ selectedLojas, onChange, lojasList }) => {
    const options = lojasList ? lojasList.map((loja) => ({
        value: loja.id,
        label: loja.razao,
    })) : [];

    const handleChange = (selectedOptions) => {
        const selectedLojasIds = selectedOptions.map((option) => option.value);
        onChange(selectedLojasIds);
    };


    return (
        <div>
            <Select
                isMulti
                options={options}
                onChange={handleChange}
                value={selectedLojas.map((id) => options.find((option) => option.value === id))}
            />
        </div>
    );
};

export default SelectLojas;
