import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarGraph = ({ data }) => {
    const renderBars = () => {
        if (!data || data.length === 0) return null;

        // Criar uma cópia dos dados para não alterar o array original
        const sortedData = [...data];

        // Ordenar as vendas pela data completa em cada loja
        sortedData.forEach(store => {
          store.data.sort((a, b) => new Date(a.data) - new Date(b.data));
        });

        const uniqueDates = Array.from(new Set(sortedData.flatMap(store => store.data.map(sale => sale.data))));

        // Ordenar as datas únicas de forma absoluta pela data, mês e ano
        uniqueDates.sort((a, b) => new Date(a) - new Date(b));

        const formattedData = uniqueDates.map(date => {
            const barDataEntry = { data: formatDate(date) };
            sortedData.forEach(store => {
              const { storeName, data: storeData } = store;
              const sale = storeData.find(sale => sale.data === date);
              const saleValue = sale ? sale.valorTotal : 0; // aqui passamos o valor bruto
              barDataEntry[storeName] = saleValue;
            });

            return barDataEntry;
          });

        return formattedData;
      };





  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste de fuso horário
    return date.toLocaleDateString('pt-BR', options);
  };

  const calculateTotalSales = () => {
    let totalSales = 0;
    data.forEach(store => {
      const storeData = store.data;
      storeData.forEach(sale => {
        totalSales += sale.valorTotal;
      });
    });
    return formatCurrency(totalSales);
  };

  const calculateStoreTotal = (storeId) => {
    let storeTotal = 0;
    const store = data.find(store => store.storeId === storeId);
    if (store) {
      const storeData = store.data;
      storeData.forEach(sale => {
        storeTotal += sale.valorTotal;
      });
    }
    return formatCurrency(storeTotal);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };







  return (
    <div style={{ marginTop: '20px' }}> {/* Aumentar o espaço acima do componente */}
      {data && data.length > 1 && (
        <div><strong>Total de Vendas:</strong> {calculateTotalSales()}</div>
      )}
      {data && data.length > 0 && (
        <div>
          {data.map(store => (
            <div key={store.storeId}>
              <strong>Total de Vendas ({store.storeName}):</strong> {calculateStoreTotal(store.storeId)}
            </div>
          ))}
        </div>
      )}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={renderBars()}
          barSize={15}
          barGap={5}
          margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data" />
          <YAxis />
          <Tooltip formatter={(value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)} />
          <Legend />
          {data && data.length > 0 &&
            data.map((store, index) => (
              <Bar
                key={index}
                dataKey={store.storeName}
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
              />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );


};

export default BarGraph;
