// React Basic and Bootstrap
import React, { useState} from "react";
import { Link } from "react-router-dom";
import { UrlApi } from '../../Utils/Autenticacao_API';
import axios from 'axios';
import { useAlert } from '../Uso_Geral/AlertProvider';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Spinner
} from "reactstrap";

//Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import recoveryimg from "../../assets/imgs/user/recovery.svg";


const PageRecoveryPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();



  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string("Digite seu e-mail").email("Digite um e-mail válido").required("E-mail é obrigatório"),
    }),
    onSubmit: (values) => {
      handleRecuperarSenha(values)
    }
  });


  const handleRecuperarSenha = async (values) => {

    setIsLoading(true);

    const api = axios.create({
      baseURL: UrlApi, // coloque aqui a URL base da sua API
    });


    try {
      const response = await api.post(`/auth/esqueceu-senha?email=${values.email}`,
        {});


      setIsLoading(false);
      showAlert('E-mail de recuperação de senha enviado!', 'success', 'top-right');
      return response.data;

    } catch (error) {
      //showAlert('Error ao excluir usuário! ', 'error', 'top-right');
      setIsLoading(false);
      console.log(error.response)
      if (error.response) {
        // O servidor respondeu com um status fora do intervalo 2xx
        showAlert(error.response.data.message || 'Erro ao recuperar senha', 'error', 'top-right', 10000);
      } else if (error.request) {
        // A requisição foi feita mas não houve resposta
        console.log("O servidor não respondeu à solicitação.");
      } else {
        // Algo aconteceu na configuração da solicitação que causou um erro
        console.log("Erro ao fazer a solicitação: " + error.message);
      }
    }


  };

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Recuperar Senha</h4>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">

                          Por favor, indique o seu endereço de e-mail. Você receberá um
                          link para criar uma nova senha por e-mail.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" for="email">
                            E-mail {" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            id="email"
                            placeholder="Digite aqui seu e-mail"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button color="primary">

                            {isLoading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Enviando...
                              </>
                            ) : (
                              <>Enviar</>
                            )}
                          </Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            Lembra da sua senha?
                          </small>{" "}
                          <Link
                            to="/login"
                            className="text-dark fw-bold"
                          >
                            Fazer Login
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};
export default PageRecoveryPassword;
