import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { TokenExpirado, Refresh, makeApiCall, UrlApi } from '../../../Utils/Autenticacao_API';
import axios from 'axios';


const CriarFaturaModal = ({ show, onHide }) => {

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  const [valor, setValor] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [dataPeridoInicial, setDataPeriodoInicial] = useState('');
  const [dataPeridoFinal, setDataPeriodoFinal] = useState('');
  const [meioPagamento, setMeioPagamento] = useState('');
  const [tipoMeioPagamento, setTipoMeioPagamento] = useState('');



  const fetchData = useCallback(async (endpoint, data) => {
    try {
      const response = await makeApiCall(endpoint, data);
      if (response && response.data) {
        return response.data;
      } else if (response === "login") {
        navigate('/login');

      }
    } catch (error) {
      console.error(error);
      const tokenExpirado = TokenExpirado();

      if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
        navigate('/login');

      } else if (tokenExpirado === 'acessToken') {
        try {
          const refres = await Refresh();
          if (refres === 'login') {
            navigate('/login');

          }
        } catch (error) {
          console.log(' ERRO NA CHAMADA DA API');

        }


      }
    }
    return null;
  }, [navigate]);




  useEffect(() => {


    const meioPagamento = fetchData('meiopagamento', {}).then((StatusData) => {
      if (StatusData) {
        const meiospagamento = StatusData

        setTipoMeioPagamento(meiospagamento);
      }



    })


    Promise.all([meioPagamento]).then(() => {
      setIsLoading(false);
    });




  }, [navigate,fetchData]);





  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const api = axios.create({
      baseURL: UrlApi,
    });


    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await api.post(
        `/matriz/criarfatura/${id}`,
        {
          valor,
          dataVencimento,
          dataPeridoInicial,
          dataPeridoFinal,
          meioPagamento
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      onHide();
      window.location.reload();
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error("Erro ao criar a fatura:", error.message);
    }

  
  };


  if (isLoading) {
    return <div className="position-fixed top-50 start-50 translate-middle">
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Carregando...</span>
      </div>
    </div>;
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Fatura</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="number"
              placeholder="Insira o valor"
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              required
            />
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Data de Vencimento</Form.Label>
            <Form.Control
              type="date"
              value={dataVencimento}
              onChange={(e) => setDataVencimento(e.target.value)}
              required
            />
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Período Inicial</Form.Label>
            <Form.Control
              type="date"
              value={dataPeridoInicial}
              onChange={(e) => setDataPeriodoInicial(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Período Final</Form.Label>
            <Form.Control
              type="date"
              value={dataPeridoFinal}
              onChange={(e) => setDataPeriodoFinal(e.target.value)}
              required
            />
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Forma de Pagamento</Form.Label>
            <Form.Control
              as="select"
              value={meioPagamento}
              onChange={(e) => setMeioPagamento(e.target.value)}
              required >
             
              <option value="" disabled>Selecione uma opção</option>

              {tipoMeioPagamento.map((opcao) => (
                <option key={opcao} value={opcao}>{opcao}</option>
              ))}
            </Form.Control>
          </Form.Group>



        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fechar
          </Button>
          <Button variant="success" type="submit">
            Criar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CriarFaturaModal;
