import React, { useState, useEffect } from 'react';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { useNavigate, Link } from 'react-router-dom';
import { Dropdown, Table, Form, FormGroup, FormLabel, FormControl, Card, Button, Pagination, Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import './Recebimentos.css'
import CommentModal from '../../componentes/Uso_Geral/CommentModal'
import CustomTooltip from '../Uso_Geral/CustomTooltip';
import axios from 'axios';
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import { useAlert } from '../Uso_Geral/AlertProvider'


const Recebimentos = () => {
    const showAlert = useAlert();

    const [recebimentosSelecionados, setRecebimentosSelecionados] = useState([]);
    const [situacao, setSituacao] = useState('PENDENTE');


    const [data, setData] = useState('')

    const [lojas, setLojas] = useState([]);
    const [lojaId, setLojaId] = useState('');
    const [lojaSelecionada, setLojaSelecionada] = useState('');

    const [recebimentoId, setRecebimentoId] = useState('');

    const [recebimentos, setRecebimentos] = useState([]);
    const [tiposDeRecebimentos, setTiposDeRecebimentos] = useState([]);
    const [tipoRecebimentoSelecionado, setTipoRecebimentoSelecionado] = useState('')

    const [isLoading, setIsLoading] = useState(true);
    const [carregando, setCarregando] = useState(true);
    const [render, setRender] = useState(true);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState('');
    const [activePage, setActivePage] = useState(1);

    const [showModalComment, setShowModalComment] = useState(false);
    const [comentario, setComentario] = useState("")

    const [showModalHistorico, setShowModalHistorico] = useState(false);
    const [historico, setHistorico] = useState("");
    const [buscarHistorio, setBuscarHistorio] = useState(false);



    const MAX_ITEMS = 10;
    //const [startItem, setStartItem] = useState(1);
    // Calcula o índice inicial e final com base na página ativa
    const startIndex = Math.max(activePage - Math.floor(MAX_ITEMS / 2), 1);
    const endIndex = Math.min(startIndex + MAX_ITEMS - 1, totalPages);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const lojasResponse = await makeApiCall('lojas');
                const tiposRecebimentosResponse = await makeApiCall('tipos-recebimentos');

                const [lojasData, tiposRecebimentosData] = await Promise.all([
                    lojasResponse,
                    tiposRecebimentosResponse
                ]);

                setLojas(lojasData.data);
                setTiposDeRecebimentos(tiposRecebimentosData.data);
            } catch (error) {
                console.error(error);
                navigate('/login');
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setCarregando(true)
            try {
                const recebimentosResponse = await makeApiCall('recebimentos/paginada', {
                    tipoRecebimento: tipoRecebimentoSelecionado,
                    statusRecebimento: situacao,
                    lojaId,
                    data,
                    page: Math.max(activePage - 1, 0),
                    size: itemsPerPage
                });

                const recebimentosData = recebimentosResponse.data;

                setRecebimentos(recebimentosData.content);
                setTotalPages(recebimentosData.totalPages);
                setActivePage(recebimentosData.pageable.pageNumber + 1);
            } catch (error) {
                console.error(error);
                navigate('/login');
            } finally {
                setIsLoading(false);
                setCarregando(false)
            }
        };

        fetchData();
    }, [activePage, render, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'Recebimentos',
                    entidadeId: recebimentoId
                });

                setHistorico(historicoResponse.data);
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [buscarHistorio, navigate]);




    const handleRecebimentoClick = (recebimentoId) => {
        setRecebimentosSelecionados((prevSelectedRecebimento) => {
            if (prevSelectedRecebimento.includes(recebimentoId)) {
                return prevSelectedRecebimento.filter((id) => id !== recebimentoId);
            } else {
                return [...prevSelectedRecebimento, recebimentoId];
            }
        });
    };

    const limparFiltros = () => {
        setSituacao('PENDENTE');
        setLojaId('');
        setLojaSelecionada('');
        setTipoRecebimentoSelecionado('')
        setData('')
        filtrar();
    };

    const limparSelecao = () => {
        setRecebimentosSelecionados([]);
    };

    const filtrar = () => {
        console.log(activePage)
        setRender(!render)
        setActivePage(0);


    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleConferir = async (novoStatus) => {
        setIsLoading(true)

        if (!recebimentosSelecionados || recebimentosSelecionados.length === 0) {

            setIsLoading(false);
            showAlert('Nenhum recebimento foi selecionado ', 'warning', 'top-right');

            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post(`recebimentos/status?listaRecebimentos=${recebimentosSelecionados}&novoStatus=${novoStatus}`,
                {

                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setRecebimentosSelecionados([]);
            setRender(!render);
            showAlert('Conferência efetuada com sucesso! ', 'success', 'top-right');
            return response.data;
        } catch (error) {
            showAlert('Erro ao efetuar a Conferência! ', 'error', 'top-right');

        } finally {
            setIsLoading(false);
        }

    }

    const handleToggleModalComment = (mensagem) => {
        setComentario(mensagem)
        setShowModalComment(true);
    };
    const handleModalClose = () => {
        setComentario('')
        setShowModalComment(false);
    };


    const handleOpenModalHistorico = () => {

        setBuscarHistorio(!buscarHistorio)
        setShowModalHistorico(true);
    };

    const handleCloseModalHistorico = () => {
        setShowModalHistorico(false);
    };




    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }


    return (
        <div>
            <h3>Recebimentos Eletrônicos</h3>
            <Form>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><strong>Filtros</strong></Accordion.Header>
                            <Accordion.Body>
                                <Card.Body>

                                    <div className="row">

                                        <div className="col">
                                            <FormGroup>
                                                <FormLabel htmlFor="filtroSituacao"><strong>Situação</strong></FormLabel>
                                                <FormControl
                                                    as="select"
                                                    id="filtroSituacao"
                                                    value={situacao}
                                                    onChange={(e) => setSituacao(e.target.value)}
                                                >
                                                    <option value="">Todas</option>
                                                    <option value="PENDENTE">Pendente</option>
                                                    <option value="DESCONHECIDO">Desconhecido</option>
                                                    <option value="CONFIRMADO">Confirmado</option>
                                                </FormControl>
                                            </FormGroup>

                                            <Form.Group controlId="tipoRecebimento">
                                                <Form.Label className='mt-3 mb-0'><strong>Tipo de Recebimento</strong></Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={tipoRecebimentoSelecionado}
                                                    onChange={(event) => {
                                                        if (tiposDeRecebimentos) {
                                                            setTipoRecebimentoSelecionado(event.target.value);
                                                        }
                                                    }}
                                                >
                                                    <option value="">Todos</option>
                                                    {tiposDeRecebimentos.map((tipoRecebimento) => (
                                                        <option key={tipoRecebimento} value={tipoRecebimento}>
                                                            {tipoRecebimento}
                                                        </option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <FormGroup>
                                                <FormLabel htmlFor="loja"><strong>Loja</strong></FormLabel>
                                                <Form.Control
                                                    as="select"
                                                    value={lojaSelecionada}
                                                    onChange={(event) => {
                                                        const lojaIndex = event.target.selectedIndex - 1;
                                                        setLojaSelecionada(event.target.value);

                                                        if (lojaIndex >= 0) {
                                                            // Quando uma loja específica é selecionada
                                                            setLojaId(lojas[lojaIndex].id);
                                                        } else {
                                                            // Quando "Todas" é selecionado
                                                            // Faça algo aqui quando "Todas" é selecionado
                                                            // Por exemplo, você pode definir lojaId como null ou como uma string vazia
                                                            setLojaId(null);
                                                        }
                                                    }}
                                                >
                                                    <option value="">Todas</option>
                                                    {lojas.map((loja) => (
                                                        <option key={loja.id} value={loja.razao}>{loja.razao}</option>
                                                    ))}
                                                </Form.Control>
                                            </FormGroup>

                                            <Form.Group controlId="formBasicDate" className="mb-0 mt-3">
                                                <Form.Label className="mb-0"><strong>Data do Fechamento</strong></Form.Label>
                                                <Form.Control type="date" value={data}
                                                    onChange={(e) => setData(e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Card.Body>

                                <Button variant="primary" className='mt-2' onClick={() => filtrar()}>
                                    Filtrar
                                </Button>
                                <Button variant="secondary" onClick={limparFiltros}>
                                    Limpar Filtros
                                </Button>
                            </Accordion.Body>

                        </Accordion.Item>
                    </Card>
                </Accordion>
            </Form>

            <div className="d-flex justify-content-end mt-3 ">
                <FormGroup>
                    <FormLabel htmlFor="itensPorPagina" className="fw-bold">
                        Mostrar
                    </FormLabel>
                    <FormControl
                        as="select"
                        id="itensPorPagina"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                    </FormControl>
                </FormGroup>


            </div>


            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-button">
                    Conferência
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleConferir('CONFIRMADO')} className="bg-confirmado">
                        CONFIRMADO
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleConferir('PENDENTE')} className="bg-pendente">
                        PENDENTE
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleConferir('DESCONHECIDO')} className="bg-desconhecido">
                        DESCONHECIDO
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Table className='mt-1' striped bordered hover responsive>
                <thead>
                    <tr>
                        <th><Link
                            onClick={() => {
                                limparSelecao()
                            }}
                        >
                            <CustomTooltip content="Apagar Seleção" position="right">
                                <i className="fa fa-eraser" aria-hidden="true"></i>
                            </CustomTooltip>
                        </Link></th>

                        <th>Tipo Recebimento</th>
                        <th>Valor</th>
                        <th>Data</th>
                        <th>Loja</th>
                        <th>Situação</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(recebimentos) &&
                        recebimentos.map((recebimento) => (
                            <tr key={recebimento.id}>
                                <td>
                                    <label className="checkbox-btn" htmlFor={`checkbox-${recebimento.id}`}>
                                        <input
                                            id={`checkbox-${recebimento.id}`}
                                            type="checkbox"
                                            checked={recebimentosSelecionados.includes(recebimento.id)}
                                            onChange={() => handleRecebimentoClick(recebimento.id)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </td>

                                <td>{recebimento.tipoRecebimento}
                                    {recebimento.observacoes && <Link> <CustomTooltip content="Observação" position="top"><i className="fa fa-commenting text-primary custom-hover" aria-hidden="true" onClick={() => handleToggleModalComment(recebimento.observacoes)}></i></CustomTooltip></Link>}

                                </td>
                                <td>{recebimento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td>{moment.utc(recebimento.data).format('DD/MM/YYYY')}</td>
                                <td>{recebimento.loja.razao}</td>
                                <td>
                                    <Badge
                                        bg={
                                            recebimento.statusRecebimento === 'PENDENTE'
                                                ? 'warning'
                                                : recebimento.statusRecebimento === 'CONFIRMADO'
                                                    ? 'success'
                                                    : recebimento.statusRecebimento === 'DESCONHECIDO'
                                                        ? 'danger'
                                                        : 'secondary'
                                        }
                                        
                                        onClick={() => {
                                            handleOpenModalHistorico();
                                            setRecebimentoId(recebimento.id);
                                        }}
                                        className="my-button"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {recebimento.statusRecebimento}
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                </tbody>

            </Table>
            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First disabled={carregando} onClick={() => handlePageChange(1)} />
                <Pagination.Prev disabled={carregando} onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />

                {/* Renderiza os itens da páginação dentro do intervalo calculado */}
                {Array.from({ length: endIndex - startIndex + 1 }, (_, index) => {
                    const pageNumber = startIndex + index;
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === activePage}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={carregando}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}

                <Pagination.Next disabled={carregando} onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last disabled={carregando} onClick={() => handlePageChange(totalPages)} />
            </Pagination>

            <CommentModal show={showModalComment} onHide={handleModalClose} comment={comentario} />


            <HistoricoAlteracoesModal
                show={showModalHistorico}
                handleClose={handleCloseModalHistorico}
                historico={historico}
            />

        </div >
    );
};

export default Recebimentos;
