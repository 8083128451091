import { Button, Form } from "react-bootstrap";
import Main from "../template/Main";
import cpf_cnpj, { CPF, CNPJ } from "cpf_cnpj";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SelectLojas from '../Uso_Geral/SelectLojas'
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";
import InputMask from 'react-input-mask';
import './Cadastro_Cliente.css';
import { useAlert } from '../Uso_Geral/AlertProvider'



const headerProps = {
    icon: "users",
    title: "Clientes",
    subtitle: "Cadastro de Clientes",
};

function CadastroCliente() {
    const [nomeRazao, setNomeRazao] = useState("");
    const [apelidoFantasia, setApelidoFantasia] = useState("");
    const [documento, setDocumento] = useState("");
    const [documentoFormatado, setDocumentoFormatado] = useState("");
    const [inscricaoEstadual, setInscricaoEstadual] = useState("");
    const [emailValido, setEmailValido] = useState(true);
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [celular, setCelular] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [bairro, setBairro] = useState("");
    const [cep, setCep] = useState("");
    const [cidade, setCidade] = useState("");
    const [estado, setEstado] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lojasList, setLojasList] = useState([]);
    const [lojasIds, setLojasIds] = useState([])
    const showAlert = useAlert();


    const navigate = useNavigate();



    useEffect(() => {
        makeApiCall('lojas')
            .then((response) => {

                if (response && response.data) {
                    const lojas = response.data
                        .filter((loja) => loja.enabled)
                        .map((loja) => ({

                            id: loja.id,
                            razao: loja.razao


                        }));
                    setLojasList(lojas);
                    setIsLoading(false);

                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {

                    Refresh()

                }


            });
    }, [navigate]);



    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        // Remover caracteres não numéricos do documento
        const documentoNumerico = documento.replace(/\D/g, '');
        if (lojasIds.length < 1) {
            setError("Por favor, preencha todos os campos e selecione pelo menos uma loja.");
            return;
        }

        if (CPF.isValid(documentoNumerico) || CNPJ.isValid(documentoNumerico)) {
            const api = axios.create({
                baseURL: UrlApi, // coloque aqui a URL base da sua API
            });

            const accessToken = localStorage.getItem('accessToken');



            try {
                const response = await api.post(
                    '/clientes',
                    {
                        nomeRazao,
                        apelidoFantasia,
                        documento: documentoNumerico,
                        inscricaoEstadual,
                        email,
                        celular,
                        telefone,
                        lojasIds,
                        enderecos: [
                            {
                                logradouro: endereco,
                                numero: numero,
                                complemento: complemento,
                                bairro: bairro,
                                cep: cep,
                                cidade: cidade,
                                estado: estado
                            }
                        ]

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );


                setIsLoading(false);
                setNomeRazao('');
                setDocumento('');
                setError(null);
                navigate('/app/clientes');
                return response.data;
            } catch (error) {
                setIsLoading(false);

                setError('Erro ao cadastrar cliente');
                if (error.response) {
                    // O servidor respondeu com um status fora do intervalo 2xx
                    showAlert(error.response.data.message, 'error', 'top-right',5000);
                } else if (error.request) {
                    // A requisição foi feita mas não houve resposta
                    console.log("O servidor não respondeu à solicitação.");
                } else {
                    // Algo aconteceu na configuração da solicitação que causou um erro
                    console.log("Erro ao fazer a solicitação: " + error.message);
                }
            }
        } else {
            setError('Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
        }
    };


    function validarEmail(email) {
        if (email === '') return true; // Considera string vazia como válida
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const handleEmailChange = (e) => {
        const novoEmail = e.target.value;
        setEmail(novoEmail);
        setEmailValido(validarEmail(novoEmail));
    }

    const formatarDocumento = (doc) => {
     

        // Aplica a formatação condicional
        if (doc.length <= 11) {
            // CPF: Formata até que todos os grupos estejam completos
            return doc
                .replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (match, g1, g2, g3, g4) => {
                    return `${g1}${g2 ? '.' + g2 : ''}${g3 ? '.' + g3 : ''}${g4 ? '-' + g4 : ''}`;
                });
        } else {
            // CNPJ: Formata até que todos os grupos estejam completos
            return doc
                .replace(/(\d{2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/, (match, g1, g2, g3, g4, g5) => {
                    return `${g1}${g2 ? '.' + g2 : ''}${g3 ? '.' + g3 : ''}${g4 ? '/' + g4 : ''}${g5 ? '-' + g5 : ''}`;
                });
        }
    };

    const handleChangeDocumento = (e) => {
        const apenasNumeros = e.target.value.replace(/\D/g, '').slice(0, 14);

        const valorFormatado = formatarDocumento(apenasNumeros);
        setDocumento(apenasNumeros);
        setDocumentoFormatado(valorFormatado)
    };

    const handleChangeIncricaoEstadual = (e) => {
        const valorApenasNumerosHifensEPontos = e.target.value.replace(/[^0-9-.]/g, '');
        setInscricaoEstadual(valorApenasNumerosHifensEPontos);
    };

    if (isLoading) {
        return <div class="position-fixed top-50 start-50 translate-middle">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }


    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>

                <div className="dados-principais">


                    <Form.Group className="mb-2">
                        <Form.Label>Nome / Razão Social <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o Nome"
                            value={nomeRazao}
                            onChange={(e) => setNomeRazao(e.target.value)}
                            required
                        />
                    </Form.Group>


                    <Form.Group className="mb-2">
                        <Form.Label>Apelido / Fantasia </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o Apelido ou Fantasia"
                            value={apelidoFantasia}
                            onChange={(e) => setApelidoFantasia(e.target.value)}
                            
                        />
                    </Form.Group>
                </div>

                <div className="documentos">


                    <Form.Group className="mb-2">
                        <Form.Label>CPF / CNPJ <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o documento"
                            value={documentoFormatado}
                            onChange={handleChangeDocumento}
                            required
                        />

                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Inscrição Estadual </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite a Inscrição Estadual"
                            value={inscricaoEstadual}
                            onChange={handleChangeIncricaoEstadual}
                        />
                    </Form.Group>

                </div>
                <div className="email">

                    <Form.Group className={`mb-2 ${!emailValido ? 'email-invalido' : ''}`}>
                        <Form.Label>E-mail </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Digite o E-mail"
                            value={email}
                            onChange={handleEmailChange}
                            isInvalid={!emailValido}

                        />
                        {!emailValido && <Form.Control.Feedback type="invalid">
                            Por favor, insira um e-mail válido.
                        </Form.Control.Feedback>}
                    </Form.Group>
                </div>

                <div className="contato">

                    <Form.Group className="mb-2">
                        <Form.Label>Celular </Form.Label>
                        <InputMask
                            mask="(99) 99999-9999"
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                            className="form-control"
                            placeholder="Digite o Celular"

                        />
                    </Form.Group>


                    <Form.Group className="mb-2">
                        <Form.Label>Telefone </Form.Label>
                        <InputMask
                            mask="(99) 9999-9999"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                            className="form-control"
                            placeholder="Digite o Telefone"

                        />
                    </Form.Group>

                </div>

                <div className="endereco">
                    <div className="row">
                        <Form.Group className="mb-2 col"> {/* Campo de Endereço */}
                            <Form.Label>Endereço</Form.Label>
                            <InputMask
                                value={endereco}
                                onChange={(e) => setEndereco(e.target.value)}
                                className="form-control"
                                placeholder="Digite o Endereço"
                            />
                        </Form.Group>

                        <Form.Group className="mb-2 col col-numero"> {/* Campo de Número com classe adicional */}
                            <Form.Label>Número</Form.Label>
                            <InputMask
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                                className="form-control"
                                placeholder="Digite o Número"
                            />
                        </Form.Group>
                    </div>
                </div>



                <div className="endereco">
                    <div className="row">
                        <Form.Group className="mb-2 col">
                            <Form.Label>Bairro </Form.Label>
                            <InputMask
                                value={bairro}
                                onChange={(e) => setBairro(e.target.value)}
                                className="form-control"
                                placeholder="Digite o Bairro"

                            />
                        </Form.Group>


                        <Form.Group className="mb-2 col ">
                            <Form.Label>Complemento </Form.Label>
                            <InputMask
                                value={complemento}
                                onChange={(e) => setComplemento(e.target.value)}
                                className="form-control"
                                placeholder="Digite o Complemento"

                            />
                        </Form.Group>
                    </div>

                </div>


                <div className="endereco">
                    <div className="row">

                        <Form.Group className="mb-2 col">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                                className="form-control"
                            >
                                <option value="">Selecione o estado</option>
                                <option value="AC">AC - Acre</option>
                                <option value="AL">AL - Alagoas</option>
                                <option value="AP">AP - Amapá</option>
                                <option value="AM">AM - Amazonas</option>
                                <option value="BA">BA - Bahia</option>
                                <option value="CE">CE - Ceará</option>
                                <option value="DF">DF - Distrito Federal</option>
                                <option value="ES">ES - Espírito Santo</option>
                                <option value="GO">GO - Goiás</option>
                                <option value="MA">MA - Maranhão</option>
                                <option value="MT">MT - Mato Grosso</option>
                                <option value="MS">MS - Mato Grosso do Sul</option>
                                <option value="MG">MG - Minas Gerais</option>
                                <option value="PA">PA - Pará</option>
                                <option value="PB">PB - Paraíba</option>
                                <option value="PR">PR - Paraná</option>
                                <option value="PE">PE - Pernambuco</option>
                                <option value="PI">PI - Piauí</option>
                                <option value="RJ">RJ - Rio de Janeiro</option>
                                <option value="RN">RN - Rio Grande do Norte</option>
                                <option value="RS">RS - Rio Grande do Sul</option>
                                <option value="RO">RO - Rondônia</option>
                                <option value="RR">RR - Roraima</option>
                                <option value="SC">SC - Santa Catarina</option>
                                <option value="SP">SP - São Paulo</option>
                                <option value="SE">SE - Sergipe</option>
                                <option value="TO">TO - Tocantins</option>
                            </Form.Select>
                        </Form.Group>




                        <Form.Group className="mb-2 col">
                            <Form.Label>Cidade </Form.Label>
                            <InputMask
                                value={cidade}
                                onChange={(e) => setCidade(e.target.value)}
                                className="form-control"
                                placeholder="Digite a Cidade"

                            />
                        </Form.Group>

                        <Form.Group className="mb-2 col">
                            <Form.Label>Cep </Form.Label>
                            <InputMask
                                mask="99999-999"
                                value={cep}
                                onChange={(e) => setCep(e.target.value)}
                                className="form-control"
                                placeholder="Digite o Cep"

                            />
                        </Form.Group>
                    </div>
                </div>


                <Form.Group className="mb-3 dados-principais">
                    <Form.Label>Lojas <span className="text-danger">*</span></Form.Label>
                    <SelectLojas
                        selectedLojas={lojasIds}
                        onChange={(lojasIds) => setLojasIds(lojasIds)}
                        lojasList={lojasList}
                        required
                    />
                </Form.Group>
                <div>
                    {error && <Form.Text className="text-danger">{error}</Form.Text>}
                </div>

                <Button className='m-2' variant="primary" type="submit">
                    Cadastrar
                </Button>
                <Link to="/app/clientes">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>

                <div className="text-muted">Os campos marcados com <span className="text-danger">*</span> são obrigatórios.</div>

            </Form>
        </Main>
    );
}

export default CadastroCliente;
