import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CommentModal = ({ show, onHide, comment }) => {
  const handleModalClose = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Observação</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Mensagem</Form.Label>
          <Form.Control
            as="textarea"
            value={comment}
            readOnly
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;
