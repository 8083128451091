import { Button, Form } from "react-bootstrap";
import Main from "../../template/Main";
import { CPF, CNPJ } from "cpf_cnpj";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UrlApi, } from "../../../Utils/Autenticacao_API";


const headerProps = {
    icon: "users",
    title: "Matrizes",
    subtitle: "Cadastro de Matriz",
};

function CadastroMatriz() {
    const [razao, setRazao] = useState("");
    const [documento, setDocumento] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const navigate = useNavigate();




    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        // Remover caracteres não numéricos do documento
        const documentoNumerico = documento.replace(/\D/g, '');


        if (CPF.isValid(documentoNumerico) || CNPJ.isValid(documentoNumerico)) {
            const api = axios.create({
                baseURL: UrlApi, // coloque aqui a URL base da sua API
            });

            const accessToken = localStorage.getItem('accessToken');



            try {
                const response = await api.post(
                    '/matriz',
                    {
                        razao,
                        documento: documentoNumerico,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );


                setIsLoading(false);
                setRazao('');
                setDocumento('');
                setError(null);
                navigate('/app/matriz');
                return response.data;
            } catch (error) {
                setIsLoading(false);

                setError('Documento já cadastrado');
                console.error('Erro ao cadastrar matriz:', error.message);
            }
        } else {
            setError('Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
        }
    };



    if (isLoading) {
        return <div class="position-fixed top-50 start-50 translate-middle">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }


    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o nome"
                        value={razao}
                        onChange={(e) => setRazao(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Documento</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o documento"
                        value={documento}
                        onChange={(e) => setDocumento(e.target.value)}
                        required
                    />
                </Form.Group>

                <div>
                    {error && <Form.Text className="text-danger">{error}</Form.Text>}
                </div>

                <Button className='mt-2' variant="primary" type="submit">
                    Cadastrar
                </Button>
                <Link to="/app/matriz">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>
            </Form>
        </Main>
    );
}

export default CadastroMatriz;
