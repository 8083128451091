import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import Main from "../template/Main";
import {  UrlApi } from '../../Utils/Autenticacao_API';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';

const headerProps = {
    icon: 'university',
    title: 'Lojas',
    subtitle: 'Cadastro de Lojas: Incluir, Listar, Alterar e Excluir',
};

function CadastroLojas() {
    const [razao, setRazao] = useState('');
    const [endereco, setEndereco] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post(
                '/lojas',
                {
                    razao,
                    endereco,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            setIsLoading(false)
            navigate('/app/lojas')

            return response.data;
        } catch (error) {
            console.error(error);
            setIsLoading(false)
            navigate('/app/lojas')
            throw new Error('Erro ao cadastrar loja');
        }
    };

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Razão Social</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a razão social"
                        value={razao}
                        onChange={(e) => setRazao(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o endereço"
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button className='mt-2' variant="primary" type="submit">
                    Cadastrar
                </Button>
                <Link to="/app/lojas">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>
            </Form>
        </Main>
    );
}

export default CadastroLojas;
