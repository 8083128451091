import React from "react";
import { Container } from "reactstrap";

//Import Containers
import SectionTitle from "../../componentes/Shared/SectionTitle";
import ReviewsSlider from "../../componentes/Shared/ReviewsSlider";
import { reviews } from "./common/data";

const Testimonials = () => {
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Depoimentos dos usuários"
          desc=", gerencie seu fluxo de caixa como nunca e veja como é fácil fazer o fechamento de caixa e controlar suas vendas diárias."
        />

        {/* clients slider */}
        <ReviewsSlider reviews={reviews} colClass="mt-4" />
      </Container>
    </React.Fragment>
  );
};

export default Testimonials;
