import './DebtStatus.css';
import React, { useState, useEffect } from 'react';

const DebtStatus = () => {
  const [isInDebt, setIsInDebt] = useState(false);

  useEffect(() => {
   
      const debtStatus = localStorage.getItem('inadimplente') === 'true';
      setIsInDebt(debtStatus);
  }, []);

  if (!isInDebt) {
    return null; // Não retorna nenhum elemento se não estiver inadimplente
  }

  return (
    <div className="debt-status">
      <p>Você está inadimplente. Por favor, regularize sua situação.</p>
    </div>
  );
};

export default DebtStatus;
