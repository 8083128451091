import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';



const ModalConfirmacao = ({ onConfirm, onCancel, question ,name,className,variant,disabled}) => {
  const [show, setShow] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setShow(false);
  };

  const handleCancel = () => {
    onCancel();
    setShow(false);
  };

  return (
    <>
      <Button disabled={disabled}  className={className} variant={variant} onClick={() => setShow(true)}>
        {name}
      </Button>

      <Modal  show={show} onHide={() => setShow(false)} className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {question}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button variant={variant} className='border-secondary' onClick={handleConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirmacao;
