import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UrlApi } from "../../Utils/Autenticacao_API";
import { CPF, CNPJ } from "cpf_cnpj";
import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from '../Uso_Geral/AlertProvider';
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Alert
} from "reactstrap";
import MaskedInput from 'react-text-mask';
import FeatherIcon from "feather-icons-react";
import signup from "../../assets/imgs/user/signup.svg";

const FIELDS = [
  { name: 'nomeRazaoSocial', label: 'Nome / Razão Social', icon: 'user', type: 'text', required: true },
  { name: 'apelidoFantasia', label: 'Apelido / Fantasia', icon: 'user-check', type: 'text', required: true },
  { name: 'cpfCnpj', label: 'CPF / CNPJ', icon: 'file-text', type: 'text', required: true },
  { name: 'responsavel', label: 'Responsável', icon: 'user-plus', type: 'text', required: true },
  { name: 'telefone', label: 'Telefone', icon: 'phone', type: 'text', required: true },
  { name: 'cep', label: 'CEP', icon: 'mail', type: 'text', required: true },
  { name: 'endereco', label: 'Endereço', icon: 'map-pin', type: 'text', required: true },
  { name: 'bairro', label: 'Bairro', icon: 'home', type: 'text', required: true },
  { name: 'numero', label: 'Número', icon: 'hash', type: 'text', required: true },
  { name: 'complemento', label: 'Complemento', icon: 'plus-circle', type: 'text', required: false },
  { name: 'estado', label: 'Estado', icon: 'map', type: 'text', required: true },
  { name: 'cidade', label: 'Cidade', icon: 'map-pin', type: 'text', required: true },
  { name: 'nomeCompleto', label: 'Nome Completo', icon: 'user', type: 'text', required: true },
  { name: 'email', label: 'Email', icon: 'mail', type: 'email', required: true },
  { name: 'senha', label: 'Senha', icon: 'lock', type: 'password', required: true },
  { name: 'confirmSenha', label: 'Confirme a Senha', icon: 'lock', type: 'password', required: true }
];

const PageSignUp = () => {
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1); // Controla a etapa do formulário
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();

  const [address, setAddress] = useState({
    logradouro: '',
    bairro: '',
    localidade: '',
    uf: '',
    error: ''
  });

  const validationSchema = Yup.object().shape({
    ...FIELDS.reduce((acc, field) => {
      if (field.name === 'cep') {
        acc[field.name] = Yup.string().matches(/^\d{5}-\d{3}$/, 'Digite um CEP válido').required('Digite o CEP');
      } else if (field.name === 'email') {
        acc[field.name] = Yup.string().email('Digite um email válido').required('Digite o email');
      } else if (field.name === 'senha') {
        acc[field.name] = Yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Digite a senha');
      } else if (field.name === 'confirmSenha') {
        acc[field.name] = Yup.string().oneOf([Yup.ref('senha')], 'As senhas não coincidem').required('Confirme a senha');
      } else {
        acc[field.name] = field.required ? Yup.string().required(`Digite o ${field.label.toLowerCase()}`) : Yup.string();
      }
      return acc;
    }, {})
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: FIELDS.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}),
    validationSchema,
    onSubmit: async (values) => {
      if (step === 1) {
        handleNextStep();
      } else {
        await handleSubmit(values);
      }
    }
  });

  useEffect(() => {
    const fetchAddress = async () => {
      const formattedCep = validation.values.cep.replace('-', '');
      if (formattedCep.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${formattedCep}/json`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          if ("erro" in result) {
            setAddress({ ...address, error: "CEP não encontrado" });
          } else {
            setAddress({
              logradouro: result.logradouro,
              bairro: result.bairro,
              localidade: result.localidade,
              uf: result.uf,
              error: ''
            });
            validation.setFieldValue('endereco', result.logradouro);
            validation.setFieldValue('bairro', result.bairro);
            validation.setFieldValue('cidade', result.localidade);
            validation.setFieldValue('estado', result.uf);
          }
        } catch (error) {
          setAddress({ ...address, error: "Erro ao buscar o CEP" });
          console.error('Failed to fetch:', error);
        }
      } else {
        setAddress({ ...address, error: "CEP Inválido" });
      }
    };
    if (validation.values.cep) {
      fetchAddress();
    }
  }, [validation.values.cep]);

  const handleNextStep = () => {
    validation.validateForm().then(errors => {
      const stepOneFields = FIELDS.filter(field => !['nomeCompleto', 'email', 'senha', 'confirmSenha'].includes(field.name));
      const stepOneErrors = stepOneFields.reduce((acc, field) => {
        if (errors[field.name]) {
          acc[field.name] = errors[field.name];
        }
        return acc;
      }, {});
      if (Object.keys(stepOneErrors).length === 0) {
        setStep(2);
      } else {
        validation.setTouched({
          ...validation.touched,
          ...stepOneFields.reduce((acc, field) => ({ ...acc, [field.name]: true }), {}),
        });
      }
    });
  };

  const handleSubmit = async (values) => {
    if (!validation.isValid) {
      validation.setTouched({
        ...validation.touched,
        nomeCompleto: true,
        email: true,
        senha: true,
        confirmSenha: true
      });
      return;
    }

    const documentoNumerico = values.cpfCnpj.replace(/\D/g, '');
    setIsLoading(true);

    if (CPF.isValid(documentoNumerico) || CNPJ.isValid(documentoNumerico)) {
      const api = axios.create({
        baseURL: UrlApi,
      });

      try {
        const response = await api.post(
          '/matriz-public',
          {
            nomeCompleto: values.nomeCompleto,
            razao: values.nomeRazaoSocial,
            apelidoFantasia: values.apelidoFantasia,
            responsavel: values.responsavel,
            documento: documentoNumerico,
            email: values.email,
            telefone: values.telefone,
            enderecos: {
              logradouro: values.endereco,
              numero: values.numero,
              complemento: values.complemento,
              bairro: values.bairro,
              cep: values.cep,
              cidade: values.cidade,
              estado: values.estado,
            },
            password: values.senha
          },
          {}
        );
        showAlert('Cadastro efetuado com sucesso!', 'success', 'top-right');
        setIsLoading(false);
        validation.resetForm();
        setError(null);
        navigate('/login');
        return response.data;
      } catch (error) {
        setIsLoading(false);
        console.log(error.response)
        setError('Erro ao cadastrar cliente');
        if (error.response) {
          // O servidor respondeu com um status fora do intervalo 2xx
          showAlert(error.response.data.message || 'Erro ao cadastrar cliente', 'error', 'top-right', 10000);
        } else if (error.request) {
          // A requisição foi feita mas não houve resposta
          console.log("O servidor não respondeu à solicitação.");
        } else {
          // Algo aconteceu na configuração da solicitação que causou um erro
          console.log("Erro ao fazer a solicitação: " + error.message);
        }
      }
    } else {
      setError('Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
    }
  };

  if (isLoading) {
    return (
      <div className="position-fixed top-50 start-50 translate-middle">
        <div className="spinner-border text-warning" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-auth-home d-table w-100">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img src={signup} className="img-fluid d-block mx-auto" alt="" />
              </div>
            </Col>
            <Col lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Crie sua conta</h4>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    {step === 1 ? (
                      <Row>
                        {FIELDS.filter(field => field.name !== 'nomeCompleto' && field.name !== 'email' && field.name !== 'senha' && field.name !== 'confirmSenha').map((field, idx) => (
                          <Col md={field.name === 'cep' || field.type === 'text' ? 6 : 12} key={idx}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor={field.name}>
                                {field.label} {field.required && <span className="text-danger">*</span>}
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon icon={field.icon} className="fea icon-sm icons" />
                                </i>
                              </div>
                              {field.name === 'cep' ? (
                                <MaskedInput
                                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                  className={`form-control ps-5 ${validation.touched[field.name] && validation.errors[field.name] ? 'is-invalid' : ''}`}
                                  name={field.name}
                                  id={field.name}
                                  placeholder={field.label}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[field.name] || ""}
                                />
                              ) : (
                                <Input
                                  type={field.type}
                                  className={`form-control ps-5 ${validation.touched[field.name] && validation.errors[field.name] ? 'is-invalid' : ''}`}
                                  name={field.name}
                                  id={field.name}
                                  placeholder={field.label}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[field.name] || ""}
                                />
                              )}
                              {validation.touched[field.name] && validation.errors[field.name] && (
                                <FormFeedback>{validation.errors[field.name]}</FormFeedback>
                              )}
                            </div>
                          </Col>
                        ))}
                        {address.error && (
                          <Col md={12}>
                            <div className="alert alert-danger">{address.error}</div>
                          </Col>
                        )}
                        {error && (
                          <Col md={12}>
                            <Alert color="danger">{error}</Alert>
                          </Col>
                        )}
                        <Col md={12}>
                          <div className="d-grid">
                            <Button color="primary" type="button" onClick={handleNextStep}>
                              Próximo
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {FIELDS.filter(field => field.name === 'nomeCompleto' || field.name === 'email' || field.name === 'senha' || field.name === 'confirmSenha').map((field, idx) => (
                          <Col md={12} key={idx}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor={field.name}>
                                {field.label} {field.required && <span className="text-danger">*</span>}
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon icon={field.icon} className="fea icon-sm icons" />
                                </i>
                              </div>
                              <Input
                                type={field.type}
                                className={`form-control ps-5 ${validation.touched[field.name] && validation.errors[field.name] ? 'is-invalid' : ''}`}
                                name={field.name}
                                id={field.name}
                                placeholder={field.label}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values[field.name] || ""}
                              />
                              {validation.touched[field.name] && validation.errors[field.name] && (
                                <FormFeedback>{validation.errors[field.name]}</FormFeedback>
                              )}
                            </div>
                          </Col>
                        ))}
                        {error && (
                          <Col md={12}>
                            <Alert color="danger">{error}</Alert>
                          </Col>
                        )}
                        <Col md={12}>
                          <div className="d-grid">
                            <Button color="primary" type="submit" disabled={!validation.isValid || isLoading}>
                              Registrar
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PageSignUp;
