import { Button, Form } from "react-bootstrap";
import Main from "../template/Main";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import SelectLojas from "../Uso_Geral/SelectLojas";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";

const headerProps = {
    icon: "tablet",
    title: " Maquinetas",
    subtitle: "Editar Maquinetas",
};

function EditarMaquineta() {
    const [operadora, setOperadora] = useState("");
    const [identificacao, setIdentificacao] = useState("");
    const [numeroDeSerie, setNumeroDeSerie] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [lojasList, setLojasList] = useState([]);
    const [lojasIds, setLojasIds] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Load maquineta details
        makeApiCall(`maquinetas/${id}`)
            .then((response) => {
                // Set initial form values
                setOperadora(response.data.operadora);
                setIdentificacao(response.data.identificacao);
                setNumeroDeSerie(response.data.numeroDeSerie);
                setLojasIds(response.data.lojas.map(loja => loja.id));
                console.log(response)
            })
            .catch((error) => {
                console.error(error);
            });



            makeApiCall('lojas')
            .then((response) => {

                if (response && response.data) {
                    const lojas = response.data
                    .filter((loja) =>loja.enabled)
                    .map((loja) => ({

                        id: loja.id,
                        razao: loja.razao


                    }));
                    setLojasList(lojas);
                    setIsLoading(false);

                } console.log(response.data)
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {

                    Refresh()

                }


            });

    }, [id, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('')

        if (operadora === "" || identificacao === "" || numeroDeSerie === "" || lojasIds.length <1) {
            setError("Por favor, preencha todos os campos e selecione pelo menos uma loja.");
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem("accessToken");

        try {
            const response = await api.put(
                `/maquinetas/${id}`,
                {
                    operadora,
                    identificacao,
                    numeroDeSerie,
                    lojasIds,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            console.log(response.data);
            // Reset form
            // ...
            navigate("/app/maquinetas");
            return response.data;
        } catch (error) {
            setError("Erro ao atualizar maquininha");
            console.error("Erro ao atualizar maquininha:", error.message);
        }
    };

    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Operadora</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a operadora"
                        value={operadora}
                        onChange={(e) => setOperadora(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Identificação</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a identificação"
                        value={identificacao}
                        onChange={(e) => setIdentificacao(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Número de série</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o número de série"
                        value={numeroDeSerie}
                        onChange={(e) => setNumeroDeSerie(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Lojas</Form.Label>
                    <SelectLojas
                        selectedLojas={lojasIds}
                        onChange={(lojasIds) => setLojasIds(lojasIds)}
                        lojasList={lojasList}
                    />
                </Form.Group>
                <div>
                    {error && <Form.Text className="text-danger">{error}</Form.Text>}
                </div>
                <Button className="mt-2" variant="primary" type="submit">
                    Atualizar
                </Button>
                <Link to="/app/maquinetas">
                    <Button variant="outline-dark">Voltar</Button>
                </Link>
            </Form>
        </Main>
    );
}

export default EditarMaquineta;
