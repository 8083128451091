import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from "../../Utils/Autenticacao_API";
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import Main from '../template/Main';
import NotFoundPage from '../Error/NotFoundPage';
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import './Financeiro_Funcionario.css'
import axios from 'axios';
import { useAlert } from '../Uso_Geral/AlertProvider'
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';

const headerProps = {
    icon: "users",
    title: "Funcionários",
    subtitle: "Resumo Financeiro",
};

const FinanceiroFuncionario = () => {
    const { id } = useParams();
    const [funcionario, setFuncionario] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [historico, setHistorico] = useState("");
    const [buscarHistorio, setBuscarHistorio] = useState(false);

    const [render, setRender] = useState(false)
    const showAlert = useAlert();


    useEffect(() => {
        makeApiCall(`funcionarios/${id}`)
            .then((response) => {
                setFuncionario(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh()
                }
            });
    }, [id, navigate]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'Funcionarios',
                    entidadeId: id
                });

                setHistorico(historicoResponse.data);
            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [id, buscarHistorio, navigate]);



    const handleExcluir = async (id) => {
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/funcionarios/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Funcionário excluido com sucesso', 'success', 'top-right');
            navigate(-1)
            return response.data;
        } catch (error) {
            if (error.response) {
                // Se o servidor retorna um código de status de erro (ex: 400, 404, 500, etc.)
                showAlert(error.response.data.message || error.response.data, 'error', 'top-right');
            } else {
                // Se um erro ocorre antes de a requisição ser enviada ou depois de a resposta ser recebida
                showAlert(error.message, 'error', 'top-right');
            }

        }
    };




    const handleVoltar = () => {
        navigate(-1); // Retorna para a página anterior
    };

    const handleAlterarClick = (maquinetaId) => {
        navigate(`/app/funcionarios/alterar/${maquinetaId}`);
    };

    const handleOpenModal = () => {
        setIsLoading(true)
        setBuscarHistorio(!buscarHistorio)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    function formatDocumento(documento) {
        if (!documento) {
            return "";
        } else if (documento.length === 11) {
            return `${documento.substr(0, 3)}.${documento.substr(3, 3)}.${documento.substr(6, 3)}-${documento.substr(9, 2)}`;
        } else {
            return documento;
        }
    }

    function formatarTelefone(numero) {
        if (!numero) return '';

        if (numero.length === 11) { // Celular com 9 dígitos
            return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 10) { // Telefone fixo
            return numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
            return numero; // Retorna o número como está caso não corresponda aos formatos esperados
        }
    }


    const handleConfirm = (id) => {
        if (id) { handleExcluir(id) }



    };


    const handleCancel = () => {
        console.log('Ação cancelada');
    };





    if (isLoading) {
        return (
            <div className="position-fixed top-50 start-50 translate-middle">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {funcionario && funcionario.nomeCompleto ? (
                <Container className="mt-4">

                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Resumo do Funcionario</h4>
                        <Button variant="outline-dark" onClick={handleVoltar}>
                            Voltar
                        </Button>

                    </div>


                    <Row className="mb-3 mt-3">
                        <Col>
                            <Card className="mb-3">
                                <Card.Header>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <strong>DADOS DO FUNCIONÁRIO</strong>
                                        </div>
                                        <div>
                                            <Button variant="outline-primary me-1" onClick={() => handleAlterarClick(funcionario.id)}>
                                                <i className="fa fa-edit"></i> Alterar
                                            </Button>
                                            <ModalConfirmacao
                                                variant="outline-danger"
                                                name="Excluir"
                                                question={`Deseja realmente excluir o funcionário ${funcionario.nomeCompleto}?`}
                                                onConfirm={() => handleConfirm(funcionario.id)}
                                                onCancel={handleCancel}
                                            />
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p className="info-item"><strong>Nome Completo:</strong> {funcionario.nomeCompleto}</p>
                                    <p className="info-item "><strong>Apelido:</strong> {funcionario.apelido || ''}</p>

                                    <div className="informacoes">
                                        <p className="info-item"><strong>Cargo:</strong> {funcionario.cargo || ''}</p>
                                        <p className="info-item"><strong>Salário:</strong> {funcionario.salario > 0 ? funcionario.salario.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }) : ''}</p>
                                    </div>

                                    <div className='informacoes'>
                                        <p className="info-item"><strong>Data de Nascimento:</strong> {funcionario.dataNascimento != null ? (moment.utc(funcionario.dataNascimento).format('DD/MM/YYYY')) : ''}</p>
                                        <p className="info-item"><strong>Data de Admissão: </strong>{funcionario.dataAdmissao != null ? (moment.utc(funcionario.dataAdmissao).format('DD/MM/YYYY')) : ''}</p>
                                    </div>

                                    <div className="documentos">
                                        <p className="info-item"><strong>CPF:</strong> {funcionario.cpf ? formatDocumento(funcionario.cpf) : ''}</p>
                                        <p className="info-item"><strong>Nº de Identidade:</strong> {funcionario.numeroIdentidade || ''}</p>
                                    </div>
                                    <p className="info-item"><strong>E-mail:</strong> {funcionario.email || ''}</p>

                                    <div className="contato">
                                        <p ><strong>Celular:</strong> {funcionario.celular ? formatarTelefone(funcionario.celular) : ''}</p>
                                        <p><strong>Telefone:</strong> {funcionario.telefone ? formatarTelefone(funcionario.telefone) : ''}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Card className="border border-2 border-warning mb-3" >
                                <Card.Header ><strong>ADIANTAMENTOS EM ABERTO</strong></Card.Header>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Card.Body >
                                        <ListGroup>
                                            {funcionario.adiantamentos && funcionario.adiantamentos.filter((adiantamento) => adiantamento.statusAdiantamento === 'PENDENTE').length > 0 ? (
                                                funcionario.adiantamentos
                                                    .filter((adiantamento) => adiantamento.statusAdiantamento === 'PENDENTE')
                                                    .map((adiantamento) => (
                                                        <ListGroup.Item key={adiantamento.id}>
                                                            <div><strong>Valor:</strong> {adiantamento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div><strong>Data:</strong> {moment.utc(adiantamento.data).format('DD/MM/YYYY')}</div>
                                                            {adiantamento.observacoes && <div><strong>Observação:</strong> {adiantamento.observacoes}</div>}
                                                            <div><strong>Loja:</strong> {adiantamento.loja.razao}</div>
                                                        </ListGroup.Item>

                                                    ))
                                            ) : (
                                                <ListGroup.Item>Nenhum adiantamento em aberto.</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>

                        <Col xs={12}>
                            <Card className="border border-2 border-success mb-3">
                                <Card.Header><strong>ADIANTAMENTOS DESCONTADOS</strong></Card.Header>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Card.Body>
                                        <ListGroup>
                                            {funcionario.adiantamentos && funcionario.adiantamentos.filter((adiantamento) => adiantamento.statusAdiantamento === 'PAGO').length > 0 ? (
                                                funcionario.adiantamentos
                                                    .filter((adiantamento) => adiantamento.statusAdiantamento === 'PAGO')
                                                    .map((adiantamento) => (
                                                        <ListGroup.Item key={adiantamento.id}>
                                                            <div><strong>Valor:</strong> {adiantamento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                            <div><strong>Data:</strong> {moment.utc(adiantamento.data).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Data do Pagamento:</strong> {moment.utc(adiantamento.dataPagamento).format('DD/MM/YYYY')}</div>
                                                            <div><strong>Loja:</strong> {adiantamento.loja.razao}</div>
                                                            {adiantamento.observacoes && <div><strong>Observação:</strong> {adiantamento.observacoes}</div>}
                                                        </ListGroup.Item>


                                                    ))
                                            ) : (
                                                <ListGroup.Item>Nenhum Adiantamento Descontado.</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <NotFoundPage />
            )}


            <div className="d-flex justify-content-between align-items-center">
                <Button variant="link" onClick={() => handleOpenModal()}>Histórico de Alterações</Button>
            </div>

            <HistoricoAlteracoesModal
                show={showModal}
                handleClose={handleCloseModal}
                historico={historico}
            />


        </Main>

    );
};
export default FinanceiroFuncionario;


