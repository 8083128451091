import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Button, Table, Card } from 'react-bootstrap';
import SearchableSelect from '../template/SearchableSelect';
import CommentModal from '../../componentes/Uso_Geral/CommentModal'
import CustomTooltip from '../Uso_Geral/CustomTooltip';
import moment from 'moment';
import Main from '../template/Main';
import { TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { makeApiCall, UrlApi } from '../../Utils/Autenticacao_API';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ExportPDF from './ExportPDF';
import HistoricoAlteracoesModal from '../Uso_Geral/HistoricoAlteracoesModal';
import './FechamentoDeCaixa.css';
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';

const headerProps = {
    icon: 'university',
    title: 'Fechamento de Caixa',
    subtitle: 'Insira os valores separados por vírgula para cada categoria',
};

function FechamentoDeCaixa() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [render, setRender] = useState(false)
    const [date, setDate] = useState('');
    const [dataVencimeto, setDataVencimento] = useState('')

    const [comentario, setComentario] = useState("")
    const [showModalComment, setShowModalComment] = useState(false);


    const [sangrias, setSangrias] = useState([]);
    const [valorSangria, setValorSangria] = useState('')
    const [sangriaSelecionada, setSangriaSelecionada] = useState('');
    const [showFormSangria, setshowFormSangria] = useState(false);

    const [suprimentos, setSuprimentos] = useState([]);
    const [valorSuprimento, setValorSuprimento] = useState('')
    const [suprimentoSelecionado, setSuprimentoSelecionado] = useState('');
    const [showFormSuprimento, setshowFormSuprimento] = useState(false);

    const [despesas, setDespesas] = useState([]);
    const [valorDespesa, setValorDespesa] = useState('')
    const [despesaSelecionada, setDespesaSelecionada] = useState(null);
    const [showFormDespesa, setshowFormDespesa] = useState(false);


    const [clienteId, setClienteId] = useState("")
    const [clientes, setClientes] = useState([])
    const [clienteSelecionado, setClienteSelecionado] = useState("")

    const [lojas, setLojas] = useState([])
    const [lojaId, setLojaId] = useState("")
    const [lojaSelecionada, setLojaSelecionada] = useState("")


    const [funcionarioId, setFuncionarioId] = useState("")
    const [funcionarios, setFuncionarios] = useState([])
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState("")


    const [showFormBoleto, setshowFormBoleto] = useState(false);
    const [boletos, setBoletos] = useState([])
    const [valorBoleto, setValorBoleto] = useState('');
    const [numeroBoleto, setNumeroBoleto] = useState('');
    const [boletoSelecionado, setBoletoSelecionado] = useState("")

    const [showFormAdiantamento, setshowFormAdiantamento] = useState(false);
    const [adiantamentos, setAdiantamentos] = useState([])
    const [valorAdiantamento, setValorAdiantamento] = useState('');
    const [adiantamentoSelecionado, setAdiantamentoSelecionado] = useState("")

    const [showFormRecebimento, setshowFormRecebimento] = useState(false);
    const [recebimentos, setRecebimentos] = useState([])
    const [valorRecebimento, setValorRecebimento] = useState('');
    const [recebimentoSelecionado, setRecebimentoSelecionado] = useState("")
    const [tiposDeRecebimentos, setTiposDeRecebimentos] = useState([])
    const [tipoRecebimentoSelecionado, setTipoRecebimentoSelecionado] = useState('')



    const [maquinetas, setMaquinetas] = useState([]);
    const [maquinetaId, setMaquinetaId] = useState('');
    const [maquinetaSelecionada, setMaquinetaSelecionada] = useState("")
    const [identificacaoMaquinetas] = useState([])

    const [showFormRecebimentosCartoes, setshowFormRecebimentosCartoes] = useState(false);
    const [recebimentosCartoes, setRecebimentosCartoes] = useState([])
    const [valorRecebimentosCartoes, setValorRecebimentosCartoes] = useState('');
    const [recebimentosCartoesSelecionado, setRecebimentosCartoesSelecionado] = useState("")


    const [dinheiro, setDinheiro] = useState([]);
    const [valorDinheiro, setValorDinheiro] = useState('')
    const [dinheiroSelecionado, setDinheiroSelecionado] = useState(null);
    const [showFormDinheiro, setshowFormDinheiro] = useState(false);

    const [fechamento, setFechamento] = useState("");
    const [observacoes, setObservacoes] = useState('');


    const [showModal, setShowModal] = useState(false);
    const [historico, setHistorico] = useState("");
    const [buscarHistorio, setBuscarHistorio] = useState(false);


    const [cofre, setCofre] = useState('');

    const formRef = useRef(null);
    const tabelaDespesasRef = useRef();



    const [renderSuprimentos, setRenderSuprimentos] = useState(false)
    const [renderSangrias, setRenderSangrias] = useState(false)
    const [renderDespesas, setRenderDespesas] = useState(false)
    const [renderAdiantamentos, setRenderAdiantamentos] = useState(false)
    const [renderBoletos, setRenderBoletos] = useState(false)
    const [renderRecebimentos, setRenderRecebimentos] = useState(false)
    const [renderRecebimentosCartoes, setRenderRecebimentosCartoes] = useState(false)
    const [renderDinheiro, setRenderDinheiro] = useState(false)

    const fetchData = useCallback(async (endpoint, data) => {
        try {
            const response = await makeApiCall(endpoint, data);
            if (response && response.data) {
                return response.data;
            } else if (response === "login") {
                navigate('/login');

            }
        } catch (error) {
            console.error(error);
            const tokenExpirado = TokenExpirado();

            if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                navigate('/login');

            } else if (tokenExpirado === 'acessToken') {
                try {
                    const refres = await Refresh();
                    if (refres === 'login') {
                        navigate('/login');

                    }
                } catch (error) {
                    console.log(' ERRO NA CHAMADA DA API');

                }


            }
        }
        return null;
    }, [navigate]);




    useEffect(() => {

        const lojasPromise = fetchData('lojas', { data: date }).then((lojasData) => {
            if (lojasData) {
                const lojas = lojasData
                    .filter((loja) => loja.enabled)
                    .map((loja) => ({
                        id: loja.id,
                        razao: loja.razao,
                    }));
                setLojas(lojas);


            }
        });



        if (date && lojaSelecionada !== "") {

            const fechamentoPromise = fetchData('fechamento/por-data', { data: date, loja_id: lojaId }).then((fechamentoData) => {

                if (fechamentoData) {

                    setFechamento(fechamentoData);
                    setObservacoes(fechamentoData.observacoes)

                } else {
                    setFechamento('')
                    setObservacoes('')

                }
            });


            const cofrePromise = fetchData(`cofre/por-loja/${lojaId}`, { data: date }).then((cofreData) => {

                if (cofreData) {

                    setCofre(cofreData);



                }
            });







            const maquinetasPromise = fetchData('maquinetas/buscar-ativas', { data: date }).then((maquinetasData) => {
                if (maquinetasData) {
                    const maquinetas = maquinetasData
                        .map((maquineta) => ({
                            id: maquineta.id,
                            operadora: maquineta.operadora,
                            identificacao: maquineta.identificacao,
                            numeroDeSerie: maquineta.numeroDeSerie,
                            status: maquineta.ativo,
                            lojas: maquineta.lojas

                        }));
                    setMaquinetas(maquinetas);
                }
            });




            const funcionariosPromise = fetchData('funcionarios', { data: date }).then((funcionariosData) => {
                if (funcionariosData) {
                    const funcionarios = funcionariosData
                        .filter((funcionario) => funcionario.ativo)
                        .filter((funcionario) =>
                            funcionario.lojas.some((loja) => loja.id === lojaId)
                        )
                        .map((funcionario) => ({
                            id: funcionario.id,
                            nomeCompleto: funcionario.nomeCompleto,
                            status: funcionario.ativo,
                        }));
                    setFuncionarios(funcionarios);
                }
            });



            const clientesPromise = fetchData('clientes', { data: date }).then((clientesData) => {
                if (clientesData) {
                    const clientes = clientesData
                        .filter((cliente) => cliente.ativo)
                        .filter((cliente) =>
                            cliente.lojas.some((loja) => loja.id === lojaId)
                        )
                        .map((cliente) => ({
                            id: cliente.id,
                            nomeRazao: cliente.nomeRazao

                        }));
                    setClientes(clientes);
                }
            });





            const tiposDeRecebimentosPromise = fetchData('tipos-recebimentos', { data: date }).then((recebimentoData) => {
                if (recebimentoData) {
                    const recebimentos = recebimentoData

                    setTiposDeRecebimentos(recebimentos);
                }
            });


            Promise.all([funcionariosPromise, clientesPromise, tiposDeRecebimentosPromise, fechamentoPromise, lojasPromise,
                maquinetasPromise, cofrePromise
            ]).then(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            return
        }
    }, [date, fetchData, navigate, render, lojaSelecionada, lojaId]);



    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const suprimentosPromise = fetchData('suprimentos/por-data-loja', { data: date, lojaId }).then((suprimentosData) => {
                if (suprimentosData) {
                    const suprimentos = suprimentosData
                        .map((suprimento) => ({
                            id: suprimento.id,
                            descricao: suprimento.descricao,
                            valor: suprimento.valor,
                            data: suprimento.data,
                        }));
                    setSuprimentos(suprimentos);
                }
            });

            Promise.resolve(suprimentosPromise)
        } else {
            setIsLoading(false);
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderSuprimentos]);


    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const sangriasPromise = fetchData('sangrias/por-data-loja', { data: date, lojaId }).then((sangriasData) => {
                if (sangriasData) {
                    const sangrias = sangriasData
                        .map((sangria) => ({
                            id: sangria.id,
                            descricao: sangria.descricao,
                            valor: sangria.valor,
                            data: sangria.data,
                        }));
                    setSangrias(sangrias);
                }
            });

            Promise.resolve(sangriasPromise)
        } else {
            setIsLoading(false);
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderSangrias]);



    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const despesasPromise = fetchData('despesas/por-data-loja', { data: date, lojaId }).then((despesasData) => {
                if (despesasData) {
                    const despesas = despesasData
                        .map((despesa) => ({
                            id: despesa.id,
                            descricao: despesa.descricao,
                            valor: despesa.valor,
                            data: despesa.data,
                        }));
                    setDespesas(despesas);
                }
            });

            Promise.resolve(despesasPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderDespesas]);


    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const adiantamentosPromise = fetchData('adiantamentos/por-data-loja', { data: date, lojaId }).then((adiantamentosData) => {
                if (adiantamentosData) {
                    const adiantamentos = adiantamentosData
                        .map((adiantamento) => ({
                            id: adiantamento.id,
                            funcionario: adiantamento.funcionario.nomeCompleto,
                            valor: adiantamento.valor,
                            data: adiantamento.data,
                        }));
                    setAdiantamentos(adiantamentos);
                }
            });

            Promise.resolve(adiantamentosPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderAdiantamentos]);



    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const boletosPromise = fetchData('boletos/por-data-loja', { data: date, lojaId }).then((boletosData) => {
                if (boletosData) {
                    const boletos = boletosData
                        .map((boleto) => ({
                            id: boleto.id,
                            cliente: boleto.cliente.nomeRazao,
                            valor: boleto.valor,
                            vencimento: boleto.vencimento,
                            identificacao: boleto.identificacao
                        }));
                    setBoletos(boletos);
                }
            });


            Promise.resolve(boletosPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderBoletos]);


    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const recebimentosPromise = fetchData('recebimentos/por-data-loja', { data: date, lojaId }).then((recebimentosData) => {
                if (recebimentosData) {
                    const recebimentos = recebimentosData
                        .map((recebimento) => ({
                            id: recebimento.id,
                            valor: recebimento.valor,
                            tipoRecebimento: recebimento.tipoRecebimento,
                            observacoes: recebimento.observacoes
                        }));
                    setRecebimentos(recebimentos);
                }
            });


            Promise.resolve(recebimentosPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderRecebimentos]);


    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const recebimentosCartoesPromise = fetchData('recebimento_cartoes/por-data-loja', { data: date, lojaId }).then((recebimentosCartoesData) => {
                if (recebimentosCartoesData) {
                    const recebimentosCartoes = recebimentosCartoesData
                        .map((recebimento) => ({
                            id: recebimento.id,
                            valor: recebimento.valor,
                            maquineta: {
                                id: recebimento.maquineta.id,
                                identificacao: recebimento.maquineta.identificacao,
                                numeroDeSerie: recebimento.maquineta.numeroDeSerie,
                                operadora: recebimento.maquineta.operadora

                            }
                        }));

                    setRecebimentosCartoes(recebimentosCartoes);
                }
            });


            Promise.resolve(recebimentosCartoesPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderRecebimentosCartoes]);


    useEffect(() => {

        if (date && lojaSelecionada !== "") {

            const dinheiroPromise = fetchData('dinheiro/por-data-loja', { data: date, lojaId }).then((dinheiroData) => {
                if (dinheiroData) {
                    const dinheiro = dinheiroData
                        .map((din) => ({
                            id: din.id,
                            descricao: din.descricao,
                            valor: din.valor,
                            data: din.data,
                        }));
                    setDinheiro(dinheiro);
                }
            });


            Promise.resolve(dinheiroPromise)
        } else {
            return
        }

    }, [date, fetchData, navigate, lojaSelecionada, lojaId, renderDinheiro]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const historicoResponse = await makeApiCall('auditoria/por-entidade-id', {
                    entidade: 'FechamentoDeCaixa',
                    entidadeId: fechamento.id
                });

                setHistorico(historicoResponse.data);

            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (buscarHistorio) {
            fetchData();
            setBuscarHistorio(false);
        }
    }, [buscarHistorio, navigate]);


    const handleDateChange = (e) => {
        setDate(e.target.value);
        setIsLoading(true)

    };

    const handleDateChangeBoleto = (e) => {

        setDataVencimento(e.target.value);

    };

    const handleDateChangeBoletoAlterar = (boleto) => {
        setBoletoSelecionado(boleto)
        setDataVencimento(moment.utc(boleto.vencimento).format('YYYY-MM-DD'))


    };



    const handleRetificar = async () => {
        setIsLoading(true);

        if (!fechamento.id || !date) {
            return
        }
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`fechamento/retificar/${fechamento.id}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            setIsLoading(false);
            setRender(!render);

            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error(error.data);

        }

    };





    const handleFinalizar = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        const data = date;

        if (!data) {
            return;
        }

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post(
                `/fechamento/fechamento-de-caixa?data=${data}&loja_id=${lojaId}&observacoes=${observacoes}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setIsLoading(false);
            setRender(!render);

            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error(error.response.data);
            // throw new Error('Erro ao inserir dinheiro');
        }
    };



    const handleUpdateSuprimento = async (e) => {

        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`suprimentos/${suprimentoSelecionado.id}`, {
                descricao,
                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setSuprimentoSelecionado(false)
            setRenderSuprimentos(!renderSuprimentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar suprimento');
        }


    };


    const handleUpdateSangria = async (e) => {

        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`sangrias/${sangriaSelecionada.id}`, {
                descricao,
                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setSangriaSelecionada(false)
            setRenderSangrias(!renderSangrias)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar sangria');
        }


    };







    const handleUpdateDespesa = async (e) => {

        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`despesas/${despesaSelecionada.id}`, {
                descricao,
                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setDespesaSelecionada(false)
            setRenderDespesas(!renderDespesas)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar despesa');
        }


    };



    const handleAddSuprimento = async (e) => {
        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const novoSuprimento = { descricao, valor };
        setSuprimentos([...suprimentos, novoSuprimento]);
        setshowFormSuprimento(false);
        setSuprimentoSelecionado("")
        setValorSuprimento('')
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/suprimentos', {
                descricao,
                valor,
                data,
                loja_id: lojaId


            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setIsLoading(false)
            setRenderSuprimentos(!renderSuprimentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir suprimento');
        }


    };


    const handleAddSangria = async (e) => {
        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const novaSangria = { descricao, valor };
        setSangrias([...sangrias, novaSangria]);
        setshowFormSangria(false);
        setSangriaSelecionada("")
        setValorSangria('')
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/sangrias', {
                descricao,
                valor,
                data,
                loja_id: lojaId


            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setIsLoading(false)
            setRenderSangrias(!renderSangrias)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir sangria');
        }


    };






    const handleAddDespesa = async (e) => {
        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const novaDespesa = { descricao, valor };
        setDespesas([...despesas, novaDespesa]);
        setshowFormDespesa(false);
        setDespesaSelecionada("")
        setValorDespesa('')
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/despesas', {
                descricao,
                valor,
                data,
                loja_id: lojaId


            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });



            setIsLoading(false)
            setRenderDespesas(!renderDespesas)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir despesa');
        }


    };





    const handleAddDinheiro = async (e) => {
        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const novoDinheiro = { descricao, valor };
        setDinheiro([...dinheiro, novoDinheiro]);
        setshowFormDinheiro(false);
        setDinheiroSelecionado("")
        setValorDinheiro('')
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/dinheiro', {
                descricao,
                valor,
                data,
                loja_id: lojaId


            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setIsLoading(false)
            setRenderDinheiro(!renderDinheiro)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir dinheiro');
        }


    };




    const handleUpdateDinheiro = async (e) => {

        e.preventDefault();
        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`dinheiro/${dinheiroSelecionado.id}`, {
                descricao,
                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });



            setIsLoading(false)
            setDinheiroSelecionado(false)
            setRenderDinheiro(!renderDinheiro)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar recebimento em dinheiro');
        }







    };




    const handleRemoveSuprimento = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/suprimentos/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderSuprimentos(!renderSuprimentos)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir a suprimento');
        }


    }



    const handleRemoveSangria = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/sangrias/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderSangrias(!renderSangrias)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir a sangria');
        }


    }






    const handleRemoveDespesa = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/despesas/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderDespesas(!renderDespesas)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir a despesa');
        }


    }



    const handleRemoveDinheiro = async (id) => {

        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/dinheiro/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderDinheiro(!renderDinheiro)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir dinheiro');
        }


    }




    const handleAddAdiantamento = async (e) => {

        e.preventDefault();

        const descricao = e.target.elements.descricao.value;
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (!descricao || isNaN(valor) || !data) {
            return;
        }
        const novoAdiantamento = { descricao, valor };
        setAdiantamentos([...adiantamentos, novoAdiantamento]);
        setValorAdiantamento('')
        setAdiantamentoSelecionado("")
        setshowFormAdiantamento(false);
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/adiantamentos', {
                descricao,
                valor,
                data,
                funcionario_id: funcionarioId,
                loja_id: lojaId



            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setRenderAdiantamentos(!renderAdiantamentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir adiantamento');
        }


    };






    const handleAddRecebimento = async (e) => {

        e.preventDefault();

        const tipoRecebimento = tipoRecebimentoSelecionado
        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (isNaN(valor) || !data) {
            return;
        }
        const novoRecebimento = { valor };
        setRecebimentos([...recebimentos, novoRecebimento]);
        setValorRecebimento('')
        setRecebimentoSelecionado("")
        setshowFormAdiantamento(false);
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/recebimentos', {
                tipoRecebimento,
                valor,
                data,
                loja_id: lojaId



            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setRenderRecebimentos(!renderRecebimentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir recebimento');
        }


    };





    const handleAddRecebimentosCartoes = async (e) => {

        e.preventDefault();

        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date;
        if (isNaN(valor) || !data) {
            return;
        }
        const novoRecebimentoCartoes = { valor };
        setRecebimentosCartoes([...recebimentosCartoes, novoRecebimentoCartoes]);
        setValorRecebimentosCartoes('')
        setRecebimentosCartoesSelecionado("")
        setshowFormRecebimentosCartoes(false);
        e.target.reset();




        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/recebimento_cartoes', {

                maquineta_id: maquinetaId,
                loja_id: lojaId,
                valor,
                data



            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });



            setIsLoading(false)
            setRenderRecebimentosCartoes(!renderRecebimentosCartoes)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inserir recebimento de cartoes');
        }


    };











    const handleAddBoleto = async (e) => {

        e.preventDefault();

        const cliente_ID = clienteId
        const cliente = clienteSelecionado
        const valor = Number(valorBoleto.replace(',', '.'));
        const vencimento = dataVencimeto
        const identificacao = numeroBoleto;

        const data = date;
        if (isNaN(valor) || !data || !vencimento) {
            return;
        }
        const novoBoleto = { cliente, valor, vencimento };

        setBoletos([...boletos, novoBoleto]);
        setValorBoleto('')
        setBoletoSelecionado("")
        setshowFormBoleto(false);
        //e.target.reset();




        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.post('/boletos', {
                valor,
                data,
                vencimento,
                identificacao,
                cliente_ID,
                loja_id: lojaId


            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setRenderBoletos(!renderBoletos)

            return response.data;
        } catch (error) {
            console.error(error);
            setRender(!render)
            throw new Error('Erro ao inserir Boleto');
        }


    };





    const handleRemoveAdiantamento = async (id) => {

        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/adiantamentos/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderAdiantamentos(!renderAdiantamentos)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir o adiantamento');
        }


    }


    const handleRemoveRecebimento = async (id) => {

        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/recebimentos/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderRecebimentos(!renderRecebimentos)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir o recebimento');
        }


    }





    const handleRemoveRecebimentosCartoes = async (id) => {

        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/recebimento_cartoes/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderRecebimentosCartoes(!renderRecebimentosCartoes)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir o recebimento');
        }


    }




    const handleRemoveBoleto = async (id) => {

        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/boletos/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRenderBoletos(!renderBoletos)
            return response.data;


        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir o boleto');
        }


    }



    const handleUpdateAdiantamento = async (e) => {

        e.preventDefault();

        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date

        if (isNaN(valor) || !data || valor <= 0) {

            return;
        }
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`adiantamentos/${adiantamentoSelecionado.id}`, {

                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setValorAdiantamento('')
            setAdiantamentoSelecionado('')
            setRenderAdiantamentos(!renderAdiantamentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar despesa');
        }







    };



    const handleUpdateRecebimento = async (e) => {

        e.preventDefault();

        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date
        const observacoes = recebimentoSelecionado.observacoes
        if (isNaN(valor) || !data || valor <= 0) {
            return;
        }
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`recebimentos/${recebimentoSelecionado.id}`, {

                valor,
                observacoes
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setValorRecebimento('')
            setRecebimentoSelecionado('')
            setRenderRecebimentos(!renderRecebimentos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar recebimento');
        }







    };





    const handleUpdateRecebimentosCartoes = async (e) => {

        e.preventDefault();

        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const data = date
        if (isNaN(valor) || !data || valor <= 0) {

            return;
        }
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`recebimento_cartoes/${recebimentosCartoesSelecionado.id}`, {

                valor
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setValorRecebimentosCartoes('')
            setRecebimentosCartoesSelecionado('')
            setRenderRecebimentosCartoes(!renderRecebimentosCartoes)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar recebimento de Cartoes');
        }







    };






    const handleUpdateBoleto = async (e) => {

        e.preventDefault();


        const valor = Number(e.target.elements.valor.value.replace(',', '.'));
        const vencimento = dataVencimeto


        const data = date;
        if (isNaN(valor) || !data || valor <= 0 || !vencimento) {

            return;
        }

        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`boletos/${boletoSelecionado.id}`, {

                valor,
                vencimento: dataVencimeto,
                identificacao: boletoSelecionado.identificacao
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });




            setIsLoading(false)
            setValorBoleto('')
            setBoletoSelecionado('')
            setNumeroBoleto('')
            setRenderBoletos(!renderBoletos)

            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('Erro ao alterar boleto');
        }







    };



    const options = clientes.map((cliente) => ({
        id: cliente.id,
        value: cliente.nomeRazao,
        label: cliente.nomeRazao,

    }));



    const handleChangeValorAdiantamento = (event) => {
        const novoValor = event.target.value;
        setAdiantamentoSelecionado({
            ...adiantamentoSelecionado,
            valor: novoValor
        });
    };



    const handleChangeValorRecebimento = (event) => {
        const novoValor = event.target.value;
        setRecebimentoSelecionado({
            ...recebimentoSelecionado,
            valor: novoValor
        });
    };


    const handleChangeValorRecebimentosCartoes = (event) => {
        const novoValor = event.target.value;
        setRecebimentosCartoesSelecionado({
            ...recebimentosCartoesSelecionado,
            valor: novoValor
        });
    };



    const handleChangeValorBoleto = (event) => {
        const novoValor = event.target.value;
        setBoletoSelecionado({
            ...boletoSelecionado,
            valor: novoValor
        });
    };

    const handleChangeNumeroBoletoIncluir = (event) => {
        const novoNumeroBoleto = event.target.value;
        setNumeroBoleto(novoNumeroBoleto)

    };

    const handleChangeNumeroBoletoAlterar = (event) => {
        const novoNumeroBoleto = event.target.value;
        setBoletoSelecionado({
            ...boletoSelecionado,
            identificacao: novoNumeroBoleto
        });
        console.log(boletoSelecionado)
    };

    const handleChangeValorAdiantamentoIncluir = (event) => {
        const novoValor = event.target.value;
        setValorAdiantamento(novoValor)
    }


    const handleChangeValorRecebimentoIncluir = (event) => {
        const novoValor = event.target.value;
        setValorRecebimento(novoValor)
    }

    const handleChangeValorRecebimentosCartoesIncluir = (event) => {
        const novoValor = event.target.value;
        setValorRecebimentosCartoes(novoValor)
    }



    const handleChangeValorSuprimento = (event) => {
        const novaDescricao = event.target.value;
        const novoValor = event.target.value;
        setSuprimentoSelecionado({
            ...suprimentoSelecionado,
            valor: novoValor,
            descricao: novaDescricao
        });
    };



    const handleChangeValorSangria = (event) => {
        const novaDescricao = event.target.value;
        const novoValor = event.target.value;
        setSangriaSelecionada({
            ...sangriaSelecionada,
            valor: novoValor,
            descricao: novaDescricao
        });
    };



    const handleChangeValorDespesa = (event) => {
        const novaDescricao = event.target.value;
        const novoValor = event.target.value;
        setDespesaSelecionada({
            ...despesaSelecionada,
            valor: novoValor,
            descricao: novaDescricao
        });
    };

    const handleChangeValorDinheiro = (event) => {
        const novaDescricao = event.target.value;
        const novoValor = event.target.value;
        setDinheiroSelecionado({
            ...dinheiroSelecionado,
            valor: novoValor,
            descricao: novaDescricao
        });
    };

    const handleChangeValorSuprimentoIncluir = (event) => {
        const novoValor = event.target.value;
        setValorSuprimento(novoValor)
    }

    const handleChangeValorSangriaIncluir = (event) => {
        const novoValor = event.target.value;
        setValorSangria(novoValor)
    }


    const handleChangeValorDespesaIncluir = (event) => {
        const novoValor = event.target.value;
        setValorDespesa(novoValor)
    }

    const handleChangeValorDinheiroIncluir = (event) => {
        const novoValor = event.target.value;
        setValorDinheiro(novoValor)
    }


    const handleChangeValorBoletoIncluir = (event) => {
        const novoValor = event.target.value;
        setValorBoleto(novoValor)
    }

    const calcularTotalSuprimentos = () => {
        let total = 0;
        suprimentos.forEach((suprimento) => {
            total += suprimento.valor;
        });
        return total;
    };

    const calcularTotalSangrias = () => {
        let total = 0;
        sangrias.forEach((sangria) => {
            total += sangria.valor;
        });
        return total;
    };


    const calcularTotalDespesas = () => {
        let total = 0;
        despesas.forEach((despesa) => {
            total += despesa.valor;
        });
        return total;
    };


    const calcularTotalAdiantamentos = () => {
        let total = 0;
        adiantamentos.forEach((adiantamento) => {
            total += adiantamento.valor;
        });
        return total;
    };


    const calcularTotalRecebimentos = () => {
        let total = 0;
        recebimentos.forEach((recebimento) => {
            total += recebimento.valor;
        });
        return total;
    };


    const calcularTotalBoletos = () => {
        let total = 0;
        boletos.forEach((boleto) => {
            total += boleto.valor;
        });
        return total;
    };


    const calcularTotalRecebimentosCartoes = () => {
        let total = 0;
        recebimentosCartoes.forEach((recebimento) => {
            total += recebimento.valor;
        });
        return total;
    };

    const calcularTotalDinheiro = () => {
        let total = 0;
        dinheiro.forEach((din) => {
            total += din.valor;
        });
        return total;
    };


    const calcularTotalVendas = () => {

        const boletos = calcularTotalBoletos();
        const recebimento = calcularTotalRecebimentos();
        const maquinetas = calcularTotalRecebimentosCartoes();
        const dinheiro = calcularTotalDinheiro();

        const total = (boletos + recebimento + maquinetas + dinheiro)

        return total;
    };

    const calcularTotalSaldo = () => {

        const despesas = calcularTotalDespesas()
        const adiantamento = calcularTotalAdiantamentos()
        const dinheiro = calcularTotalDinheiro()
        const sangrias = calcularTotalSangrias()
        const suprimentos = calcularTotalSuprimentos()
        const total = (dinheiro - despesas - adiantamento + suprimentos - sangrias + cofre.valor)

        return total;
    };


    const handleObservacoes = (event) => {
        setObservacoes(event.target.value);
    };

    const handleObservacoesRecebimentos = (event) => {

        const novaObservacao = event.target.value;
        setRecebimentoSelecionado({
            ...recebimentoSelecionado,
            observacoes: novaObservacao
        });
    };


    const handleConfirm = () => {

        formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
        );


    };




    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };


    const verificarStatus = () => {

        if ((fechamento.statusFechamento !== "PENDENTE" && fechamento.statusFechamento)
            || !date || !lojas
        ) {
            return true
        }

    };



    const handleToggleModalComment = (mensagem) => {
        setComentario(mensagem)
        setShowModalComment(true);
    };
    const handleModalClose = () => {
        setComentario('')
        setShowModalComment(false);
    };



    //Modal do Historico
    const handleOpenModal = () => {
        setIsLoading(true)
        setBuscarHistorio(!buscarHistorio)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };






    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (

        <Main {...headerProps}>
            <div ref={tabelaDespesasRef}>
                <Form ref={formRef} onSubmit={handleFinalizar}>
                    <div className="d-flex justify-content-between align-items-center flex-column">
                        <Form.Group className="mb-3 w-100">
                            <Form.Label>Loja</Form.Label>
                            <Form.Control
                                as="select"
                                value={lojaSelecionada}
                                onChange={(event) => {
                                    const lojaIndex = event.target.selectedIndex - 1;
                                    if (lojaIndex >= 0) {
                                        setLojaSelecionada(event.target.value);
                                        setLojaId(lojas[lojaIndex].id);

                                    }
                                }}
                                isValid={lojaSelecionada !== ""}
                                isInvalid={lojaSelecionada === ""}
                            >
                                <option disabled value="">
                                    Selecione uma opção
                                </option>
                                {lojas.map((loja) => (
                                    <option key={loja.id} value={loja.razao}>
                                        {loja.razao}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <Form.Group controlId="formBasicDate" className="mb-0">
                                <Form.Label className="mb-0">Data do Fechamento</Form.Label>
                                <Form.Control type="date" value={date}
                                    onChange={handleDateChange}
                                    isValid={date}
                                    isInvalid={!date} />
                            </Form.Group>
                            <div className="d-flex flex-column">
                                <h2 className="mx-3">Fechamento de Caixa {date && moment(date).format('DD/MM/YYYY')}</h2>
                                <h3 className="align-self-center">{lojaSelecionada}</h3>
                            </div>
                            <div>
                                <>
                                    {/* Condição para renderizar o botão "Finalizar" */}
                                    {fechamento.statusFechamento !== 'FINALIZADO' && fechamento.statusFechamento !== 'RETIFICADO' && (
                                        <ModalConfirmacao
                                            variant="success"
                                            name="Finalizar"
                                            question="Deseja finalizar o fechamento?"
                                            onConfirm={handleConfirm}
                                            onCancel={handleCancel}
                                            className="me-3 px-4 py-2 rounded-pill text-uppercase"
                                            disabled={!date || !lojaId}
                                        />
                                    )}

                                    {(fechamento.statusFechamento === 'FINALIZADO' || fechamento.statusFechamento === 'RETIFICADO') && (
                                        <>
                                            <ModalConfirmacao
                                                variant="warning"
                                                name="Retificar"
                                                question="Deseja retificar o fechamento?"
                                                onConfirm={() => handleRetificar()}
                                                onCancel={handleCancel}
                                                className="me-3 px-4 py-2 rounded-pill text-uppercase"
                                                disabled={!date || fechamento.statusFechamento === 'PENDENTE'}
                                            />

                                            <ExportPDF data={date} lojaId={lojaId} className="ms-3" />
                                        </>
                                    )}
                                </>
                            </div>



                        </div>
                    </div>
                </Form>



                {calcularTotalVendas() > 0 ? (

                    <div>
                        <Card className="bg-green text-white text-center">
                            <Card.Body>
                                <Card.Title>Total de Vendas</Card.Title>

                                <Card.Text className="display-6">
                                    {calcularTotalVendas().toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="bg-yellow text-black text-center">
                            <Card.Body>
                                <Card.Title>Total em Dinheiro </Card.Title>
                                {`(Cofre: ${cofre.valor.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",

                                })} + Suprimentos: ${calcularTotalSuprimentos().toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })} - Sangrias: ${calcularTotalSangrias().toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })} + Vendas em Dinheiro: ${calcularTotalDinheiro().toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })} - Despesas: ${calcularTotalDespesas().toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })} - Adiantamentos: ${calcularTotalAdiantamentos().toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}) `}
                                <Card.Text className="display-6">
                                    {fechamento.saldo && fechamento.saldo.valor && !fechamento.statusFechamento === "PENDENTE" ? (
                                        fechamento.saldo.valor.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })
                                    ) : (
                                        calcularTotalSaldo().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })
                                    )}
                                </Card.Text>
                            </Card.Body>
                        </Card>



                    </div>

                ) : null}




                <div className='suprimentos text-center border border-2 border-warning rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">SUPRIMENTOS</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark">
                                <th colSpan="2">Total de Suprimentos</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalSuprimentos().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {suprimentos.length > 0 ? (
                                suprimentos.map((suprimento) => (
                                    <tr key={suprimento.id}>
                                        <td>{suprimento.descricao}</td>
                                        <td>{suprimento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setSuprimentoSelecionado(suprimento); setshowFormSuprimento(false) }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveSuprimento(suprimento.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhuma suprimento cadastrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormSuprimento && (
                        <Form onSubmit={handleAddSuprimento}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição do suprimento" />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do suprimento (ex: 100,00)"
                                    value={valorSuprimento}
                                    onChange={handleChangeValorSuprimentoIncluir}
                                />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorSuprimento <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormSuprimento(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormSuprimento && !suprimentoSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="warning " onClick={() => { setSuprimentoSelecionado(''); setshowFormSuprimento(true) }}>
                            Adicionar Suprimento
                        </Button>
                    )}


                    {suprimentoSelecionado && (
                        <Form onSubmit={handleUpdateSuprimento}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição da despesa"
                                    defaultValue={suprimentoSelecionado.descricao}
                                />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do suprimento (ex: 100,00)"
                                    value={suprimentoSelecionado.valor}
                                    isInvalid={suprimentoSelecionado.valor <= 0}
                                    onChange={handleChangeValorSuprimento} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type='submit' disabled={sangriaSelecionada.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setSuprimentoSelecionado(false)} >
                                Cancelar
                            </Button>
                        </Form>
                    )}


                </div>











                <div className='sangrias text-center border border-2 border-dark rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">SANGRIAS</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark">
                                <th colSpan="2">Total de Sangrias</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalSangrias().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sangrias.length > 0 ? (
                                sangrias.map((sangria) => (
                                    <tr key={sangria.id}>
                                        <td>{sangria.descricao}</td>
                                        <td>{sangria.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setSangriaSelecionada(sangria); setshowFormSangria(false) }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveSangria(sangria.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhuma sangria cadastrada.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormSangria && (
                        <Form onSubmit={handleAddSangria}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição da sangria" />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da sangria (ex: 100,00)"
                                    value={valorSangria}
                                    onChange={handleChangeValorSangriaIncluir}
                                />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorSangria <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormSangria(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormSangria && !sangriaSelecionada) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="dark " onClick={() => { setSangriaSelecionada(''); setshowFormSangria(true) }}>
                            Adicionar Sangria
                        </Button>
                    )}


                    {sangriaSelecionada && (
                        <Form onSubmit={handleUpdateSangria}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição da despesa"
                                    defaultValue={sangriaSelecionada.descricao}
                                />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da sangria (ex: 100,00)"
                                    value={sangriaSelecionada.valor}
                                    isInvalid={sangriaSelecionada.valor <= 0}
                                    onChange={handleChangeValorSangria} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type='submit' disabled={sangriaSelecionada.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setSangriaSelecionada(false)} >
                                Cancelar
                            </Button>
                        </Form>
                    )}


                </div>






                <div className='despesas text-center border border-2 border-danger rounded p-3 mt-2 bg-light '>
                    <h4 className="mb-3 fw-bold">DESPESAS</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark">
                                <th colSpan="2">Total de Despesas</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalDespesas().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {despesas.length > 0 ? (
                                despesas.map((despesa) => (
                                    <tr key={despesa.id}>
                                        <td>{despesa.descricao}</td>
                                        <td>{despesa.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setDespesaSelecionada(despesa); setshowFormDespesa(false) }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveDespesa(despesa.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhuma despesa cadastrada.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormDespesa && (
                        <Form onSubmit={handleAddDespesa}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição da despesa" />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da despesa (ex: 100,00)"
                                    value={valorDespesa}
                                    onChange={handleChangeValorDespesaIncluir}
                                />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorDespesa <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormDespesa(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormDespesa && !despesaSelecionada) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary " onClick={() => setshowFormDespesa(true)}>
                            Adicionar Despesa
                        </Button>
                    )}


                    {despesaSelecionada && (
                        <Form onSubmit={handleUpdateDespesa}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição da despesa"
                                    defaultValue={despesaSelecionada.descricao}
                                />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da despesa (ex: 100,00)"
                                    value={despesaSelecionada.valor}
                                    isInvalid={despesaSelecionada.valor <= 0}
                                    onChange={handleChangeValorDespesa} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type='submit' disabled={despesaSelecionada.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setDespesaSelecionada(false)} >
                                Cancelar
                            </Button>
                        </Form>
                    )}


                </div>










                <div className='adiantamentos text-center border border-2 border-info rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">ADIANTAMENTOS / VALE</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark ">
                                <th colSpan="2">Total de Adiantamentos</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalAdiantamentos().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Funcionario</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adiantamentos.length > 0 ? (
                                adiantamentos.map((adiantamento) => (
                                    <tr key={adiantamento.id}>
                                        <td>{adiantamento.funcionario}</td>
                                        <td>{adiantamento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setAdiantamentoSelecionado(adiantamento); setshowFormAdiantamento(false); }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveAdiantamento(adiantamento.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhuma adiantamento cadastrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormAdiantamento && (
                        <Form onSubmit={handleAddAdiantamento}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Funcionario</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={funcionarioSelecionado}
                                    onChange={(event) => {
                                        const funcionarioIndex = event.target.selectedIndex - 1;
                                        if (funcionarioIndex >= 0) {
                                            setFuncionarioSelecionado(event.target.value);
                                            setFuncionarioId(funcionarios[funcionarioIndex].id);
                                        }
                                    }}
                                    isValid={funcionarioSelecionado !== ""}
                                    isInvalid={funcionarioSelecionado === ""}
                                >
                                    <option disabled value="">
                                        Selecione uma opção
                                    </option>
                                    {funcionarios.map((funcionario) => (
                                        <option key={funcionario.id} value={funcionario.nomeCompleto}>
                                            {funcionario.nomeCompleto}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>


                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do adiantamento (ex: 100,00)"
                                    value={valorAdiantamento}
                                    onChange={handleChangeValorAdiantamentoIncluir} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorAdiantamento <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormAdiantamento(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormAdiantamento && !adiantamentoSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary" onClick={() => { setshowFormAdiantamento(true); setAdiantamentoSelecionado('') }} >
                            Adicionar Adiantamento
                        </Button>
                    )}


                    {adiantamentoSelecionado && (

                        <Form onSubmit={handleUpdateAdiantamento}>
                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da despesa (ex: 100,00)"
                                    value={adiantamentoSelecionado.valor}
                                    onChange={handleChangeValorAdiantamento} isInvalid={adiantamentoSelecionado.valor <= 0} />
                            </Form.Group>
                            <Button variant="primary" className='mt-2' type='submit' disabled={adiantamentoSelecionado.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setAdiantamentoSelecionado(false)} >
                                Cancelar
                            </Button>

                        </Form>


                    )}


                </div>


















                <div className='boletos text-center border border-2 border-secondary rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">BOLETOS</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark ">
                                <th colSpan="4" >Total de Boletos</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalBoletos().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Cliente</th>
                                <th>Vencimento</th>
                                <th>N° Boleto</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boletos.length > 0 ? (
                                boletos.map((boleto) => (
                                    <tr key={boleto.id}>
                                        <td>{boleto.cliente}</td>
                                        <td>{moment.utc(boleto.vencimento).format('DD/MM/YYYY')}</td>
                                        <td>{boleto.identificacao}</td>
                                        <td>{boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { handleDateChangeBoletoAlterar(boleto); setshowFormBoleto(false) }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveBoleto(boleto.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nenhuma boleto cadastrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormBoleto && (
                        <Form onSubmit={handleAddBoleto}>
                            <Form.Group controlId="cliente">
                                <Form.Label>Cliente</Form.Label>
                                <SearchableSelect
                                    options={options}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setClienteSelecionado(selectedOption.value);
                                            setClienteId(selectedOption.id);
                                        }
                                    }}
                                    placeholder="Selecione uma opção"
                                    isValid={clienteSelecionado !== ""}
                                    isInvalid={clienteSelecionado === ""}
                                />
                            </Form.Group>

                            <Form.Group controlId="vencimento">
                                <Form.Label>Vencimento</Form.Label>
                                <Form.Control type="date"
                                    value={dataVencimeto}
                                    onChange={handleDateChangeBoleto}
                                    isInvalid={!dataVencimeto}
                                />
                            </Form.Group>

                            <Form.Group controlId="identificação">
                                <Form.Label>N° Boleto</Form.Label>
                                <Form.Control type="number" placeholder="Digite o numero do boleto (opcional)"
                                    onChange={handleChangeNumeroBoletoIncluir} />

                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do Boleto (ex: 100,00)"

                                    onChange={handleChangeValorBoletoIncluir} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorBoleto <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormBoleto(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormBoleto && !boletoSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary" onClick={() => setshowFormBoleto(true)} >
                            Adicionar Boleto
                        </Button>
                    )}


                    {boletoSelecionado && (

                        <Form onSubmit={handleUpdateBoleto}>

                            <Form.Group controlId="vencimento">
                                <Form.Label>Vencimento</Form.Label>
                                <Form.Control type="date"
                                    value={dataVencimeto}
                                    onChange={handleDateChangeBoleto} />
                            </Form.Group>

                            <Form.Group controlId="identificação">
                                <Form.Label>N° Boleto</Form.Label>
                                <Form.Control type="number" placeholder="Digite o numero do boleto (opcional)"
                                    defaultValue={boletoSelecionado.identificacao}
                                    onChange={handleChangeNumeroBoletoAlterar} />

                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do boleto (ex: 100,00)" defaultValue={boletoSelecionado.valor}

                                    onChange={handleChangeValorBoleto} isInvalid={boletoSelecionado.valor <= 0} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type='submit' disabled={boletoSelecionado.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setBoletoSelecionado(false)} >
                                Cancelar
                            </Button>

                        </Form>


                    )}


                </div>















                <div className='recebimentos text-center border border-2 border-success rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">RECEBIMENTOS</h4>
                    <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                        <Table className="table table-striped table-hover">
                            <thead>
                                <tr className="bg-lightblue text-dark ">
                                    <th colSpan="2">Total de Recebimentos</th>
                                    <th>
                                        <span className="fs-5 fw-bold">
                                            {calcularTotalRecebimentos().toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })}
                                        </span>
                                    </th>
                                </tr>

                                <tr>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>

                            <tbody>
                                {recebimentos.length > 0 ? (
                                    recebimentos.filter((recebimento) => recebimento.tipoRecebimento !== undefined)
                                        .sort((a, b) => a.tipoRecebimento.localeCompare(b.tipoRecebimento))
                                        .map((recebimento) => (
                                            <tr key={recebimento.id}>
                                                <td>{recebimento.tipoRecebimento}
                                                    {recebimento.observacoes && <Link> <CustomTooltip content="Observação" position="top"><i className="fa fa-commenting text-primary custom-hover" aria-hidden="true" onClick={() => handleToggleModalComment(recebimento.observacoes)}></i></CustomTooltip></Link>}
                                                </td>
                                                <td>{recebimento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>
                                                    <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setRecebimentoSelecionado(recebimento); setshowFormRecebimento(false) }}>
                                                        <i className="fa fa-edit"></i>
                                                    </Button>
                                                    <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveRecebimento(recebimento.id)}>
                                                        <i className="fa fa-remove"></i>
                                                    </Button>

                                                </td>
                                            </tr>

                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">Nenhuma recebimento cadastrado.</td>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                    </div>
                    {showFormRecebimento && (
                        <Form className={` ${verificarStatus() ? 'form-invisivel' : ''}`} onSubmit={handleAddRecebimento}>
                            <Form.Group controlId="tipoRecebimento">
                                <Form.Label>Tipo de Recebimento</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={tipoRecebimentoSelecionado}
                                    onChange={(event) => {
                                        if (tiposDeRecebimentos) {

                                            setTipoRecebimentoSelecionado(event.target.value);
                                        }
                                    }}
                                    isValid={tipoRecebimentoSelecionado !== ""}
                                    isInvalid={tipoRecebimentoSelecionado === ""}
                                >
                                    <option disabled value=''>Selecione um tipo de recebimento</option>
                                    {tiposDeRecebimentos.map((tipoRecebimento, index) => (
                                        <option key={index} value={tipoRecebimento}>
                                            {tipoRecebimento}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do recebimento (ex: 100,00)"
                                    value={valorRecebimento}
                                    onChange={handleChangeValorRecebimentoIncluir} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorRecebimento <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormRecebimento(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormRecebimento && !recebimentoSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary" onClick={() => { setshowFormRecebimento(true); setRecebimentoSelecionado('') }} >
                            Adicionar Recebimento
                        </Button>
                    )}


                    {recebimentoSelecionado && (

                        <Form onSubmit={handleUpdateRecebimento}>
                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor do recebimento (ex: 100,00)"
                                    value={recebimentoSelecionado.valor}
                                    onChange={handleChangeValorRecebimento} isInvalid={recebimentoSelecionado.valor <= 0} />
                            </Form.Group>
                            <Form.Group controlId="observacoes">
                                <Form.Label>Observações</Form.Label>
                                <Form.Control type="text" placeholder="Digite as observações (ex: pix dia anterior)"
                                    value={recebimentoSelecionado.observacoes}
                                    onChange={handleObservacoesRecebimentos} isInvalid={recebimentoSelecionado && recebimentoSelecionado.observacoes && recebimentoSelecionado.observacoes.length > 255}
                                />
                            </Form.Group>
                            <Button variant="primary" className='mt-2' type='submit' disabled={recebimentoSelecionado.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setRecebimentoSelecionado(false)} >
                                Cancelar
                            </Button>

                        </Form>


                    )}


                </div>







                <div className='maquinetas text-center border border-2 border-primary rounded p-3 mt-2 bg-light'>
                    <h4 className="mb-3 fw-bold">MAQUINETAS</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark ">
                                <th colSpan="4">Total das Maquinetas</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalRecebimentosCartoes().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Operadora</th>
                                <th>Identificação</th>
                                <th>Nº de Serie</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recebimentosCartoes.length > 0 ? (
                                recebimentosCartoes.filter((recebimentoCartao) => recebimentoCartao.id !== undefined)
                                    .sort((a, b) => {
                                        if (a.id && b.id && typeof a.id === 'string' && typeof b.id === 'string') {
                                            return a.id.localeCompare(b.id);
                                        }
                                        //Compara se o id e uma string antes de ordenar
                                        return 0;
                                    })
                                    .map((recebimentoCartao) => (
                                        <tr key={recebimentoCartao.id}>
                                            <td>{recebimentoCartao.maquineta.operadora}</td>
                                            <td>{recebimentoCartao.maquineta.identificacao}</td>
                                            <td>{recebimentoCartao.maquineta.numeroDeSerie}</td>
                                            <td>{recebimentoCartao.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td>
                                                <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setRecebimentosCartoesSelecionado(recebimentoCartao); setshowFormRecebimentosCartoes(false) }}>
                                                    <i className="fa fa-edit"></i>
                                                </Button>
                                                <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveRecebimentosCartoes(recebimentoCartao.id)}>
                                                    <i className="fa fa-remove"></i>
                                                </Button>

                                            </td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Nenhuma recebimento de maquineta cadastrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormRecebimentosCartoes && (
                        <Form onSubmit={handleAddRecebimentosCartoes}>
                            <Form.Group controlId="tipoMaquineta">
                                <Form.Label>Tipo de Maquineta</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={maquinetaSelecionada}
                                    onChange={(event) => {
                                        if (identificacaoMaquinetas) {
                                            const selectedOption = event.target.value;
                                            const maquinetaId = selectedOption.split(" | ")[1]; // Obtém o ID da maquineta
                                            setMaquinetaId(maquinetaId)
                                            setMaquinetaSelecionada(event.target.value);
                                        }
                                    }}
                                    isValid={maquinetaSelecionada !== ""}
                                    isInvalid={maquinetaSelecionada === ""}
                                >
                                    <option disabled value=''>Selecione uma maquineta</option>
                                    {maquinetas
                                        .filter((maquineta) =>
                                            maquineta.lojas.some((loja) => loja.id === lojaId)
                                        )
                                        .map((maquineta) => (
                                            <option key={maquineta.id} value={`${maquineta.operadora} | ${maquineta.id} | ${maquineta.identificacao} | ${maquineta.numeroDeSerie}`}>
                                                {`${maquineta.operadora} | ${maquineta.identificacao} | ${maquineta.numeroDeSerie}`}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da Maquineta(ex: 100,00)"
                                    value={valorRecebimentosCartoes}
                                    onChange={handleChangeValorRecebimentosCartoesIncluir} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorRecebimentosCartoes <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormRecebimentosCartoes(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormRecebimentosCartoes && !recebimentosCartoesSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary" onClick={() => setshowFormRecebimentosCartoes(true)} >
                            Adicionar Maquineta
                        </Button>
                    )}


                    {recebimentosCartoesSelecionado && (

                        <Form onSubmit={handleUpdateRecebimentosCartoes}>
                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor da Maquineta (ex: 100,00)"
                                    value={recebimentosCartoesSelecionado.valor}
                                    onChange={handleChangeValorRecebimentosCartoes} isInvalid={recebimentosCartoesSelecionado.valor <= 0} />
                            </Form.Group>
                            <Button variant="primary" className='mt-2' type='submit' disabled={recebimentosCartoesSelecionado.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setRecebimentosCartoesSelecionado(false)} >
                                Cancelar
                            </Button>

                        </Form>


                    )}


                </div>









                <div className='dinheiro text-center border border-2 border-success rounded p-3 mt-2 bg-light '>
                    <h4 className="mb-3 fw-bold">DINHEIRO</h4>
                    <Table className="table table-striped table-hover">
                        <thead>
                            <tr className="bg-lightblue text-dark">
                                <th colSpan="2">Total de Dinheiro</th>
                                <th>
                                    <span className="fs-5 fw-bold">
                                        {calcularTotalDinheiro().toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dinheiro.length > 0 ? (
                                dinheiro.map((din) => (
                                    <tr key={din.id}>
                                        <td>{din.descricao}</td>
                                        <td>{din.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>
                                            <Button disabled={verificarStatus()} variant="outline-primary me-2" onClick={() => { setDinheiroSelecionado(din); setshowFormDinheiro(false) }}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                            <Button disabled={verificarStatus()} variant="outline-danger" onClick={() => handleRemoveDinheiro(din.id)}>
                                                <i className="fa fa-remove"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhuma recebimento em dinheiro cadastrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {showFormDinheiro && (
                        <Form onSubmit={handleAddDinheiro}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição " />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor (ex: 100,00)"
                                    value={valorDinheiro}
                                    onChange={handleChangeValorDinheiroIncluir}
                                />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit" disabled={valorDinheiro <= 0}>
                                Incluir
                            </Button>
                            <Button variant="secondary" onClick={() => setshowFormDinheiro(false)}>
                                Cancelar
                            </Button>
                        </Form>
                    )}
                    {(!showFormDinheiro && !dinheiroSelecionado) && (
                        <Button className={` ${verificarStatus() ? 'botao-invisivel' : ''}`} variant="primary" onClick={() => setshowFormDinheiro(true)}>
                            Adicionar Dinheiro
                        </Button>
                    )}


                    {dinheiroSelecionado && (
                        <Form onSubmit={handleUpdateDinheiro}>
                            <Form.Group controlId="descricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Digite a descrição "
                                    defaultValue={dinheiroSelecionado.descricao}
                                />
                            </Form.Group>

                            <Form.Group controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control type="text" placeholder="Digite o valor (ex: 100,00)"
                                    value={dinheiroSelecionado.valor}
                                    isInvalid={dinheiroSelecionado.valor <= 0}
                                    onChange={handleChangeValorDinheiro} />
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type='submit' disabled={dinheiroSelecionado.valor <= 0}>
                                Alterar
                            </Button>
                            <Button variant="secondary" onClick={() => setDinheiroSelecionado(false)} >
                                Cancelar
                            </Button>
                        </Form>
                    )}


                </div>





                <div className="mb-3 mt-3">
                    <label htmlFor="observacoes" className="form-label">Observações:</label>
                    <textarea
                        disabled={verificarStatus()}
                        className="form-control"
                        id="observacoes"
                        rows="3"
                        maxLength="255"
                        placeholder='Coloque observaçoes pertinentes ao fechamento do caixa.'
                        value={observacoes}
                        onChange={handleObservacoes}
                    ></textarea>
                </div>






            </div>

            <CommentModal show={showModalComment} onHide={handleModalClose} comment={comentario} />



            {fechamento &&
                <div className="d-flex justify-content-between align-items-center">
                    <Button variant="link" onClick={() => handleOpenModal()}>Histórico de Alterações</Button>
                </div>
            }

            <HistoricoAlteracoesModal
                show={showModal}
                handleClose={handleCloseModal}
                historico={historico}
            />





        </Main>




    );

}

export default FechamentoDeCaixa;
