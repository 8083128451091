import React, { useState, useEffect } from 'react';
import Main from '../template/Main';
import { Button, Table } from 'react-bootstrap';
import { makeApiCall, UrlApi } from '../../Utils/Autenticacao_API';
import { TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalConfirmacao from '../Uso_Geral/Modal_Confirmacao';
import { useAlert } from '../Uso_Geral/AlertProvider'
import ToggleSwitch from '../Uso_Geral/ToggleSwitch';



const headerProps = {
    icon: 'university',
    title: 'Lojas',
    subtitle: 'Cadastro de Lojas: Incluir, Listar, Alterar e Excluir',
};

function Lojas() {
    const showAlert = useAlert();

    const [render, setRender] = useState(false);
    const [lojas, setLojas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        makeApiCall('lojas')
            .then((response) => {
                if (response && response.data) {
                    const lojas = response.data.map((loja) => ({

                        id: loja.id,
                        razao: loja.razao,
                        endereco: loja.endereco,
                        ativo: loja.enabled

                    }));
                    setLojas(lojas);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken") {
                    navigate('/login')
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }


            });
    }, [render,navigate]);

    const handleAlterarClick = (lojaId) => {
        navigate(`/lojas/alterar/${lojaId}`);
    };


    const handleExcluir = async (id) => {
        // Implementa a lógica para excluir o usuário com o ID fornecido
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/lojas/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            showAlert('Loja excluida com sucesso ', 'success', 'top-right');
            return response.data;

        } catch (error) {
            if (error.response) {
                // Se o servidor retorna um código de status de erro (ex: 400, 404, 500, etc.)
                showAlert(error.response.data.message || error.response.data, 'error', 'top-right');
            } else {
                // Se um erro ocorre antes de a requisição ser enviada ou depois de a resposta ser recebida
                showAlert(error.message, 'error', 'top-right');
            }
        }



    };

    const handleStatus = async (id) => {
        // Implementa a lógica para inativar o usuário com o ID fornecido
        const api = axios.create({
            baseURL: UrlApi, // coloque aqui a URL base da sua API
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.put(`/lojas/status/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao inativar loja');
        }
    };

    



    const handleConfirm = (id) => {
        if(id){handleExcluir(id)}



      };




      const handleCancel = () => {
       console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
      };


    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    return (

        <Main {...headerProps}>
            <div className="d-flex justify-content-end" >
                <Link to="/app/lojas/cadastro">
                    <Button variant="outline-dark" className="m-3">Cadastrar</Button>
                </Link>
            </div>
            <div className='despesas'></div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Razao Social</th>
                        <th>Endereço</th>
                        <th>Status</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody >
                    {lojas.map((loja) => (
                        <tr key={loja.id}>
                            <td>{loja.razao}</td>
                            <td>{loja.endereco}</td>
                            <td>
                            
                            <ToggleSwitch
                                    id={`toggle-${loja.id}`}
                                    isActive={loja.ativo}
                                    onToggle={(e) => {
                                        e.preventDefault();
                                        handleStatus(loja.id);
                                    }}
                                />
                            
                            </td>
                            <td>
                                <Button className='mt-2' variant="primary" onClick={() => handleAlterarClick(loja.id)}>
                                    Alterar
                                </Button>{' '}
                                <ModalConfirmacao
                                    variant="danger"
                                    name="Excluir"
                               question={`Deseja realmente excluir ${loja.razao} ?`}
                                    onConfirm={()=> handleConfirm(loja.id)}
                                    onCancel={handleCancel}

                                    />{' '}
                               
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Main>

    );
}

export default Lojas;
