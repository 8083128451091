import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Table, InputGroup, FormControl, Dropdown, Pagination } from "react-bootstrap";
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../../Utils/Autenticacao_API';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalConfirmacao from '../../Uso_Geral/Modal_Confirmacao';
import Main from '../../template/Main'

const headerProps = {
    icon: 'users',
    title: ' Matrizes',
    subtitle: 'Incluir, Listar, Alterar e Excluir '
};


function Matriz() {


    const [matrizes, setMatrizes] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [orderBy, setOrderBy] = useState("id");


    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(false)

    const [activePage, setActivePage] = useState(1);

    const itemsPerPage = 10;



    useEffect(() => {
        makeApiCall('matriz')
            .then((response) => {

                if (response && response.data) {
                    const matrizes = response.data.map((matriz) => {
                        return {
                            id: matriz.id,
                            razao: matriz.razao,
                            apelidoFantasia: matriz.apelidoFantasia,
                            documento: matriz.documento,
                            statusConta: matriz.statusConta

                        }
                    });
                    setMatrizes(matrizes);
                    setIsLoading(false);
                }

            })
            .catch(async (error) => {
                setIsLoading(false);
                console.log(error.response.data.message)
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                    navigate('/app/')
                } else if (tokenExpirado === 'acessToken') {
                    try {
                        const refres = await Refresh();
                        if (refres === 'login') {
                            navigate('/login');
                        }
                    } catch (error) {
                        console.log(" ERRO NA CHAMADA DA API")
                    }





                }


            });
    }, [render, navigate]);

    const handleAlterarClick = (matrizId) => {
        navigate(`/app/matriz/alterar/${matrizId}`);
    };

    const handleExcluir = async (id) => {
        const api = axios.create({
            baseURL: UrlApi,
        });

        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await api.delete(`/matriz/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });


            setRender(!render)
            return response.data;

        } catch (error) {
            console.error(error);
            throw new Error('Erro ao excluir matriz');

        }
    };



    const filteredAndSortedMatrizes = (matrizes || [])
        .filter(matriz =>
            (matriz.razao && matriz.razao.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (matriz.documento && matriz.documento.includes(searchTerm))
        )
        .sort((a, b) => {
            if (orderBy === 'nome') {
                const nameA = a.razao.toLowerCase();
                const nameB = b.razao.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (a[orderBy] < b[orderBy]) {
                    return -1;
                }
                if (a[orderBy] > b[orderBy]) {
                    return 1;
                }
                return 0;
            }
        });

    function formatDocumento(documento) {
        if (!documento) {
            return "";
        } else if (documento.length === 11) {
            return `${documento.substr(0, 3)}.${documento.substr(3, 3)}.${documento.substr(6, 3)}-${documento.substr(9, 2)}`;
        } else if (documento.length === 14) {
            return `${documento.substr(0, 2)}.${documento.substr(2, 3)}.${documento.substr(5, 3)}/${documento.substr(8, 4)}-${documento.substr(12, 2)}`;
        } else {
            return documento;
        }
    }



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    const handleConfirm = (id) => {
        if (id) { handleExcluir(id) }



    };


    const handleCancel = () => {
        console.log('Ação cancelada');
        // Outra lógica de tratamento aqui...
    };

    const totalPages = Math.ceil(filteredAndSortedMatrizes.length / itemsPerPage);
    const currentPageItems = filteredAndSortedMatrizes.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);





    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }





    return (
        <Main {...headerProps}>

            <div className="d-flex justify-content-between">
                <InputGroup className="m-3" style={{ width: "300px" }}>
                    <InputGroup.Text>Pesquisar</InputGroup.Text>
                    <FormControl
                        placeholder="Nome ou Documento"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>
                <div className="order-button-container">
                    <Dropdown onSelect={(e) => setOrderBy(e)}>
                        <Dropdown.Toggle variant="outline-dark" className="m-3">
                            Ordenar por
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="id">ID</Dropdown.Item>
                            <Dropdown.Item eventKey="nome">Nome</Dropdown.Item>
                            <Dropdown.Item eventKey="documento">Documento</Dropdown.Item>
                            <Dropdown.Item eventKey="lojas">Lojas</Dropdown.Item>
                            <Dropdown.Item eventKey="ativo">Status</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Link to="/app/matriz/cadastro">
                    <Button variant="outline-dark" className="m-3">
                        Cadastrar
                    </Button>
                </Link>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome / Razao Social</th>
                        <th>CPF/CNPJ</th>
                        <th>Status Conta</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageItems.map((matriz) => (
                        <tr key={matriz.id}>
                            <td>{matriz.id}</td>
                            <td>
                                <Link to={`/app/matriz/dados/${matriz.id}`} className="link">
                                    {matriz.razao} <br/>
                                    <span style={{ fontSize: '0.8em', color: '#666' }}>
                                        {matriz.apelidoFantasia}</span>
                                </Link>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>{formatDocumento(matriz.documento)}</td>
                            <td>{matriz.statusConta}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                <Button className='mt-2' variant="primary" onClick={() => handleAlterarClick(matriz.id)}>
                                    Alterar
                                </Button>{" "}
                                <ModalConfirmacao
                                    variant="danger"
                                    name="Excluir"
                                    question={`Deseja realmente excluir ${matriz.razao} ?`}
                                    onConfirm={() => handleConfirm(matriz.id)}
                                    onCancel={handleCancel}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table >
            <Pagination className="mt-3 position-relative d-flex justify-content-center" style={{ zIndex: 1000 }}>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>





        </Main >
    )
}

export default Matriz;
