import React from 'react';
import './ToggleSwitch.css'

function ToggleSwitch({ id, isActive, onToggle }) {
    return (
        <div className="bauble_box" onClick={onToggle}>
            <input
                className="bauble_input"
                id={id}
                type="checkbox"
                checked={isActive}
                onChange={() => {}} // Prevents default input behavior
                style={{ opacity: 0, zIndex: -1 }}
            />
            <label className="bauble_label" htmlFor={id}>
            </label>
        </div>
    );
}

export default ToggleSwitch;
